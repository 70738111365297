import React from "react"
import IndexButtons from "../shared/buttons/IndexButtons";

export default class PreCampaignRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      aisleLocation: this.props.preCampaign.aisle_location,
      changed: false,
      instructionImage: this.props.preCampaign.instruction_image,
      instructionImageUploaded: false,
      instructionImageRemoved: false,
      marketingImage: this.props.preCampaign.marketing_image,
      marketingImageUploaded: false,
      marketingImageRemoved: false,
      productReviewImage: this.props.preCampaign.product_review_image,
      productReviewImageUploaded: false,
      productReviewImageRemoved: false,
      errors: "",
      loading: false,
    };

    this.changeAisleLocation = this.changeAisleLocation.bind(this)
    this.changeInstructionImage = this.changeInstructionImage.bind(this)
    this.changeMarketingImage = this.changeMarketingImage.bind(this)
    this.changeProductReviewImage = this.changeProductReviewImage.bind(this)
    this.removeInstructionImage = this.removeInstructionImage.bind(this)
    this.removeMarketingImage = this.removeMarketingImage.bind(this)
    this.removeProductReviewImage = this.removeProductReviewImage.bind(this)
    this.updatePreCampaign = this.updatePreCampaign.bind(this)
  }

  changeAisleLocation(event) {
    this.setState({
      changed: true,
      aisleLocation: event.target.value
    })
  }

  changeInstructionImage(event) {
    this.setState({
      changed: true,
      instructionImage: event.target.files[0],
      instructionImageUploaded: true
    })
  }

  changeMarketingImage(event) {
    this.setState({
      changed: true,
      marketingImage: event.target.files[0],
      marketingImageUploaded: true
    })
  }

  changeProductReviewImage(event) {
    this.setState({
      changed: true,
      productReviewImage: event.target.files[0],
      productReviewImageUploaded: true
    })
  }

  updatePreCampaign() {
    this.setState({
      errors: "",
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.preCampaign.id,
      aisle_location: this.state.aisleLocation,
    })
    formData.append('pre_campaign', data)

    if (this.state.instructionImageUploaded) {
      this.setState({ instructionImageRemoved: false })
      formData.append('instruction_image', this.state.instructionImage)
    }
    if (this.state.marketingImageUploaded) {
      this.setState({ marketingImageRemoved: false })
      formData.append('marketing_image', this.state.marketingImage)
    }
    if (this.state.productReviewImageUploaded) {
      this.setState({ productReviewImageRemoved: false })
      formData.append('product_review_image', this.state.productReviewImage)
    }

    fetch(this.props.preCampaign.url_update, {
      method: 'PUT',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors,
            loading: false,
          })
        } else {
          that.setState({
            changed: false,
            loading: false,
          })
        }
      }
    )
  }

  removeInstructionImage() {
    this.setState({
      errors: "",
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.preCampaign.id,
    })
    formData.append('pre_campaign', data)

    fetch(this.props.preCampaign.url_remove_instruction, {
      method: 'PUT',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors,
            loading: false,
          })
        } else {
          that.setState({
            loading: false,
            instructionImageRemoved: true,
          })
        }
      }
    )
  }

  removeMarketingImage() {
    this.setState({
      errors: "",
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.preCampaign.id,
    })
    formData.append('pre_campaign', data)

    fetch(this.props.preCampaign.url_remove_marketing, {
      method: 'PUT',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors,
            loading: false,
          })
        } else {
          that.setState({
            loading: false,
            marketingImageRemoved: true,
          })
        }
      }
    )
  }

  removeProductReviewImage() {
    this.setState({
      errors: "",
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.preCampaign.id,
    })
    formData.append('pre_campaign', data)

    fetch(this.props.preCampaign.url_remove_product_review, {
      method: 'PUT',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors,
            loading: false,
          })
        } else {
          that.setState({
            loading: false,
            productReviewImageRemoved: true,
          })
        }
      }
    )
  }

  render () {
    let {
      aisleLocation,
      changed,
      loading,
      instructionImageRemoved,
      marketingImageRemoved,
      productReviewImageRemoved,
    } = this.state;
    let { preCampaign } = this.props;

    return (
      <tr>
        <th style={{ width:"50px" }} scope="row">
          {
            loading ? (
              <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className="btn btn-sm btn-secondary mlr-5" onClick={null} disabled="disabled">
                <i className="fas fa-circle-notch fa-spin"></i>
              </button>
            ) : (
              <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className={`btn btn-sm ${changed ? "btn-success" : "btn-secondary"} mlr-5`} onClick={() => this.updatePreCampaign()} disabled={changed ? "" : "disabled"}>
                <i className="fas fa-save"></i>
              </button>
            )
          }
        </th>
        <th style={{ width:"50px" }}>{preCampaign.id}</th>
        <td>
          {
            preCampaign.campaign_live ? (
              <i className="fas fa-check-circle color-primary" style={{ marginRight:"4px" }}></i>
            ) : (
              <></>
            )
          }

          {preCampaign.machine.name} [{preCampaign.machine.id}]
        </td>
        <td style={{ wordWrap:"break-word" }}>
          <div className="input-group mb-3">
            <textarea className="form-control" placeholder="-" onChange={this.changeAisleLocation} value={aisleLocation} />
          </div>
        </td>
        <td>
          <div className="input-group">
            <input className="form-control" type="file" id="formFile" onChange={this.changeInstructionImage} style={{ fontSize:"12px" }} />
            {
              loading ? (
                <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className="btn btn-sm btn-secondary mlr-5" onClick={null} disabled="disabled">
                  <i className="fas fa-circle-notch fa-spin"></i>
                </button>
              ) : (
                <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className={`btn btn-sm ${preCampaign.instruction_image ? (instructionImageRemoved ? "btn-secondary" : "btn-danger") : "btn-secondary"} mlr-5`} onClick={instructionImageRemoved ? null : () => this.removeInstructionImage()} disabled={preCampaign.instruction_image ? (instructionImageRemoved ? "disabled" : "") : "disabled"}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              )
            }
          </div>

          {
            !preCampaign.instruction_image || instructionImageRemoved ? (
              <label style={{ marginBottom:"0",fontSize:"10px" }}>-</label>
            ) : (
              <label style={{ marginBottom:"0",fontSize:"10px" }}>
                <img style={{ width:"40px",marginRight:"2px" }} src={preCampaign.instruction_image_url}></img>

                {preCampaign.instruction_image_name}
              </label>
            )
          }
        </td>
        <td>
          <div className="input-group">
            <input className="form-control" type="file" id="formFile" onChange={this.changeMarketingImage} style={{ fontSize:"12px" }} />
            {
              loading ? (
                <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className="btn btn-sm btn-secondary mlr-5" onClick={null} disabled="disabled">
                  <i className="fas fa-circle-notch fa-spin"></i>
                </button>
              ) : (
                <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className={`btn btn-sm ${preCampaign.marketing_image ? (marketingImageRemoved ? "btn-secondary" : "btn-danger") : "btn-secondary"} mlr-5`} onClick={marketingImageRemoved ? null : () => this.removeMarketingImage()} disabled={preCampaign.marketing_image ? (marketingImageRemoved ? "disabled" : "") : "disabled"}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              )
            }
          </div>

          {
            !preCampaign.marketing_image || marketingImageRemoved ? (
              <label style={{ marginBottom:"0",fontSize:"10px" }}>-</label>
            ) : (
              <label style={{ marginBottom:"0",fontSize:"10px" }}>
                <img style={{ width:"40px",marginRight:"2px" }} src={preCampaign.marketing_image_url}></img>

                {preCampaign.marketing_image_name}
              </label>
            )
          }
        </td>
        <td>
          <div className="input-group">
            <input className="form-control" type="file" id="formFile" onChange={this.changeProductReviewImage} style={{ fontSize:"12px" }} />
            {
              loading ? (
                <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className="btn btn-sm btn-secondary mlr-5" onClick={null} disabled="disabled">
                  <i className="fas fa-circle-notch fa-spin"></i>
                </button>
              ) : (
                <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className={`btn btn-sm ${preCampaign.product_review_image ? (productReviewImageRemoved ? "btn-secondary" : "btn-danger") : "btn-secondary"} mlr-5`} onClick={productReviewImageRemoved ? null : () => this.removeProductReviewImage()} disabled={preCampaign.product_review_image ? (productReviewImageRemoved ? "disabled" : "") : "disabled"}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              )
            }
          </div>
          {
            !preCampaign.product_review_image || productReviewImageRemoved ? (
              <label style={{ marginBottom:"0",fontSize:"10px" }}>-</label>
            ) : (
              <label style={{ marginBottom:"0",fontSize:"10px" }}>
                <img style={{ width:"40px",marginRight:"2px" }} src={preCampaign.product_review_image_url}></img>

                {preCampaign.product_review_image_name}
              </label>
            )
          }
        </td>
      </tr>
    )
  }
}
