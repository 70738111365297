import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render () {
    let { planograms, showdrop_logo, url_new } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'configure_planogram';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Planograms'}
              url={url_new}
            />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Active Machines</th>
                  <th scope="col" className="w200"></th>
                </tr>
              </thead>
              <tbody>
                {planograms.map(planogram => {
                  return (
                    <tr className={`clickable ${planogram.hidden ? 'opacity25' : ''}`} onClick={(e) => {e.stopPropagation(); this.goToUrl(planogram.url)}}>
                      <th scope="row">{planogram.id}</th>
                      <td>{planogram.name || '-'}</td>
                      <td>
                        {planogram.machines.map(machine => {
                          return (
                            <div>{machine.name}</div>
                          )
                        })}
                      </td>
                      <td>
                        <IndexButtons
                          id={planogram.id}
                          url_show={null}
                          url_edit={planogram.url_edit}
                          url_delete={null}
                          hide_message={"Are you sure?"}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
