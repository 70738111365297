import React, { useState } from "react"
import Nav from "../shared/Nav";
import NewHeader from "../shared/headers/NewHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";
import Multiselect from 'multiselect-react-dropdown';

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ageOptions: this.props.options.age,
      brandOptions: this.props.options.brand,
      campaignOptions: this.props.options.campaign,
      campaignId: "",
      customerOverrides: '',
      errors: '',
      genderOptions: this.props.options.gender,
      loading: false,
      machineOptions: this.props.options.machine,
      message: null,
      navigation: "",
      navigationCampaignId: "",
      navigationType: "",
      onDesktop: true,
      selectedAgeFilters: [],
      selectedBrandFilters: [],
      selectedGenderFilters: [],
      selectedMachineFilters: [],
      internalNavigation: false,
      externalNavigation: false,
    };

    this.changeCustomerOverrides = this.changeCustomerOverrides.bind(this)
    this.changeMessage = this.changeMessage.bind(this)
    this.changeNavigation = this.changeNavigation.bind(this)
    this.changeNavigationCampaignId = this.changeNavigationCampaignId.bind(this)
    this.changeNavigationType = this.changeNavigationType.bind(this)
    this.onAgeSelect = this.onAgeSelect.bind(this)
    this.onBrandSelect = this.onBrandSelect.bind(this)
    this.onCampaignSelect = this.onCampaignSelect.bind(this)
    this.onGenderSelect = this.onGenderSelect.bind(this)
    this.onMachineSelect = this.onMachineSelect.bind(this)
    this.newRecord = this.newRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeCampaignId(event) {
    this.setState({
      campaignId: event.target.value
    })
  }

  changeCustomerOverrides(event) {
    this.setState({
      customerOverrides: event.target.value
    })
  }

  changeMessage(event) {
    this.setState({
      message: event.target.value
    })
  }

  changeNavigation(event) {
    this.setState({
      navigation: event.target.value
    })
  }

  changeNavigationCampaignId(event) {
    this.setState({
      navigationCampaignId: event.target.value
    })
  }

  changeNavigationType(event) {
    let navType = event.target.value;

    this.setState({
      navigationType: navType,
      internalNavigation: navType == "Internal",
      externalNavigation: navType == "External",
    })
  }

  onAgeSelect(selectedList, selectedItem) {
    let items = [...this.state.selectedAgeFilters];
    let itemAlreadyExistsInCollection = items.indexOf(selectedItem) > -1;

    if (itemAlreadyExistsInCollection) {
      items = items.filter(item => item !== selectedItem);
    } else {
      items = [...this.state.selectedAgeFilters, selectedItem]
    }

    this.setState({
      selectedAgeFilters: items
    });
  }

  onBrandSelect(selectedList, selectedItem) {
    let items = [...this.state.selectedBrandFilters];
    let itemAlreadyExistsInCollection = items.indexOf(selectedItem) > -1;

    if (itemAlreadyExistsInCollection) {
      items = items.filter(item => item !== selectedItem);
    } else {
      items = [...this.state.selectedBrandFilters, selectedItem]
    }

    this.setState({
      selectedBrandFilters: items
    });
  }

  onCampaignSelect(selectedList, selectedItem) {
    this.setState({
      campaignId: selectedItem.id
    });
  }

  onGenderSelect(selectedList, selectedItem) {
    let items = [...this.state.selectedGenderFilters];
    let itemAlreadyExistsInCollection = items.indexOf(selectedItem) > -1;

    if (itemAlreadyExistsInCollection) {
      items = items.filter(item => item !== selectedItem);
    } else {
      items = [...this.state.selectedGenderFilters, selectedItem]
    }

    this.setState({
      selectedGenderFilters: items
    });
  }

  onMachineSelect(selectedList, selectedItem) {
    let items = [...this.state.selectedMachineFilters];
    let itemAlreadyExistsInCollection = items.indexOf(selectedItem) > -1;

    if (itemAlreadyExistsInCollection) {
      items = items.filter(item => item !== selectedItem);
    } else {
      items = [...this.state.selectedMachineFilters, selectedItem]
    }

    this.setState({
      selectedMachineFilters: items
    });
  }

  newRecord() {
    if( confirm('Are you sure you want to send this notification to these customers? (Confirm Filters or Customer Overrides)') ) {
      this.setState({
        loading: true
      })
      let that = this;
      const formData = new FormData();
      let data = JSON.stringify({
        age_filters: this.state.selectedAgeFilters.map(filter => filter.id),
        brand_filters: this.state.selectedBrandFilters.map(filter => filter.id),
        campaign_id: this.state.campaignId,
        customer_overrides: this.state.customerOverrides,
        gender_filters: this.state.selectedGenderFilters.map(filter => filter.id),
        machine_filters: this.state.selectedMachineFilters.map(filter => filter.id),
        message: this.state.message,
        navigation: this.state.navigation,
        navigation_campaign_id: this.state.navigationCampaignId,
        navigation_type: this.state.navigationType,
      })
      formData.append('notification', data)

      fetch(this.props.url_create, {
        method: 'POST',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors,
            loading: false
          })
        } else {
          this.setState({
            loading: false
          })

          window.location.assign(data.url)
        }
      })
    }
  }

  render () {
    let { notifications_url, showdrop_logo } = this.props;
    let {
      ageOptions,
      brandOptions,
      campaignOptions,
      customerOverrides,
      errors,
      genderOptions,
      loading,
      machineOptions,
      message,
      internalNavigation,
      externalNavigation,
      navigation,
      navigationCampaignId,
      navigationType,
      onDesktop,
    } = this.state;
    let pageName = 'notifications';

    let showNavigationId = () => {
      if (navigation == "find_sample" || navigation == "promo" || navigation == "rebate") {
        return (
          <>
            <dt className="col-sm-3 text-right">
              Campaign ID
              <div className="fs-10 thin">(The ID of the campaign a user will navigation to)</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <input className="form-control" placeholder="Campaign ID" onChange={this.changeNavigationCampaignId} value={navigationCampaignId} />
              </div>
            </dd>
          </>
        )
      } else {
        return null;
      }
    }

    let showNavgiationOptions = () => {
      if (internalNavigation) {
        return (
          <>
            <dt className="col-sm-3 text-right">
              Navigation (Internal)
              <div className="fs-10 thin">(Where in the app the notification will direct the user to on click)</div>
            </dt>
            <dd className="col-sm-9">
              <select className="form-select" value={navigation} onChange={this.changeNavigation}>
                <option value="" disabled selected>Select a Navigation</option>

                {['find_sample', 'find_samples', 'profile', 'promo', 'rebate'].map(nav => {
                  return (
                    <option key={nav} value={nav}>{nav}</option>
                  )
                })}
              </select>
            </dd>

            {showNavigationId()}
          </>
        )
      } else if (externalNavigation) {
        return (
          <>
            <dt className="col-sm-3 text-right">
              Navigation (External)
              <div className="fs-10 thin">(Where the user will go when they click on the notification)</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <input className="form-control" placeholder="External URL" onChange={this.changeNavigation} value={navigation} />
              </div>
            </dd>
          </>
        )
      } else {
        return null;
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <NewHeader
              name={'New Notification'}
              url={notifications_url}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">
                Message
                <div className="fs-10 thin">Use "|" when you want a new line</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea className="form-control" placeholder="Message" onChange={this.changeMessage} value={message} />
                </div>
              </dd>

              <dt className="col-sm-12 center-text p10 fs-20">
                ~Navigation~
              </dt>

              <dt className="col-sm-3 text-right">
                Navigation Type
                <div className="fs-10 thin">(Is the link internal to the app or external?)</div>
              </dt>
              <dd className="col-sm-9">
                <select className="form-select" value={navigationType} onChange={this.changeNavigationType}>
                  <option value="" selected>Select a Navigation Type</option>

                  {['Internal', 'External'].map(discount_type => {
                    return (
                      <option key={discount_type} value={discount_type}>{discount_type}</option>
                    )
                  })}
                </select>
              </dd>

              {showNavgiationOptions()}

              <dt className="col-sm-12 center-text p10 fs-20 color-red">
                ~Overrides~
                <div className="fs-12">WARNING! All other filters below will be ignored and ONLY sent to these customers.</div>
              </dt>

              <dt className="col-sm-3 text-right color-red">
                Customer Overrides
                <div className="fs-10 thin">(Comma Seperated Customer IDs (Ex. 1,2,3,4,5))</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Customer IDs" onChange={this.changeCustomerOverrides} value={customerOverrides} />
                </div>
              </dd>

              <dt className="col-sm-12 center-text p10 fs-20">
                ~Filters~
                <div className="fs-12">NOTE: if you set a campaign_id and a brand or machine filter, make sure the campaign is for that brand on that machine!</div>
              </dt>

              <dt className="col-sm-3 text-right">
                Campaign ID
                <div className="fs-10 thin">(The ID of the campaign the users will be filtered by)</div>
              </dt>
              <dd className="col-sm-9">
                <Multiselect
                  selectionLimit={1}
                  options={campaignOptions} // Options to display in the dropdown
                  selectedValues={null} // Preselected value to persist in dropdown
                  onSelect={this.onCampaignSelect} // Function will trigger on select event
                  onRemove={this.onCampaignSelect} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </dd>

              <dt className="col-sm-12 center-text pb-10 fs-12">
                ~OR~
              </dt>

              <dt className="col-sm-3 text-right">Brand Filters</dt>
              <dd className="col-sm-9">
                <Multiselect
                  options={brandOptions} // Options to display in the dropdown
                  selectedValues={null} // Preselected value to persist in dropdown
                  onSelect={this.onBrandSelect} // Function will trigger on select event
                  onRemove={this.onBrandSelect} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </dd>

              <dt className="col-sm-3 text-right">Machine Filters</dt>
              <dd className="col-sm-9">
                <Multiselect
                  options={machineOptions} // Options to display in the dropdown
                  selectedValues={null} // Preselected value to persist in dropdown
                  onSelect={this.onMachineSelect} // Function will trigger on select event
                  onRemove={this.onMachineSelect} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </dd>

              <dt className="col-sm-12 center-text pb-10 fs-12">
                ----
              </dt>

              <dt className="col-sm-3 text-right">Age Filters</dt>
              <dd className="col-sm-9">
                <Multiselect
                  options={ageOptions} // Options to display in the dropdown
                  selectedValues={null} // Preselected value to persist in dropdown
                  onSelect={this.onAgeSelect} // Function will trigger on select event
                  onRemove={this.onAgeSelect} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </dd>

              <dt className="col-sm-3 text-right">Gender Filters</dt>
              <dd className="col-sm-9">
                <Multiselect
                  options={genderOptions} // Options to display in the dropdown
                  selectedValues={null} // Preselected value to persist in dropdown
                  onSelect={this.onGenderSelect} // Function will trigger on select event
                  onRemove={this.onGenderSelect} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-success" onClick={this.newRecord} disabled={loading ? "disabled" : ''}>
                  {
                    loading ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      'Create Notification'
                    )
                  }
                </button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
