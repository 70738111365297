import React from "react"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class IndexHeader extends React.Component {
  constructor(props) {
    super(props);
    self = this;

    this.newRecord = this.newRecord.bind(this)
  }

  newRecord(event) {
    window.location.assign(event.currentTarget.value);
  }

  render () {
    let { name, url } = this.props;

    return (
      <ul className="nav justify-content-between p20">
        <li className="nav-item">
          <h4 className="bold">{name}</h4>
        </li>

        <CreateButton
          url={url}
          onClick={this.newRecord}
        />
      </ul>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};

function CreateButton(props) {
  let { url, onClick } = props;

  if (url) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={renderTooltip("New")}
      >
        <button value={url} type="button" className="btn btn-success mlr-5" onClick={onClick}>
          <i className="fas fa-plus"></i>
        </button>
      </OverlayTrigger>
    )
  } else {
    return null;
  }
}
