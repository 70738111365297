import React from "react";

export default class Tos extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="h800 color-white">
        <div className="container pb-40 pt-100">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 pt-30">
              <div>
                <h1>
                  TERMS OF SERVICE
                  <span className="fs-16 pdl-5">Last updated January 01, 2023</span>
                </h1>
              </div>
              <p>Please read these terms of service ("terms of service", "terms") carefully before using Showdrop website and app (“website”, "service") operated by Showdrop Inc. ("us", 'we", "our").</p>
              <h3>Conditions of use</h3>
              <p>By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. Showdrop only grants use and access of this website, its products, and its services to those who have accepted its terms.</p>
              <h3>Privacy policy</h3>
              <p>Before you continue using our website, we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices.</p>
              <h3>Age restriction</h3>
              <p>You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. Showdrop assumes no responsibility for liabilities related to age misrepresentation.</p>
              <h3>Intellectual property</h3>
              <p>You agree that all materials, products, and services provided on this website are the property of [name], its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Showdrop’s intellectual property in any way, including electronic, digital, or new trademark registrations.</p>
              <p>You grant Showdrop a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</p>
              <h3>User accounts</h3>
              <p>As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password. If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly.</p>
              <p>We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.</p>
              <h3>Applicable law</h3>
              <p>By visiting this website, you agree that the laws of Chicago, IL, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Showdrop and you, or its business partners and associates.</p>
              <h3>Disputes</h3>
              <p>Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court Chicago, IL and you consent to exclusive jurisdiction and venue of such courts.</p>
              <h3>Indemnification</h3>
              <p>You agree to indemnify Showdrop and its affiliates and hold Showdrop harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.</p>
              <h3>Limitation on liability</h3>
              <p>Showdrop is not liable for any damages that may occur to you as a result of your misuse of our website.</p>
              <p>Showdrop reserves the right to edit, modify, and change this Agreement any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between Showdrop and the user, and this supersedes and replaces all prior agreements regarding the use of this website.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
