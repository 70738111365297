import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullyLoaded: false,
      loading: false,
      onDesktop: true,
      sending: false,
      loadedNotifications: [],
    };

    this.getAllNotifications = this.getAllNotifications.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop,
      loading: true
    })

    let that = this;

    fetch(this.props.url, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loadedNotifications: data.notifications,
          loading: false,
        })
      }
    })
  }

  getAllNotifications() {
    this.setState({
      loading: true
    })

    let that = this;

    fetch(this.props.url_all, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loadedNotifications: data.notifications,
          loading: false,
          fullyLoaded: true,
        })
      }
    })
  }

  sendNotification(id) {
    if( confirm('Are you sure you want to send this notification?') ) {
      let that = this;

      this.setState({
        sending: true
      })

      const formData = new FormData();
      let data = JSON.stringify({
        id: id
      })
      formData.append('notification', data)

      fetch(this.props.url_send, {
        method: 'POST',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors,
            sending: false
          })
        } else {
          this.setState({
            sending: false
          })

          window.location.reload()
        }
      })
    }
  }

  render () {
    let { showdrop_logo, url_new } = this.props;
    let { fullyLoaded, loading, loadedNotifications, onDesktop, sending } = this.state;
    let pageName = 'notifications';

    let displayFilters = (values) => {
      if (values) {
        return (
          values.map(value => {
            return (
              <div>{value}</div>
            )
          })
        )
      } else {
        return "-"
      }
    }
    const showLoadingButton = () => {
      if (fullyLoaded) {
        return (
          <div className="center-text">
            <button type="button" className="btn btn-secondary mlr-5 fs-20" disabled>Fully Loaded</button>
          </div>
        )
      } else {
        return (
          <div className="center-text">
            <button type="button" className="btn btn-warning mlr-5 fs-20" onClick={this.getAllNotifications}>Load All Notifications</button>
          </div>
        )
      }
    }
    let showNotifications = () => {
      if (loading) {
        return (
          <tr className="center-text">
            <td colSpan={13}>
              <i className="fas fa-circle-notch fa-spin fs-80"></i>
            </td>
          </tr>
        )
      } else {
        return (
          <>
            {loadedNotifications.map(notification => {
              return (
                <tr>
                  <th scope="row">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 100, hide: 100 }}
                      overlay={renderTooltip(notification.sent ? "Notification Sent!" : "Send Notification?")}
                    >
                      <button value={notification.id} type="button" className={`btn mlr-5 ${notification.sent ? 'btn-secondary' : 'btn-danger'}`} onClick={() => this.sendNotification(notification.id)} disabled={notification.sent ? 'disabled' : ''}>
                        {
                          sending ? (
                            <i className="fas fa-circle-notch fa-spin"></i>
                          ) : (
                            notification.sent ? (
                              <i className="fas fa-check"></i>
                            ) : (
                              <i className="fas fa-paper-plane"></i>
                            )
                          )
                        }
                      </button>
                    </OverlayTrigger>
                  </th>
                  <td>{notification.id}</td>
                  <td>{displayFilters(notification.brand_filters)}</td>
                  <td>{displayFilters(notification.machine_filters)}</td>
                  <td>{displayFilters(notification.gender_filters)}</td>
                  <td>{displayFilters(notification.age_filters)}</td>
                  <td>{displayFilters(notification.customer_overrides)}</td>
                  <td>{notification.campaign_id || "-"}</td>
                  <td>{notification.navigation_campaign_id || "-"}</td>
                  <td>{notification.customer_count}</td>
                  <td>{notification.created_at}</td>
                  <td>{notification.sent_at || "-"}</td>
                  <td>{notification.message}</td>
                  <td>
                    <IndexButtons
                      id={notification.id}
                      url_show={null}
                      url_edit={null}
                      url_delete={null}
                      hide_message={""}
                    />
                  </td>
                </tr>
              )
            })}
          </>
        )
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Notifications'}
              url={url_new}
            />

            {showLoadingButton()}

            <br></br>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Brand Filters</th>
                  <th scope="col">Machine Filters</th>
                  <th scope="col">Gender Filters</th>
                  <th scope="col">Age Filters</th>
                  <th scope="col">Customer Overrides</th>
                  <th scope="col">Campaign ID</th>
                  <th scope="col">Navigation Campaign ID</th>
                  <th scope="col">Customer Count</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Sent At</th>
                  <th scope="col">Message</th>
                  <th scope="col" className="w200"></th>
                </tr>
              </thead>
              <tbody>
                {showNotifications()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
