import React from "react"
import Nav from "../../shared/Nav";
import IndexHeader from "../../shared/headers/IndexHeader";
import AdminHeader from "../../shared/headers/AdminHeader";
import MachineRow from "./MachineRow";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { machines, showdrop_logo } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'machine_stats';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white">
            <IndexHeader
              name={'Machine Stats'}
              url={null}
            />

            <div className="table-responsive w100p m-auto">
              {
                <table className="table table-long table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <th className="sticky bg-extra-gray">Machine Name</th>
                      <th className="sticky bg-extra-gray">Week Of</th>
                      <th className="sticky bg-extra-gray">Impressions</th>
                      <th className="sticky bg-extra-gray">Dwells</th>
                      <th className="sticky bg-extra-gray center-text">
                        Avg
                        <div>Dwell</div>
                      </th>
                      <th className="sticky bg-extra-gray center-text">
                        Customer
                        <div>Count (u)</div>
                      </th>
                      <th className="sticky bg-purple center-text">
                        PIN
                        <div>Requests (u)</div>
                      </th>
                      <th className="sticky bg-light-purple">Showdrop</th>
                      <th className="sticky bg-light-purple">Machine</th>
                      <th className="sticky bg-light-purple">Returning</th>
                      <th className="sticky bg-light-purple">Showdrop (e)</th>
                      <th className="sticky bg-light-purple">Machine (e)</th>
                      <th className="sticky bg-light-purple">Returning (e)</th>
                      <th className="sticky bg-blue center-text">
                        PIN
                        <div>Inputs</div>
                      </th>
                      <th className="sticky bg-blue center-text">
                        Valid PIN
                        <div>Inputs (u)</div>
                      </th>
                      <th className="sticky bg-extra-gray">Claimed (u)</th>
                      <th className="sticky bg-extra-gray center-text">
                        Total
                        <div>Claimed</div>
                      </th>
                      <th className="sticky bg-extra-gray center-text">
                        Avg
                        <div>Eligible</div>
                      </th>
                      <th className="sticky bg-extra-gray center-text">
                        Dispense
                        <div>Errors</div>
                      </th>
                      <th className="sticky bg-extra-gray center-text">
                        Invalid
                        <div>PIN</div>
                      </th>
                      <th className="sticky bg-extra-gray center-text">
                        Already
                        <div>Claimed</div>
                      </th>
                      <th className="sticky bg-extra-gray center-text">
                        Shop
                        <div>Clicks</div>
                      </th>
                      <th className="sticky bg-extra-gray center-text">
                        Engagement
                        <div>Clicks</div>
                      </th>
                      <th className="sticky bg-extra-gray center-text">
                        Rebate
                        <div>Signups</div>
                      </th>
                      <th className="sticky bg-extra-gray">Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    {machines.map(machine => {
                      return (
                        <MachineRow machine={machine} />
                      )
                    })}
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
