import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloading: false,
      onDesktop: true,
    };

    this.downloadCategoriesCsv = this.downloadCategoriesCsv.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  downloadCategoriesCsv() {
    let that = this;

    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `products_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render () {
    let { categories, showdrop_logo } = this.props;
    let { downloading, onDesktop } = this.state;
    let pageName = 'categories';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Categories'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="col">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Product Categories")}
                >
                  <button type="button" className="btn btn-sm btn-success mb-5px fs-17 w35 floatr" onClick={this.downloadCategoriesCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <table className="table table-long table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    <>Major Category</>

                    <button style={{ fontSize:"8px",padding:"2px 4px" }} type="button" className="btn btn-sm btn-success mlr-5" onClick={(e) => {e.stopPropagation(); this.goToUrl(this.props.major_url_new)}}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th scope="col">
                    <>Minor Category</>

                    <button style={{ fontSize:"8px",padding:"2px 4px" }} type="button" className="btn btn-sm btn-success mlr-5" onClick={(e) => {e.stopPropagation(); this.goToUrl(this.props.minor_url_new)}}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th scope="col">
                    <>Sub Category</>

                    <button style={{ fontSize:"8px",padding:"2px 4px" }} type="button" className="btn btn-sm btn-success mlr-5" onClick={(e) => {e.stopPropagation(); this.goToUrl(this.props.sub_url_new)}}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th scope="col" className="w200"></th>
                </tr>
              </thead>
              <tbody>
                {categories.map(category => {
                  return (
                    <tr>
                      <td></td>
                      {
                        category.major_id ? (
                          <td>
                            <button style={{ fontSize:"12px",padding:"3px 6px" }} type="button" className="btn btn-sm btn-warning mlr-5" onClick={(e) => {e.stopPropagation(); this.goToUrl(category.major_url_edit)}}>
                              <i className="fas fa-pencil-alt"></i>
                            </button>

                            <>{category.major_name} [{category.major_id}]</>
                          </td>
                        ) : (
                          <td></td>
                        )
                      }
                      {
                        category.minor_id ? (
                          <td>
                            <button style={{ fontSize:"12px",padding:"3px 6px" }} type="button" className="btn btn-sm btn-warning mlr-5" onClick={(e) => {e.stopPropagation(); this.goToUrl(category.minor_url_edit)}}>
                              <i className="fas fa-pencil-alt"></i>
                            </button>

                            <>{category.minor_name} [{category.minor_id}]</>
                          </td>
                        ) : (
                          <td></td>
                        )
                      }
                      {
                        category.sub_id ? (
                          <td>
                            <button style={{ fontSize:"12px",padding:"3px 6px" }} type="button" className="btn btn-sm btn-warning mlr-5" onClick={(e) => {e.stopPropagation(); this.goToUrl(category.sub_url_edit)}}>
                              <i className="fas fa-pencil-alt"></i>
                            </button>

                            <>{category.sub_name} [{category.sub_id}]</>
                          </td>
                        ) : (
                          <td></td>
                        )
                      }
                      <td>
                        <IndexButtons
                          id={null}
                          url_show={null}
                          url_edit={null}
                          url_delete={null}
                          hide_message={""}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};

function normalizeInput(value, _) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength <= 11) return currentValue;
  return currentValue.slice(0,11);
};
