import React from "react"

export default class Success extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { message } = this.props;

    if (message) {
      return (
        <div className="center-text color-green pb-20 fs-14">{message}</div>
      )
    } else {
      return null;
    }
  }
}
