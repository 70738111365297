import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import IndexHeader from "../shared/headers/IndexHeader";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      onDesktop: true,
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { company, showdrop_logo, url_new } = this.props;
    let { loading, onDesktop } = this.state;
    let pageName = 'companies';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={company.name}
              subtext={null}
              url_edit={company.url_edit}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{company.id}</dd>

              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">{company.name}</dd>

              <dt className="col-sm-3 text-right">Logo</dt>
              <dd className="col-sm-9">
                {company.logo ? (
                  <img className="w300 h-auto" src={company.logo_url} />
                ) : (
                  null
                )}

                <div>{company.logo_name}</div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}
