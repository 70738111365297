import React from "react"
import IndexButtons from "../shared/buttons/IndexButtons";

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render () {
    let {} = this.state;
    let { job_tracker } = this.props;

    return (
      <tr style={{ color:`${job_tracker.eng_error ? "red" : (job_tracker.ops_error ? "orange" : "black")}` }}>
        <th style={{ minWidth:"50px" }}>
        </th>
        <td scope="row">
          <span>{job_tracker.endpoint}</span>
          <div>[{job_tracker.id}]</div>
        </td>
        <td style={{ minWidth:"110px" }}>{job_tracker.created_at}</td>
        <td style={{ minWidth:"320px",maxWidth:"320px",wordWrap:"break-word" }}>{job_tracker.message || '-'}</td>
        <td>
          <IndexButtons
            id={job_tracker.id}
            url_show={null}
            url_edit={null}
            url_delete={null}
            hide_message={""}
          />
        </td>
      </tr>
    )
  }
}
