import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    self = this;

    this.state = {
      eventCountDirection: '',
      onDesktop: true,
      sortedVisits: this.props.visits,
    }

    this.sortByEventCount = this.sortByEventCount.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  sortByEventCount() {
    let visits = this.state.sortedVisits
    let direction = '';

    if (this.state.eventCountDirection == "DESC") {
      visits = visits.sort((a, b) => a.events > b.events ? -1 : 1);
      direction = "ASC"
    } else {
      visits = visits.sort((a, b) => a.events > b.events ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      eventCountDirection: direction,
      sortedVisits: visits,
    })
  }

  render () {
    let { showdrop_logo } = this.props;
    let { eventCountDirection, onDesktop, sortedVisits } = this.state;
    let pageName = 'visits';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Visits'}
              url={null}
            />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Date</th>
                  <th scope="col" className="sortable" onClick={this.sortByEventCount}>
                    Events
                    <RenderArrow direction={eventCountDirection} />
                  </th>
                  <th scope="col" className="w200"></th>
                </tr>
              </thead>
              <tbody>
                {sortedVisits.map(visit => {
                  return (
                    <tr>
                      <th scope="row">{visit.id}</th>
                      <td>{visit.date || '-'}</td>
                      <td>{visit.events > 0 ? visit.events : '-'}</td>
                      <td>
                        <IndexButtons
                          id={visit.id}
                          url_show={null}
                          url_edit={null}
                          url_delete={visit.url_delete}
                          hide_message={"Are you sure?"}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function RenderArrow(props) {
  let { direction } = props;

  if (direction) {
    if (direction == "DESC") {
      return (
        <i className="fas fa-caret-down"></i>
      )
    } else {
      return (
        <i className="fas fa-caret-up"></i>
      )
    }
  } else {
    return null;
  }
}
