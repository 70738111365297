import React from "react"

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: this.props.coil.product.quantity
    }

    this.addOne = this.addOne.bind(this)
    this.finishReplenishment = this.finishReplenishment.bind(this)
    this.goBackToProducts = this.goBackToProducts.bind(this)
    this.goToNextCoil = this.goToNextCoil.bind(this)
    this.removeOne = this.removeOne.bind(this)
    this.setEmpty = this.setEmpty.bind(this)
    this.setMax = this.setMax.bind(this)
  }

  addOne() {
    let currentQuantity = this.state.quantity

    this.setState({
      quantity: currentQuantity + 1
    })
  }

  finishReplenishment(url) {
    const formData = new FormData();
    let data = JSON.stringify({
      quantity: this.state.quantity
    })
    formData.append('coil', data)

    fetch(url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  goBackToProducts(url) {
    const formData = new FormData();
    let data = JSON.stringify({
      quantity: this.state.quantity
    })
    formData.append('coil', data)

    fetch(url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  goToNextCoil(url) {
    const formData = new FormData();
    let data = JSON.stringify({
      quantity: this.state.quantity
    })
    formData.append('coil', data)

    fetch(url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  removeOne() {
    let currentQuantity = this.state.quantity

    this.setState({
      quantity: currentQuantity - 1
    })
  }

  setEmpty() {
    this.setState({
      quantity: 0
    })
  }

  setMax() {
    this.setState({
      quantity: this.props.coil.product.max_quantity
    })
  }

  render () {
    let { coil } = this.props;
    let { quantity } = this.state;

    let full = quantity == coil.product.max_quantity
    let empty = quantity == 0

    let fullButton = () => {
      if (full) {
        return (
          <div style={{ backgroundColor:"#a8a8a8",color:"#f0f0f0",fontWeight:"bold",fontSize:"20px",padding:"20px 0",borderRadius:"4px" }}>
            Full
          </div>
        )
      } else {
        return (
          <div className="clickable" style={{ backgroundColor:"#198754",color:"white",fontWeight:"bold",fontSize:"20px",padding:"20px 0",borderRadius:"4px" }} onClick={this.setMax}>
            Full
          </div>
        )
      }
    }
    let emptyButton = () => {
      if (empty) {
        return (
          <div style={{ backgroundColor:"#a8a8a8",color:"#f0f0f0",fontWeight:"bold",fontSize:"20px",padding:"20px 0",borderRadius:"4px" }}>
            Empty
          </div>
        )
      } else {
        return (
          <div style={{ backgroundColor:"#ef5c63",color:"white",fontWeight:"bold",fontSize:"20px",padding:"20px 0",borderRadius:"4px" }} onClick={this.setEmpty}>
            Empty
          </div>
        )
      }
    }
    let addOneButton = () => {
      if (full) {
        return (
          <i className="fas fa-plus-square" style={{ fontSize:"60px",color:"#a8a8a8" }}></i>
        )
      } else {
        return (
          <i className="fas fa-plus-square clickable" style={{ fontSize:"60px",color:"#198754" }} onClick={this.addOne}></i>
        )
      }
    }
    let removeOneButton = () => {
      if (empty) {
        return (
          <i className="fas fa-minus-square" style={{ fontSize:"60px",color:"#a8a8a8" }}></i>
        )
      } else {
        return (
          <i className="fas fa-minus-square clickable" style={{ fontSize:"60px",color:"#ef5c63" }} onClick={this.removeOne}></i>
        )
      }
    }
    let columnSquare = (column, num) => {
      if (coil.product.id != column.product_id) {
        // NOTE: Not this product
        return (
          <div style={{ flex:"1",padding:"3px",textAlign:"center" }}>
            <div style={{ backgroundColor:"#000000",borderRadius:"4px",color:"#FFFFFF50",fontWeight:"bold",marginTop:"12px" }}>
              {num}
            </div>
          </div>
        )
      } else {
        if (column.id == coil.column_id) {
          // NOTE: Active Coil currently replenishing
          return (
            <div style={{ flex:"2",padding:"3px",textAlign:"center" }}>
              <div style={{ backgroundColor:`${coil.row_color}`,borderRadius:"4px",color:"white",fontWeight:"bold",height:"50px",fontSize:"32px" }}>
                <div style={{ fontSize:"10px",paddingTop:"2px" }}>Coil</div>
                <div style={{ marginTop:"-10px" }}>{num}</div>
              </div>
            </div>
          )
        } else if (!column.active) {
          // NOTE: Inactive Coil
          return (
            <div style={{ flex:"1",padding:"3px",textAlign:"center" }}>
              <div style={{ backgroundColor:"#000000",borderRadius:"4px",color:"#FFFFFF50",fontWeight:"bold",marginTop:"12px" }}>
                {num}
              </div>
            </div>
          )
        } else if (column.full) {
          // NOTE: Full Coil
          return (
            <div style={{ flex:"1",padding:"3px",textAlign:"center" }}>
              <div style={{ backgroundColor:"#c7c7c7",borderRadius:"4px",color:`#FFFFFF60`,fontWeight:"bold",marginTop:"12px" }}>
                {num}
              </div>
            </div>
          )
        } else {
          // NOTE: Next Coil
          return (
            <div style={{ flex:"1",padding:"3px",textAlign:"center",opacity:"0.5" }}>
              <div style={{ backgroundColor:`${coil.row_color}`,borderRadius:"4px",color:`#FFFFFF80`,fontWeight:"bold",marginTop:"12px" }}>
                {num}
              </div>
            </div>
          )
        }
      }
    }

    return (
      <div className="container-fluid full-height" style={{ width:"100vw" }}>
        <div className="row">
          <div className="col">
            <div style={{ border:"2px solid #00000020",borderRadius:"4px",marginTop:"10px" }}>
              <div style={{ backgroundColor:`${coil.row_color}20` }}>
                <div>
                  <div style={{ padding:"10px 13px 5px" }}>
                    <div style={{ textAlign:"center",fontSize:"20px",borderRadius:"4px",padding:"5px",fontWeight:"bold",color:"white",backgroundColor:`${coil.row_color}` }}>
                      Row {coil.row_number}
                    </div>
                  </div>

                  <div style={{ display:"flex",padding:"0 10px" }}>
                    {coil.columns.map((column, i) => {
                      return (
                        columnSquare(column, i+1)
                      )
                    })}
                  </div>
                </div>

                <div style={{ textAlign:"center",padding:"10px 20px" }}>
                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",position:"relative" }}>
                      <div style={{ position:"absolute",top:"30%",left:"0" }} onClick={() => this.goToNextCoil(coil.back_coil_url)}>
                        <i className="fas fa-reply" style={{ fontSize:"40px" }}></i>
                        <div style={{ fontWeight:"bold",fontSize:"20px" }}>Prev</div>
                      </div>
                    </div>

                    <img src={coil.product.image} style={{ width:"200px" }}></img>

                    <div style={{ flex:"1",position:"relative" }}>
                      <div style={{ position:"absolute",top:"30%",right:"0" }} onClick={() => this.goToNextCoil(coil.update_coil_url)}>
                        <i className="fas fa-share" style={{ fontSize:"40px" }}></i>
                        <div style={{ fontWeight:"bold",fontSize:"20px" }}>Next</div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop:"10px" }}>
                    <div style={{ fontSize:"22px",fontWeight:"bold" }}>{coil.product.brand.name}</div>
                    <div style={{ fontSize:"16px" }}>{coil.product.name}</div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop:"10px",backgroundColor:"#f0f0f0" }}>
                <div style={{ display:"flex",textAlign:"center",paddingTop:"20px" }}>
                  <div style={{ flex:"1" }}>
                    {removeOneButton()}
                  </div>

                  <div style={{ flex:"1" }}>
                    <div style={{ fontSize:"40px",borderRadius:"4px",color:`${full ? "#198754" : (empty ? "#ef5c63" : "#c7c7c7")}`,fontWeight:"bold",backgroundColor:`${full ? "#19875420" : (empty ? "#ef5c6320" : "white")}`,border:`2px solid #c7c7c7` }}>
                      {quantity}
                    </div>

                    <div style={{ fontSize:"10px",fontWeight:"normal",color:"black" }}>Max Quantity: {coil.product.max_quantity}</div>
                  </div>

                  <div style={{ flex:"1" }}>
                    {addOneButton()}
                  </div>
                </div>

                <div style={{ display:"flex",textAlign:"center",padding:"0 20px 10px" }}>
                  <div style={{ flex:"1",padding:"10px" }}>
                    {emptyButton()}
                  </div>

                  <div style={{ flex:"1",padding:"10px" }}>
                    {fullButton()}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor:"#20cb9a",fontSize:"20px",margin:"10px 0",color:"white",borderRadius:"4px",padding:"20px",fontWeight:"bold",textAlign:"center" }} onClick={() => this.goBackToProducts(coil.switch_product_url)}>
              I'm done with this product!
            </div>
          </div>
        </div>
      </div>
    )
  }
}
