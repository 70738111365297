import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";
import MonthCalendar from './MonthCalendar'
import ExtractTable from './ExtractTable'

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date()

    this.state = {
      csv: null,
      message: '',
      onDesktop: true,
      sending: false,
      today: today,
      showExtractTable: false,
      uploadedRaSensorDataExtracts: [],
    };

    this.onClick = this.onClick.bind(this)
    this.setFile = this.setFile.bind(this)
    this.toggleExtractTable = this.toggleExtractTable.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  onClick() {
    let that = this;
    const formData = new FormData();

    this.setState({
      sending: true
    })

    if (this.state.csv) {
      formData.append('file', this.state.csv)
    }

    fetch(this.props.url_upload, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState((state, props) => {
        const uploadedRaSensorDataExtracts = data.ra_sensor_data_extract ?
          [...state.uploadedRaSensorDataExtracts, data.ra_sensor_data_extract] :
          state.uploadedRaSensorDataExtracts
        return {
          message: data.errors,
          sending: false,
          csv: null,
          uploadedRaSensorDataExtracts,
        }
      })

      that.fileInput.value = "";
    })
  }

  setFile(event) {
    this.setState({
      csv: event.target.files[0]
    })
  }

  toggleExtractTable() {
    this.setState(state => ({ showExtractTable: !state.showExtractTable }))
  }

  render () {
    let { showdrop_logo } = this.props;
    let { message, onDesktop, sending, today, showExtractTable } = this.state;
    let pageName = 'retail_aware';

    const raSensorDataExtracts = [...this.props.ra_sensor_data_extracts, ...this.state.uploadedRaSensorDataExtracts]
    const extractsByYearMonthDay = groupExtractsByYearMonthDay(raSensorDataExtracts)
    const currentMonth = { year: today.getFullYear(), month: today.getMonth() }
    const currentMonthDayProp = dayProp(extractsByYearMonthDay, currentMonth.year, currentMonth.month, today)
    const previousMonth = currentMonth.month == 0
      ? { year: currentMonth.year - 1, month: 11 }
      : { year: currentMonth.year, month: currentMonth.month - 1 }
    const previousMonthDayProp = dayProp(extractsByYearMonthDay, previousMonth.year, previousMonth.month, today)

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Retail Aware Data'}
              url={null}
            />

            <div style={{ marginBottom: '20px' }}>
              <h5>Sensor Data Extracts Processed</h5>
              <div style={{display: 'flex' }}>
                <MonthCalendar year={previousMonth.year} month={previousMonth.month} dayProp={previousMonthDayProp} />
                <MonthCalendar year={currentMonth.year} month={currentMonth.month} dayProp={currentMonthDayProp} />
              </div>
              <div>
                <button type="button" className="btn btn-secondary" onClick={this.toggleExtractTable}>{showExtractTable ? 'Hide Details' : 'Show Details'}</button>
                {showExtractTable && (<ExtractTable extracts={raSensorDataExtracts} />)}
              </div>
            </div>

            <form onSubmit={e => e.preventDefault()}>
              <h5>Sensor Data Extract Upload</h5>
              <input type="file" className="form-control-file" onChange={this.setFile} disabled={sending ? "disabled" : ''} ref={ref=> this.fileInput = ref}></input>

              <button className="btn btn-lg btn-block btn-success bold mt-20" type="submit" onClick={this.onClick} disabled={sending ? "disabled" : ''}>
                {sending ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  "Submit"
                )}
              </button>
            </form>

            <p className="pt-20">
              {message.split("--").map(string => {
                if (string.includes("Success")) {
                  return (
                    <div className="center-text color-primary bold pb-20 fs-14">{string}</div>
                  )
                } else {
                  return (
                    <Errors errors={string} />
                  )
                }
              })}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function groupExtractsByYearMonthDay(extracts) {
  return extracts.reduce((acc, extract) => {
    const createdAt = new Date(extract.created_at)
    const year = createdAt.getFullYear()
    const month = createdAt.getMonth()
    const day = createdAt.getDate()
    if (!(year in acc)) acc[year] = {}
    if (!(month in acc[year])) acc[year][month] = {}
    if (!(day in acc[year][month])) acc[year][month][day] = []
    acc[year][month][day].push(extract)
    return acc
  }, {})
}

const dayPropSuccessStyle = { backgroundColor: 'green' }
const dayPropFailureStyle = { backgroundColor: 'red' }
const dayPropOtherStyle = { backgroundColor: 'yellow' }
const dayPropTodayStyle = { outline: 'black solid 2px', outlineOffset: '-2px' }
function dayProp(extractsByYearMonthDay, year, month, today) {
  return (day) => {
    const isToday = today.getFullYear() == year && today.getMonth() == month && today.getDate() == day
    const baseStyle = isToday ? dayPropTodayStyle : {}
    const extracts = extractsByYearMonthDay?.[year]?.[month]?.[day] || []
    if (extracts.length == 0) return { style: baseStyle }
    else if (extracts.every(e => e.status == 'succeeded')) return { style: {...baseStyle, ...dayPropSuccessStyle} }
    else if (extracts.every(e => e.status == 'failed')) return { style: {...baseStyle, ...dayPropFailureStyle} }
    else return { style: {...baseStyle, ...dayPropOtherStyle} }
  }
}
