import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Select from "react-select";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: "",
      onDesktop: true,
      products: [],
      selectedBrandId: "all",
      selectedCustomerId: "all",
      selectedMachineId: "all",
      selectedProductId: "all",
      status: "",
    };

    this.setSelectedBrandId = this.setSelectedBrandId.bind(this)
    this.setSelectedCustomerId = this.setSelectedCustomerId.bind(this)
    this.setSelectedMachineId = this.setSelectedMachineId.bind(this)
    this.setSelectedProductId = this.setSelectedProductId.bind(this)
    this.unlockSample = this.unlockSample.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop,
    })
  }

  setSelectedBrandId(option) {
    let selectedBrand = this.props.brands.find(brand => brand.id == option.value)

    this.setState({
      selectedBrandId: option.value,
      selectedProductId: "all",
      products: selectedBrand ? selectedBrand.products : []
    })
  }

  setSelectedCustomerId(option) {
    this.setState({
      selectedCustomerId: option.value,
    })
  }

  setSelectedMachineId(option) {
    this.setState({
      selectedMachineId: option.value,
    })
  }

  setSelectedProductId(option) {
    this.setState({
      selectedProductId: option.value,
    })
  }

  unlockSample() {
    this.setState({
      loading: true,
      message: "",
      status: "",
    })

    if (this.state.selectedCustomerId == "all") {
      this.setState({
        loading: false,
        message: "Customer MUST be set.",
        status: "error"
      })
    } else {
      let that = this;
      const formData = new FormData();
      let data = JSON.stringify({
        brand_id: this.state.selectedBrandId,
        customer_id: this.state.selectedCustomerId,
        machine_id: this.state.selectedMachineId,
        product_id: this.state.selectedProductId,
      })
      formData.append('unlock_sample', data)

      fetch(this.props.url_update, {
        method: 'POST',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            message: data.errors,
            loading: false,
            status: "error"
          })
        } else {
          that.setState({
            message: data.message,
            loading: false,
            status: "success"
          })
        }
      })
    }
  }

  render () {
    let { brands, customers, machines, showdrop_logo } = this.props;
    let { onDesktop, loading, message, products, selectedBrandId, selectedCustomerId, selectedMachineId, selectedProductId, status } = this.state;
    let pageName = 'unlock_samples';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Unlock Sample for User'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="form-group">
                <label htmlFor="customer-select">Customers</label>

                <CustomerSelect
                  customers={customers}
                  onChange={this.setSelectedCustomerId}
                  selectedId={selectedCustomerId}
                />

                <p style={{ margin:"0",fontSize:"12px",color:"#00000070",fontStyle:"italic" }}>
                  Unlock all samples for the Customer globally
                </p>
              </div>

              <div className="form-group">
                <label htmlFor="customer-select">Machines</label>

                <MachineSelect
                  machines={machines}
                  onChange={this.setSelectedMachineId}
                  selectedId={selectedMachineId}
                />

                <p style={{ margin:"0",fontSize:"12px",color:"#00000070",fontStyle:"italic" }}>
                  Unlock all samples from this Machine for the Customer
                </p>
              </div>

              <div className="form-group">
                <label htmlFor="customer-select">Brands</label>

                <BrandSelect
                  brands={brands}
                  onChange={this.setSelectedBrandId}
                  selectedId={selectedBrandId}
                />

                <p style={{ margin:"0",fontSize:"12px",color:"#00000070",fontStyle:"italic" }}>
                  Unlock all samples from this Brand for the Customer
                </p>
              </div>

              <div className="form-group">
                <label htmlFor="customer-select">Products</label>

                <ProductSelect
                  products={products}
                  onChange={this.setSelectedProductId}
                  selectedId={selectedProductId}
                  brandNotSelected={selectedBrandId == "all"}
                />

                <p style={{ margin:"0",fontSize:"12px",color:"#00000070",fontStyle:"italic" }}>
                  Unlock all samples of this Product for the Customer
                </p>
              </div>

              <div className="form-group" style={{ textAlign:"center" }}>
                <button type="button" className="btn btn-sm btn-primary" style={{ fontSize:"20px",padding:"10px 20px",fontWeight:"bold" }} onClick={this.unlockSample} disabled={loading ? "disabled" : ''}>
                  {
                    loading ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      "Unlock Sample"
                    )
                  }
                </button>

                {
                  message ? (
                    <p style={{ color:`${status == "error" ? "red" : "green"}`,padding:"5px" }}>{message}</p>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function CustomerSelect(props) {
  let { customers, onChange, selectedId } = props;

  const allOption = { value: "all", label: "Select a Customer..." }
  const customerOptions = customers.map(customer => {
    const label = [`#${customer.id}`, customer.name, customer.email, customer.phone].filter(Boolean).join(' ') || customer.id
    return { value: customer.id, label }
  })
  const selectOptions = [allOption, ...customerOptions]
  const selectedOption = selectOptions.find(o => o.value == selectedId)

  return (
    <Select
      inputId="customer-select"
      value={selectedOption}
      onChange={onChange}
      options={selectOptions}
    />
  )
}

function MachineSelect(props) {
  let { machines, onChange, selectedId } = props;

  const allOption = { value: "all", label: "Select a Machine..." }
  const machineOptions = machines.map(machine => {
    const label = [`#${machine.id}`, machine.name].filter(Boolean).join(' ') || machine.id
    return { value: machine.id, label }
  })
  const selectOptions = [allOption, ...machineOptions]
  const selectedOption = selectOptions.find(o => o.value == selectedId)

  return (
    <Select
      inputId="customer-select"
      value={selectedOption}
      onChange={onChange}
      options={selectOptions}
    />
  )
}

function BrandSelect(props) {
  let { brands, onChange, selectedId } = props;

  const allOption = { value: "all", label: "Select a Brand..." }
  const brandOptions = brands.map(brand => {
    const label = [`#${brand.id}`, brand.name].filter(Boolean).join(' ') || brand.id
    return { value: brand.id, label }
  })
  const selectOptions = [allOption, ...brandOptions]
  const selectedOption = selectOptions.find(o => o.value == selectedId)

  return (
    <Select
      inputId="customer-select"
      value={selectedOption}
      onChange={onChange}
      options={selectOptions}
    />
  )
}

function ProductSelect(props) {
  let { brandNotSelected, products, onChange, selectedId } = props;

  const allOption = { value: "all", label: "Select a Product..." }
  const productOptions = products.map(product => {
    const label = [`#${product.id}`, product.name].filter(Boolean).join(' ') || product.id
    return { value: product.id, label }
  })
  const selectOptions = [allOption, ...productOptions]
  const selectedOption = selectOptions.find(o => o.value == selectedId)

  return (
    <Select
      inputId="customer-select"
      value={selectedOption}
      onChange={onChange}
      options={selectOptions}
      isDisabled={brandNotSelected}
    />
  )
}
