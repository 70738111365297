import React from "react"
import Product from "./Product";
import { Modal } from "react-bootstrap";

export default class Restock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countdown: 5,
      errors: "",
      maintenanceExited: false,
      password: "",
      showMaintenanceModal: false,
      showPasswordModal: !this.props.password_set,
    }

    this.changePassword = this.changePassword.bind(this)
    this.checkMaintenanceMode = this.checkMaintenanceMode.bind(this)
    this.confirmPassword = this.confirmPassword.bind(this)
    this.goToUrl = this.goToUrl.bind(this)
    this.sendToMaintenance = this.sendToMaintenance.bind(this)
  }

  changePassword(event) {
    this.setState({
      password: event.target.value
    })
  }

  checkMaintenanceMode() {
    if (!this.state.maintenanceExited) {
      console.log("checking...")
      let that = this;

      fetch(this.props.machine.url_check_maintenance, {
        method: 'PUT',
        headers: {},
        data: {}
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors
          })
        } else {
          that.setState({
            maintenanceExited: data.maintenance_exited,
            countdown: 6
          })
        }
      })
    }
  }

  confirmPassword() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      password: this.state.password
    })
    formData.append('associate', data)

    fetch(this.props.confirm_password_url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          password: "",
          showPasswordModal: false,
        })
      }
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  sendToMaintenance() {
    let that = this;

    this.setState({
      showMaintenanceModal: true
    })

    fetch(this.props.machine.url_send, {
      method: 'PUT',
      headers: {},
      data: {}
    }).then(response => {
      return response.json();
    }).then((_) => {
      console.log("maintenance mode starting...")

      setInterval(that.checkMaintenanceMode, 5*1000);
    })
  }

  render () {
    let { machine } = this.props;
    let { errors, maintenanceExited, password, showMaintenanceModal, showPasswordModal } = this.state;

    let completeButton = () => {
      if (machine.replenish_complete) {
        return (
          <div style={{ position:"sticky",bottom:"0",left:"0",width:"100%",backgroundColor:"white",padding:"10px 5px",boxShadow:"rgb(0 0 0 / 20%) 0px -20px 15px -15px",zIndex:"20" }}>
            <div className="clickable" style={{ backgroundColor:"#1F51FF",color:"white",fontWeight:"bold",fontSize:"16px",padding:"20px 10px",borderRadius:"4px",textAlign:"center",margin:"10px" }} onClick={this.sendToMaintenance}>
              Complete Replenishment
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ position:"sticky",bottom:"0",left:"0",width:"100%",backgroundColor:"white",padding:"10px 5px",boxShadow:"rgb(0 0 0 / 20%) 0px -20px 15px -15px",zIndex:"20" }}>
            <div style={{ backgroundColor:"#a8a8a8",color:"#f0f0f0",fontWeight:"bold",fontSize:"16px",padding:"20px 10px",borderRadius:"4px",textAlign:"center",margin:"10px" }}>
              Complete Replenishment
            </div>
          </div>
        )
      }
    }
    let completeModal = () => {
      if (showMaintenanceModal) {
        return (
          <Modal show={showMaintenanceModal} centered>
            <Modal.Body>
              <div style={{ textAlign:"center" }}>
                <img src={`${machine.maintenance_image}`} style={{ width:"50%" }}></img>
              </div>

              <div style={{ padding:"20px 0",fontSize:"18px",textAlign:"center" }}>
                Please go to the large screen on the front of the machine and press the "Exit Maintenance" button.
              </div>

              <div>
                {
                  maintenanceExited ? (
                    <button onClick={(e) => {e.stopPropagation(); this.goToUrl(machine.url_back_to_start)}} style={{ width:"100%",border:"0",padding:"10px",fontSize:"18px",fontWeight:"bold",borderRadius:"4px",backgroundColor:"#20cb9a",color:"white" }}>
                      I'm Done!
                    </button>
                  ) : (
                    <button style={{ width:"100%",border:"0",padding:"10px",fontSize:"18px",fontWeight:"bold",borderRadius:"4px",backgroundColor:"#00000050",color:"white" }}>
                      I'm Done!
                    </button>
                  )
                }
              </div>
            </Modal.Body>
          </Modal>
        )
      } else {
        return null;
      }
    }

    return (
      <div className="container-fluid full-height" style={{ width:"100vw" }}>
        <div className="row">
          <div className="col">
            <ul style={{ listStyleType:"none",padding:"0",margin:"0",marginTop:"20px" }}>
              {machine.products.map(product => {
                return <Product product={product} />
              })}
            </ul>
          </div>
        </div>

        {completeButton()}

        {completeModal()}

        <PasswordModal
          changePassword={this.changePassword}
          confirmPassword={this.confirmPassword}
          errors={errors}
          password={password}
          showPasswordModal={showPasswordModal}
        />
      </div>
    )
  }
}

function PasswordModal(props) {
  let { changePassword, confirmPassword, errors, password, showPasswordModal } = props;

  if (showPasswordModal) {
    return (
      <Modal show={true} centered>
        <Modal.Body>
          <div style={{ padding:"20px 0",fontSize:"18px",textAlign:"center" }}>
            Please enter the password for this machine.
          </div>

          <input type="text" className="form-control" placeholder="Machine Password" onChange={changePassword} value={password} />

          {
            errors ? (
              <div style={{ padding:"5px 0 0",fontSize:"14px",textAlign:"center",color:"red" }}>{errors}</div>
            ) : (
              null
            )
          }

          <button style={{ width:"100%",padding:"10px",borderRadius:"4px",border:"none",backgroundColor:"#20cb9a",fontSize:"18px",fontWeight:"bold",color:"white",marginTop:"20px" }} onClick={confirmPassword}>Confirm</button>
        </Modal.Body>
      </Modal>
    )
  } else {
    return null;
  }
}
