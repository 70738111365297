import React from "react";

export default class MachineRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countdown: 60,
      loadedMachine: {},
      machineLoading: true,
    };

    this.fetchInitialMachineData = this.fetchInitialMachineData.bind(this)
    this.fetchMachineData = this.fetchMachineData.bind(this)
  }

  componentDidMount() {
    this.fetchInitialMachineData()
  }

  fetchInitialMachineData() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.machine.id,
    })
    formData.append('machine', data)

    fetch(this.props.machine.url_fetch_machine_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          machineLoading: false,
          loadedMachine: data.machine,
        })

        setInterval(that.fetchMachineData, 60*1000);
        setInterval(() => this.tick(), 1000);
      }
    })
  }

  fetchMachineData() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.machine.id,
    })
    formData.append('machine', data)

    fetch(this.props.machine.url_fetch_machine_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loadedMachine: data.machine,
          countdown: 61,
        })
      }
    })
  }

  tick() {
    const current = this.state.countdown;
    if (current === 0) {

    } else {
      this.setState({ countdown: current - 1 });
    }
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render() {
    let { machine } = this.props;
    let { countdown, loadedMachine, machineLoading } = this.state;

    const checkForOverstock = (overstock, overstock_priority) => {
      if (overstock) {
        if (overstock_priority == "high") {
          return (
            <i className="fas fa-truck" style={{ color:"red" }}></i>
          )
        } else if (overstock_priority == "low") {
          return (
            <i className="fas fa-truck" style={{ color:"#0096FF" }}></i>
          )
        } else {
          return (
            <i className="fas fa-truck"></i>
          )
        }
      } else {
        return null
      }
    }

    return (
      <>
        {
          machineLoading ? (
            <li style={{ flex:"0 0 348px",backgroundColor:"#f0f0f0",margin:"4px 2px",borderRadius:"4px",position:"relative",height:"518px" }}>
              <div style={{ position:"absolute",width:"100%",height:"100%",backgroundColor:"#00000020",color:"white",fontWeight:"bold",borderTop:"1px solid white",borderBottom:"1px solid white",textAlign:"center" }}>
                <i className="fas fa-circle-notch fa-spin" style={{ fontSize:"50px",padding:"10px",marginTop:"150px" }}></i>
                <div style={{ paddingLeft:"4px",fontSize:"18px" }}>Loading '{machine.name}'...</div>
              </div>
            </li>
          ) : (
            loadedMachine ? (
              <li className="clickable" style={{ flex:"0 0 348px",backgroundColor:"#f0f0f0",margin:"4px 2px",borderRadius:"4px",opacity:`${loadedMachine.hidden ? "0.25" : "1"}` }} onClick={(e) => {e.stopPropagation(); this.goToUrl(loadedMachine.url)}}>
                <div style={{ padding:"2px 4px",minHeight:"460px" }}>
                  <div style={{ textAlign:"center",fontWeight:"bold",fontSize:"16px",position:'relative' }}>
                    {
                      loadedMachine.out_of_stock ? (
                        <div style={{ position:"absolute",zIndex:"2",width:"100%",fontSize:"8px",backgroundColor:"rgba(255, 0, 0, 0.8)",color:"white",top:"0" }}>
                          OUT OF STOCK!!
                        </div>
                      ) : (
                        null
                      )
                    }

                    <div style={{ display:"flex",color:"white" }}>
                      <div style={{ flex:"0.9",padding:"10px 0",backgroundColor:`${loadedMachine.active ? "#198754" : "#ef5c63"}`,position:"relative" }}>
                        {
                          loadedMachine.app_only ? (
                            <div style={{ position:"absolute",right:"0",bottom:"0",padding:"2px 4px" }}>
                              <i className="fas fa-mobile-alt"></i>
                            </div>
                          ) : (
                            <div style={{ position:"absolute",right:"0",bottom:"0",padding:"2px 4px" }}>
                              <i className="fas fa-sms"></i>
                            </div>
                          )
                        }

                        <div>
                          <span>{loadedMachine.nickname ? truncateString(loadedMachine.nickname, 28) : '-'}{` [${loadedMachine.id}]`}</span>
                        </div>

                        <div style={{ fontSize:"10px",fontWeight:"normal" }}>Last Synced: {loadedMachine.last_sync}</div>

                        <div style={{ fontSize:"8px",fontWeight:"normal",position:"absolute",bottom:"0",left:"0",color:"#FFFFFF70",padding:"2px 4px" }}>{countdown}s</div>
                      </div>

                      <div style={{ flex:"0.1",padding:"10px 0",backgroundColor:`${temperatureColor(loadedMachine.temperature_mode)}`,minWidth:"50px",position:"relative" }}>
                        <div style={{ fontSize:"10px" }}>{readableTemperature(loadedMachine.temperature_mode)}</div>
                        <div style={{ fontSize:"20px",lineHeight:"1" }}>
                          <span>{loadedMachine.temperature}°</span>
                          <div style={{ fontSize:"8px",fontWeight:"normal",paddingTop:"2px" }}>({(loadedMachine.temperature * 9/5) + 32}°F)</div>
                        </div>

                        {
                          loadedMachine.temperature_lock ? (
                            <div style={{ position:"absolute",right:"0",bottom:"0",fontSize:"7px",padding:"0 1px" }}>
                              <i className="fas fa-lock"></i>
                            </div>
                          ) : (
                            <></>
                          )
                        }
                      </div>
                    </div>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"0 0 40%",fontSize:"9px" }}>
                      <table className="table table-sm" style={{ width:"100%",marginBottom:"5px" }}>
                        <thead>
                          <tr>
                            <th style={{ minWidth:"65px" }}></th>
                            <th style={{ textAlign:"center" }}>1hr</th>
                            <th style={{ textAlign:"center" }}>8hr</th>
                            <th style={{ textAlign:"center" }}>24hr</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>Keypad</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.keypad_launched}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.keypad_launched}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.keypad_launched}</td>
                          </tr>

                          <tr>
                            <td>QR Scan</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.qr_scans}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.qr_scans}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.qr_scans}</td>
                          </tr>

                          <tr>
                            <td>PIN Req</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.pin_requests}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.pin_requests}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.pin_requests}</td>
                          </tr>

                          <tr style={{ fontWeight:"bold" }}>
                            <td>PIN Req (u)</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.customers}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.customers}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.customers}</td>
                          </tr>

                          <tr>
                            <td>PIN Input</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.pin_inputs}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.pin_inputs}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.pin_inputs}</td>
                          </tr>

                          <tr>
                            <td>Claimed</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.claimed}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.claimed}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.claimed}</td>
                          </tr>

                          <tr style={{ fontWeight:"bold" }}>
                            <td>Claimed (u)</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.claimed_unique}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.claimed_unique}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.claimed_unique}</td>
                          </tr>

                          <tr>
                            <td>Purchases</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.purchases}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.purchases}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.purchases}</td>
                          </tr>

                          <tr>
                            <td>PIN Invld</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.one_hour.pin_invalids}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.eight_hours.pin_invalids}</td>
                            <td style={{ textAlign:"center" }}>{loadedMachine.twenty_four_hours.pin_invalids}</td>
                          </tr>

                          <tr>
                            <td>Disp Err</td>
                            <td style={{ textAlign:"center",color:`${loadedMachine.one_hour.dispense_errors > 1 ? "red" : "black"}`,fontWeight:`${loadedMachine.one_hour.dispense_errors > 1 ? "bold" : "normal"}` }}>{loadedMachine.one_hour.dispense_errors}</td>
                            <td style={{ textAlign:"center",color:`${loadedMachine.eight_hours.dispense_errors > 1 ? "red" : "black"}`,fontWeight:`${loadedMachine.eight_hours.dispense_errors > 1 ? "bold" : "normal"}` }}>{loadedMachine.eight_hours.dispense_errors}</td>
                            <td style={{ textAlign:"center",color:`${loadedMachine.twenty_four_hours.dispense_errors > 1 ? "red" : "black"}`,fontWeight:`${loadedMachine.twenty_four_hours.dispense_errors > 1 ? "bold" : "normal"}` }}>{loadedMachine.twenty_four_hours.dispense_errors}</td>
                          </tr>
                        </tbody>
                      </table>

                      <div style={{ maxHeight:"45px",minHeight:"45px" }}>
                        <ul className="pd-0 mgb-0 no-list">
                          {loadedMachine.planograms.map(planogram => {
                            return (
                              <li style={{ padding:"2px 4px",textAlign:"center" }}>
                                <a className="fs-10" style={{ textDecoration:"none",color:`${planogram.active ? "white" : "#a8a8a8"}` }} href={planogram.url}>
                                  {
                                    planogram.active ? (
                                      <div style={{ fontWeight:"bold",border:"1px solid #a8a8a8",backgroundColor:"#a8a8a8",padding:"4px",borderRadius:"4px" }}>
                                        <span>{planogram.name}</span>
                                      </div>
                                    ) : (
                                      <div style={{ fontWeight:"normal",border:"1px solid #a8a8a8",padding:"4px",borderRadius:"4px" }}>
                                        <span>{planogram.name}</span>

                                        <div style={{ fontSize:"8px" }}>Launches {planogram.scheduled_at}</div>
                                      </div>
                                    )
                                  }
                                </a>

                              </li>
                            )
                          })}
                        </ul>
                      </div>

                      <div>
                        <div style={{ margin:"4px" }}>
                          <button type="button" style={{ width:"100%" }} className={`btn ${loadedMachine.url_inventory ? "btn-outline-success" : "btn-outline-secondary"}`} onClick={(e) => {e.stopPropagation(); loadedMachine.url_inventory ? this.goToUrl(loadedMachine.url_inventory) : null}} disabled={loadedMachine.url_inventory ? "" : "disabled"}>
                            <i className="fas fa-warehouse"></i>
                          </button>
                        </div>

                        <div style={{ display:"flex" }}>
                          <div style={{ flex:"1",margin:"4px" }}>
                            <button type="button" style={{ width:"100%" }} className="btn btn-outline-info" onClick={(e) => {e.stopPropagation(); this.goToUrl(loadedMachine.url_configure_machine)}}>
                              <i className="fas fa-tools"></i>
                            </button>
                          </div>

                          <div style={{ flex:"1",margin:"4px" }}>
                            <button type="button" style={{ width:"100%" }} className="btn btn-outline-warning" onClick={(e) => {e.stopPropagation(); this.goToUrl(loadedMachine.url_edit)}}>
                              <i className="fas fa-pencil-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ flex:"0 0 60%",maxHeight:"400px",overflow:"auto" }}>
                      <ul className="no-list pd-0 mgb-0">
                        {loadedMachine.campaigns.map(campaign => {
                          let lowInventory = campaign.inventory_remaining < 20 && campaign.max_inventory > 0

                          return (
                            <li style={{ margin:"2px",borderRadius:"4px",border:"1px solid #00000030",position:"relative",display:"flex" }}>
                              <div style={{ flex:"0.15" }}>
                                <div style={{ display:"flex",padding:"1px",position:"relative",textAlign:"center",height:"30px",backgroundColor:`${campaign.has_free_products ? '#EFA63020' : ''}`,color:`${campaign.has_free_products ? '#D6952B' : '#00000020'}`,border:`1px solid ${campaign.has_free_products ? '#EFA630' : '#00000020'}` }}>
                                  <div style={{ flex:"0.3",position:"relative" }}>
                                    <div style={{ fontSize:"7px" }}><i className="fas fa-award"></i></div>
                                  </div>

                                  <div style={{ width:"100%",flex:"0.7",lineHeight:"1.3",fontSize:"11px" }}>
                                    <div style={{ width:"100%",bottom:"12px",fontSize:"9px" }}>
                                      {
                                        campaign.recent_inventory_taken > 0 ? (
                                          <span>+</span>
                                        ) : (
                                          null
                                        )
                                      }
                                      {campaign.recent_inventory_taken}
                                    </div>

                                    <div style={{ width:"100%",position:"relative",fontWeight:"bold" }}>
                                      {campaign.claimed_count}
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display:"flex",padding:"1px",position:"relative",textAlign:"center",height:"30px",backgroundColor:`${campaign.has_paid_products ? '#19875420' : ''}`,color:`${campaign.has_paid_products ? '#198754' : '#00000020'}`,border:`1px solid ${campaign.has_paid_products ? '#198754' : '#00000020'}` }}>
                                  <div style={{ flex:"0.3",position:"relative" }}>
                                    <div style={{ fontSize:"7px" }}><i className="fas fa-dollar-sign"></i></div>
                                  </div>

                                  <div style={{ width:"100%",flex:"0.7",lineHeight:"1.3",fontSize:"11px" }}>
                                    <div style={{ width:"100%",bottom:"12px",fontSize:"9px" }}>
                                      {
                                        campaign.recent_purchase_claimed_count > 0 ? (
                                          <span>+</span>
                                        ) : (
                                          null
                                        )
                                      }
                                      {campaign.recent_purchase_claimed_count}
                                    </div>

                                    <div style={{ width:"100%",position:"relative",fontWeight:"bold" }}>
                                      {campaign.purchase_claimed_count}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={{ flex:"0.8",padding:"2px 0 2px 4px" }}>
                                <a href={campaign.url}>
                                  <div style={{ textAlign:"center",fontWeight:"bold",fontSize:`${campaign.brand.name.length > 20 ? "12px" : "14px"}` }}>
                                    {campaign.brand.name}
                                  </div>
                                </a>

                                <div style={{ whiteSpace:"nowrap",textOverflow:"ellipsis",width:"140px",overflow:"hidden",fontSize:`${campaign.product.name.length > 20 ? (campaign.product.name.length > 30 ? "9px" : "10px") : "11px"}` }}>
                                  {campaign.product.name}
                                </div>

                                <div style={{ fontSize:"10px",borderTop:"1px solid #00000020",padding:"1px 3px 0 0",position:"relative",display:"flex",justifyContent:"space-between" }}>
                                  <div>
                                    {"[ "}
                                    <span className={lowInventory ? 'bold color-red' : ''}>
                                      {campaign.inventory_remaining}
                                    </span>

                                    {` / ${campaign.max_inventory} ]`}

                                    <span style={{ fontSize:"8px",color:"#333333",paddingLeft:"4px" }}>
                                      {checkForOverstock(campaign.overstock, campaign.overstock_priority)}
                                    </span>
                                  </div>

                                  <div style={{ position:"relative" }}>
                                    <span style={{ paddingRight:"2px" }}>
                                      <i className="fas fa-star" style={{ fontSize:"6px",padding:"0 1px",position:"relative",top:"-1px" }}></i>
                                      <span>
                                        {campaign.review_percent}
                                      </span>
                                    </span>

                                    <span>
                                      <i className="fas fa-pencil-alt" style={{ fontSize:"5px",padding:"0 1px",position:"relative",top:"-1px" }}></i>
                                      <span>
                                        {campaign.comment_percent}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div style={{ flex:"0.05",borderLeft:"1px solid #00000020",minWidth:"24px",textAlign:"center" }}>
                                <div style={{ fontSize:"8px",padding:"2px",marginBottom:"2px",paddingBottom:"4px",lineHeight:"1",borderBottom:"1px solid #00000020" }}>
                                  <i style={{ color:"#00000050" }} className="fas fa-clock"></i>

                                  <div style={{ fontSize:"10px" }}>
                                    {campaign.duration}

                                    <div style={{ fontSize:"6px",color:"#00000070" }}>Days</div>
                                  </div>
                                </div>

                                <div style={{ fontSize:"8px",padding:"2px",lineHeight:"1" }}>
                                  <i style={{ color:"#00000050" }} className="fas fa-envelope"></i>

                                  <div style={{ fontSize:"8px" }}>
                                    {campaign.product_review_text_interval}

                                    <div style={{ fontSize:"6px",color:"#00000070" }}>Hrs</div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ) : (
              null
            )
          )
        }
      </>
    )
  }
}
function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

function readableTemperature(temperatureMode) {
  if (temperatureMode == 0) {
    return "Ambient"
  } else if (temperatureMode == 1) {
    return "Weak"
  } else if (temperatureMode == 2) {
    return "Strong"
  } else if (temperatureMode == 3) {
    return "Heating"
  } else {
    return "Unknown"
  }
}

function temperatureColor(temperatureMode) {
  if (temperatureMode == 0) {
    // Ambient
    return "#61616175"
  } else if (temperatureMode == 1) {
    // Weak Cooling
    return "#6495ED70"
  } else if (temperatureMode == 2) {
    // Strong Cooling
    return "#6495ED"
  } else if (temperatureMode == 3) {
    // Heating
    return "#ef5c63"
  } else {
    // Unknown
    return "#000000"
  }
}
