import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Row from "./Row";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedApiRequests: [],
      loading: false,
      onDesktop: true,
      selectedEndpoint: "",
    };

    this.fetchData = this.fetchData.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  fetchData(event) {
    this.setState({
      selectedEndpoint: event.target.value,
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      request_endpoint: event.target.value
    })
    formData.append('api_request', data)

    fetch(this.props.url_fetch, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loading: false,
          loadedApiRequests: data.api_requests,
        })
      }
    })
  }

  render () {
    let { names, showdrop_logo } = this.props;
    let { loading, loadedApiRequests, onDesktop, selectedEndpoint } = this.state;
    let pageName = 'api_requests';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          counts={this.props.counts}
          onDesktop={onDesktop}
          pageName={pageName}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'API Requests'}
              url={null}
            />

            <select className="form-select" value={selectedEndpoint} onChange={this.fetchData} disabled={loading ? 'disabled' : ""}>
              <option value="" disabled selected>Select an Endpoint</option>

              {names.map(name => {
                return (
                  <option key={name} value={name}>{name}</option>
                )
              })}
            </select>

            {
              loading ? (
                <div style={{ textAlign:"center",fontSize:"50px" }}>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </div>
              ) : (
                <table className="table table-hover" style={{ tableLayout:"fixed",width:"100%" }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width:"50px" }}></th>
                      <th scope="col" style={{ width:"50px" }}>ID</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Endpoint</th>
                      <th scope="col">Params</th>
                      <th scope="col" style={{ maxWidth:"100px" }}>Body</th>
                      <th scope="col" className="w200"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      loadedApiRequests.map(request => {
                        return (
                          <Row request={request} />
                        )
                      })
                    }
                  </tbody>
                </table>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}
