import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true,
    };
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { sales_total, sales, showdrop_logo } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'sales';

    const isNewOrder = (new_order) => {
      if (new_order) {
        return (
          <div className="bg-red bold color-white p5 w30 h30 br-100 center-text">
            <i className="fas fa-star"></i>
          </div>
        )
      } else {
        return null
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Showdrop Sales'}
              url={null}
            />

            <div className="pb-10 pos-r">
              <strong>{sales.length}</strong>
              <span style={{ paddingLeft:"4px" }}>sales ({sales_total})</span>
            </div>

            <table className="table table-long table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Customer ID</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Customer Email</th>
                  <th scope="col">Customer Phone</th>
                  <th scope="col">Brand Name</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Product Price</th>
                  <th scope="col">Machine Name</th>
                  <th scope="col" className="w200"></th>
                </tr>
              </thead>
              <tbody>
                {sales.map(order => {
                  return (
                    <tr>
                      <td>{isNewOrder(order.new_order)}</td>
                      <th scope="row">{order.id}</th>
                      <td>{order.created_at}</td>
                      <td>{order.customer.id}</td>
                      <td>{order.customer.name}</td>
                      <td>{order.customer.email}</td>
                      <td>{order.customer.phone}</td>
                      <td>{`${order.brand.name} [${order.brand.id}]`}</td>
                      <td>{`${order.product.name} [${order.product.id}]`}</td>
                      <td>{order.product.price}</td>
                      <td>{`${order.machine.name} [${order.machine.id}]`}</td>
                      <td>
                        <IndexButtons
                          id={order.id}
                          url_show={null}
                          url_edit={null}
                          url_delete={null}
                          hide_message={""}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
