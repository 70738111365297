import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandDirection: '',
      endDateDirection: '',
      idDirection: '',
      onDesktop: true,
      startDateDirection: '',
      sortedCampaigns: this.props.campaigns,
    }

    this.sortByBrand = this.sortByBrand.bind(this)
    this.sortByEndDate = this.sortByEndDate.bind(this)
    this.sortById = this.sortById.bind(this)
    this.sortByStartDate = this.sortByStartDate.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  endRecord(id, url) {
    if( confirm('Are you sure you want to end this campaign?') ) {
      let data = JSON.stringify({
        id: id,
      })

      fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data
      }).then(response => {
        return response.json();
      }).then(data => {
        window.location.assign(data.url)
      })
    }
  }

  sortByBrand() {
    let campaigns = this.state.sortedCampaigns
    let direction = '';

    if (this.state.brandDirection == "DESC") {
      campaigns = campaigns.sort((a, b) => a.brand.name > b.brand.name ? -1 : 1);
      direction = "ASC"
    } else {
      campaigns = campaigns.sort((a, b) => a.brand.name > b.brand.name ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      brandDirection: direction,
      endDateDirection: '',
      idDirection: '',
      startDateDirection: '',
      sortedCampaigns: campaigns,
    })
  }

  sortByEndDate() {
    let campaigns = this.state.sortedCampaigns
    let direction = '';

    if (this.state.endDateDirection == "DESC") {
      campaigns = campaigns.sort((a, b) => a.end_date > b.end_date ? -1 : 1);
      direction = "ASC"
    } else {
      campaigns = campaigns.sort((a, b) => a.end_date > b.end_date ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      brandDirection: '',
      endDateDirection: direction,
      idDirection: '',
      startDateDirection: '',
      sortedCampaigns: campaigns,
    })
  }

  sortById() {
    let campaigns = this.state.sortedCampaigns
    let direction = '';

    if (this.state.idDirection == "DESC") {
      campaigns = campaigns.sort((a, b) => a.id > b.id ? -1 : 1);
      direction = "ASC"
    } else {
      campaigns = campaigns.sort((a, b) => a.id > b.id ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      brandDirection: '',
      endDateDirection: '',
      idDirection: direction,
      startDateDirection: '',
      sortedCampaigns: campaigns,
    })
  }

  sortByStartDate() {
    let campaigns = this.state.sortedCampaigns
    let direction = '';

    if (this.state.startDateDirection == "DESC") {
      campaigns = campaigns.sort((a, b) => a.start_date > b.start_date ? -1 : 1);
      direction = "ASC"
    } else {
      campaigns = campaigns.sort((a, b) => a.start_date > b.start_date ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      brandDirection: '',
      endDateDirection: '',
      idDirection: '',
      startDateDirection: direction,
      sortedCampaigns: campaigns,
    })
  }

  render () {
    let { showdrop_logo } = this.props;
    let { brandDirection, endDateDirection, idDirection, onDesktop, startDateDirection, sortedCampaigns } = this.state;
    let pageName = 'campaigns';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Campaigns'}
              url={null}
            />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" className="sortable" onClick={this.sortById}>
                    ID
                    <RenderArrow direction={idDirection} />
                  </th>
                  <th scope="col" className="sortable" onClick={this.sortByBrand}>
                    Brand Name
                    <RenderArrow direction={brandDirection} />
                  </th>
                  <th scope="col" className="sortable" onClick={this.sortByStartDate}>
                    Start Date
                    <RenderArrow direction={startDateDirection} />
                  </th>
                  <th scope="col" className="sortable" onClick={this.sortByEndDate}>
                    End Date
                    <RenderArrow direction={endDateDirection} />
                  </th>
                  <th scope="col">Machine</th>
                  <th scope="col">Status</th>
                  <th scope="col">Codes Remaining</th>
                  <th scope="col" className="w300"></th>
                </tr>
              </thead>
              <tbody>
                {sortedCampaigns.map(campaign => {
                  return (
                    <tr className={campaign.active || campaign.upcoming ? '' : 'opacity25'}>
                      <th scope="row">{campaign.id}</th>
                      <td>
                        <a href={campaign.brand.url}>{campaign.brand.name}</a>
                      </td>
                      <td>{campaign.start_date || '-'}</td>
                      <td>{campaign.end_date || '-'}</td>
                      <td>
                        <a href={campaign.machine.url}>{campaign.machine.name}</a>
                      </td>
                      <td>{campaign.status}</td>
                      <td>
                        <IndexButtons
                          id={campaign.id}
                          url_show={campaign.url}
                          url_edit={null}
                          url_delete={campaign.url_delete}
                          hide_message={"Are you sure?"}
                        />

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 100 }}
                          overlay={renderTooltip("End")}
                        >
                          <button type="button" className="btn btn-outline-danger mlr-5" onClick={() => this.endRecord(campaign.id, campaign.url_end)}>
                            <i className="fas fa-times-circle"></i>
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};

function RenderArrow(props) {
  let { direction } = props;

  if (direction) {
    if (direction == "DESC") {
      return (
        <i className="fas fa-caret-down"></i>
      )
    } else {
      return (
        <i className="fas fa-caret-up"></i>
      )
    }
  } else {
    return null;
  }
}
