import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true,
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { campaign, showdrop_logo } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'campaigns';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={`${campaign.brand.name}`}
              subtext={null}
              url_edit={campaign.url_edit}
              url_new={null}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{campaign.id}</dd>

              <dt className="col-sm-3 text-right">Active?</dt>
              <dd className="col-sm-9">{campaign.active ? 'Yes' : 'No'}</dd>

              <dt className="col-sm-3 text-right">Brand</dt>
              <dd className="col-sm-9">
                <a href={campaign.brand.url}>{campaign.brand.name}</a>
              </dd>

              <dt className="col-sm-3 text-right">Vending Machine</dt>
              {campaign.machine.id ? (
                <dd className="col-sm-9">
                  <a href={campaign.machine.url}>{campaign.machine.name}</a>
                </dd>
              ) : (
                <dd className="col-sm-9">-</dd>
              )}

              <dt className="col-sm-3 text-right">Start Date</dt>
              <dd className="col-sm-9">{campaign.start_date || '-'}</dd>

              <dt className="col-sm-3 text-right">End Date</dt>
              <dd className="col-sm-9">{campaign.end_date || '-'}</dd>

              <dt className="col-sm-3 text-right">Rebate URL</dt>
              {campaign.enable_rebate ? (
                <dd className="col-sm-9">
                  <a href={campaign.rebate_url} target="_blank">{campaign.rebate_url}</a>
                </dd>
              ) : (
                <dd className="col-sm-9">-</dd>
              )}

              <dt className="col-sm-3 text-right">Rebate URL (Short)</dt>
              {campaign.enable_rebate ? (
                <dd className="col-sm-9">
                  <a href={campaign.rebate_url_short} target="_blank">{campaign.rebate_url_short}</a>
                </dd>
              ) : (
                <dd className="col-sm-9">-</dd>
              )}

              <dt className="col-sm-3 text-right">Shopping URL (Short)</dt>
              <dd className="col-sm-9">
                <a href={campaign.shopping_url_short} target="_blank">{campaign.shopping_url_short}</a>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}
