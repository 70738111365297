import React from "react"
import Nav from "../shared/Nav";
import NewHeader from "../shared/headers/NewHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Select from "react-select";
import Errors from "../shared/Errors";
import Success from "../shared/Success";

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datetime: '',
      errors: '',
      loading: false,
      message: '',
      notification: false,
      onDesktop: true,
      selectedAges: [],
      selectedBrands: [],
      selectedCampaigns: [],
      selectedCustomerIds: [],
      selectedGenders: [],
      selectedMachines: [],
      selectedZips: [],
      success: '',
    };

    this.changeMessage = this.changeMessage.bind(this)
    this.changeNotification = this.changeNotification.bind(this)
    this.newRecord = this.newRecord.bind(this)
    this.setDateTime = this.setDateTime.bind(this)
    this.setSelectedAges = this.setSelectedAges.bind(this)
    this.setSelectedBrands = this.setSelectedBrands.bind(this)
    this.setSelectedCampaigns = this.setSelectedCampaigns.bind(this)
    this.setSelectedCustomerIds = this.setSelectedCustomerIds.bind(this)
    this.setSelectedGenders = this.setSelectedGenders.bind(this)
    this.setSelectedMachines = this.setSelectedMachines.bind(this)
    this.setSelectedZips = this.setSelectedZips.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeMessage(e) {
    this.setState({
      message: e.target.value,
    })
  }

  changeNotification() {
    let notification = !this.state.notification;

    this.setState({
      notification: notification
    })
  }

  newRecord(customerIds) {
    this.setState({
      success: "",
      errors: "",
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      datetime: this.state.datetime,
      selected_ages: this.state.selectedAges,
      selected_brands: this.state.selectedBrands,
      selected_campaigns: this.state.selectedCampaigns,
      selected_customer_ids: this.state.selectedCustomerIds,
      selected_genders: this.state.selectedGenders,
      selected_machines: this.state.selectedMachines,
      selected_zips: this.state.selectedZips,
      customer_ids: customerIds,
      message: this.state.message,
      notification: this.state.notification,
    })
    formData.append('text_message', data)

    fetch(this.props.url_create, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false,
        })
      } else {
        that.setState({
          success: data.message,
          loading: false,
        })

        window.location.assign(data.url)
      }
    })
  }

  setDateTime(e) {
    this.setState({
      datetime: e.currentTarget.value
    })
  }

  setSelectedAges(option) {
    this.setState({
      selectedAges: option
    })
  }

  setSelectedBrands(option) {
    this.setState({
      selectedBrands: option
    })
  }

  setSelectedCampaigns(option) {
    this.setState({
      selectedCampaigns: option
    })
  }

  setSelectedCustomerIds(option) {
    this.setState({
      selectedCustomerIds: option
    })
  }

  setSelectedGenders(option) {
    this.setState({
      selectedGenders: option
    })
  }

  setSelectedMachines(option) {
    this.setState({
      selectedMachines: option
    })
  }

  setSelectedZips(option) {
    this.setState({
      selectedZips: option
    })
  }

  render () {
    let { brands, campaigns, customers, machines, showdrop_logo, zip_codes } = this.props;
    let {
      datetime,
      errors,
      loading,
      message,
      notification,
      onDesktop,
      selectedAges,
      selectedBrands,
      selectedCampaigns,
      selectedCustomerIds,
      selectedGenders,
      selectedMachines,
      selectedZips,
      success,
    } = this.state;
    let pageName = 'text_messages';

    ///////////////
    // Brands
    ///////////////
    let brandOptions = brands.map(brand => {
      return { value: brand.id, label: brand.name }
    })

    ///////////////
    // Machines
    ///////////////
    let machineOptions = machines.map(machine => {
      return { value: machine.id, label: machine.name }
    })

    ///////////////
    // Campaigns
    ///////////////
    let campaignOptions = filterCampaigns(campaigns, selectedBrands, selectedMachines).map(campaign => {
      return { value: campaign.id, label: `${campaign.id} [${campaign.machine_id}] (${campaign.date})` }
    })

    ///////////////
    // Genders
    ///////////////
    let genderOptions = [
      { value: "1", label: "Male" },
      { value: "2", label: "Female" },
      { value: "0", label: "Non-Binary" },
      { value: "999", label: "N/A" },
    ]

    ///////////////
    // Ages
    ///////////////
    let ageOptions = [
      { value: "1", label: "18-25" },
      { value: "2", label: "26-35" },
      { value: "3", label: "36-45" },
      { value: "4", label: "46-55" },
      { value: "5", label: "56-65" },
      { value: "6", label: "65+" },
      { value: "999", label: "N/A" },
    ]

    ///////////////
    // ZipCodes
    ///////////////
    let zipOptions = zip_codes.map(zipCode => {
      return { value: zipCode.id, label: zipCode.id }
    })

    ///////////////
    // Customer Ids
    ///////////////
    let customerIdsOptions = filterCustomersByNotification(customers, notification).map(customer => {
      const label = [`#${customer.id}`, customer.name, customer.email, customer.phone].filter(Boolean).join(' ')

      return { value: customer.id, label: label }
    })

    // Customers
    const filteredCustomers = filterCustomers(customers, selectedBrands, selectedCampaigns, selectedMachines, selectedGenders, selectedAges, selectedZips, notification)
    const filteredCustomersByOptOut = filterByOptOut(filteredCustomers, notification)

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <NewHeader
              name={'New Mass Text'}
              url={this.props.url}
            />

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                Brand Filter
              </label>

              <Select
                isMulti
                inputId="brand-select"
                value={selectedBrands}
                onChange={this.setSelectedBrands}
                options={brandOptions}
                maxMenuHeight={150}
                isDisabled={loading}
              />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                Machine Filter
              </label>

              <Select
                isMulti
                inputId="machine-select"
                value={selectedMachines}
                onChange={this.setSelectedMachines}
                options={machineOptions}
                maxMenuHeight={150}
                isDisabled={loading}
              />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                Campaign Filter
              </label>

              <Select
                isMulti
                inputId="campaign-select"
                value={selectedCampaigns}
                onChange={this.setSelectedCampaigns}
                options={campaignOptions}
                maxMenuHeight={150}
                isDisabled={loading}
              />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                Gender Filter
              </label>

              <Select
                isMulti
                inputId="gender-select"
                value={selectedGenders}
                onChange={this.setSelectedGenders}
                options={genderOptions}
                maxMenuHeight={150}
                isDisabled={loading}
              />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                Age Filter
              </label>

              <Select
                isMulti
                inputId="age-select"
                value={selectedAges}
                onChange={this.setSelectedAges}
                options={ageOptions}
                maxMenuHeight={150}
                isDisabled={loading}
              />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                Zip Code Filter
              </label>

              <Select
                isMulti
                inputId="zip-select"
                value={selectedZips}
                onChange={this.setSelectedZips}
                options={zipOptions}
                maxMenuHeight={150}
                isDisabled={loading}
              />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px",color:"red" }}>
                Customer Overrides
              </label>

              <Select
                isMulti
                inputId="zip-select"
                value={selectedCustomerIds}
                onChange={this.setSelectedCustomerIds}
                options={customerIdsOptions}
                maxMenuHeight={150}
                isDisabled={loading}
              />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                When to Send?
              </label>

              <input className="form-control" aria-label="Date and time" type="datetime-local" onChange={this.setDateTime} value={datetime} />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                Send as Notification?
              </label>

              <div className="form-check">
                <input type="checkbox" className="form-check-input" onChange={this.changeNotification} checked={notification} />
              </div>
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }}>
              <label style={{ fontSize:"10px",marginBottom:"0",marginLeft:"3px" }}>
                Message
              </label>

              <textarea type="text" className="form-control" placeholder="Message" onChange={this.changeMessage} value={message} disabled={loading ? "disabled" : ""} />
            </div>

            <div className="form-group" style={{ marginBottom:"10px" }}>
              <button type="button" style={{ width:"100%" }} className="btn btn-success" onClick={loading ? null : () => { this.newRecord(filteredCustomersByOptOut.map(customer => customer.id)) }} disabled={loading ? "disabled" : ""}>
                {
                  loading ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    <>Send Text Messages</>
                  )
                }
              </button>
            </div>

            <Errors errors={errors} />
            <Success message={success} />

            <h6>
              {filteredCustomersByOptOut.length} Customers

              <div style={{ fontSize:"10px" }}>
                {filteredCustomers.filter(customer => customer.twilio_opt_out == true).length} Opt Outs
              </div>
            </h6>

            <table className="table table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>1st Text?</th>
                  <th>Opt Out?</th>
                  <th>App User?</th>
                </tr>
              </thead>
              <tbody>
                {filteredCustomersByOptOut.map(customer => {
                  return (
                    <tr>
                      <td>{customer.id}</td>
                      <td>{customer.name || '-'}</td>
                      <td>{customer.phone}</td>
                      {
                        customer.conversations ? (
                          <td>-</td>
                        ) : (
                          <td>Yes</td>
                        )
                      }
                      <td>{customer.twilio_opt_out ? "Yes" : "-"}</td>
                      <td>{customer.notification ? "Yes" : "-"}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

/////////////////////
// Filter Campaigns List
/////////////////////
function filterCampaigns(campaigns, selectedBrands, selectedMachines) {
  let filteredCampaigns = campaigns

  if (selectedBrands.length == 0 && selectedMachines.length == 0) {
    // filter by nothing
    filteredCampaigns = campaigns
  } else if (selectedBrands.length == 0) {
    // Only filter by machine
    filteredCampaigns = filterCampaignsByMachine(filteredCampaigns, selectedMachines)
  } else if (selectedMachines.length == 0) {
    // Only filter by brand
    filteredCampaigns = filterCampaignsByBrand(filteredCampaigns, selectedBrands)
  } else {
    // filter by both brand and machine
    filteredCampaigns = filterCampaignsByBrandAndMachine(filteredCampaigns, selectedBrands, selectedMachines)
  }

  return filteredCampaigns
}
function filterCampaignsByBrand(campaigns, selectedBrands) {
  if (selectedBrands.length == 0) return campaigns

  let selectedValues = selectedBrands.map(brand => brand.value)

  let filteredCampaigns = campaigns.filter(campaign => {
    return selectedValues.includes(campaign.brand_id)
  })

  if (filteredCampaigns.length == 0) {
    return campaigns
  } else {
    return filteredCampaigns
  }
}
function filterCampaignsByMachine(campaigns, selectedMachines) {
  if (selectedMachines.length == 0) return campaigns

  let selectedValues = selectedMachines.map(machine => machine.value)

  let filteredCampaigns = campaigns.filter(campaign => {
    return selectedValues.includes(campaign.machine_id)
  })

  if (filteredCampaigns.length == 0) {
    return campaigns
  } else {
    return filteredCampaigns
  }
}
function filterCampaignsByBrandAndMachine(campaigns, selectedBrands, selectedMachines) {
  let selectedMachineValues = selectedMachines.map(machine => machine.value)
  let selectedBrandValues = selectedBrands.map(brand => brand.value)

  let filteredCampaigns = campaigns.filter(campaign => {
    return selectedMachineValues.includes(campaign.machine_id) && selectedBrandValues.includes(campaign.brand_id)
  })

  if (filteredCampaigns.length == 0) {
    return []
  } else {
    return filteredCampaigns
  }
}

/////////////////////
// Filter Customers List
/////////////////////
function filterCustomers(customers, selectedBrands, selectedCampaigns, selectedMachines, selectedGenders, selectedAges, selectedZips, notification) {
  let filteredCustomers = customers
  filteredCustomers = filterCustomersByBrand(filteredCustomers, selectedBrands)
  filteredCustomers = filterCustomersByCampaign(filteredCustomers, selectedCampaigns)
  filteredCustomers = filterCustomersByMachine(filteredCustomers, selectedMachines)
  filteredCustomers = filterCustomersByGender(filteredCustomers, selectedGenders)
  filteredCustomers = filterCustomersByAge(filteredCustomers, selectedAges)
  filteredCustomers = filterCustomersByZip(filteredCustomers, selectedZips)
  filteredCustomers = filterCustomersByNotification(filteredCustomers, notification)

  return filteredCustomers
}
function filterCustomersByBrand(customers, selectedBrands) {
  if (selectedBrands.length == 0) return customers

  let selectedValues = selectedBrands.map(brand => brand.value)

  return customers.filter(customer => {
    return customer.brands.some(el => selectedValues.includes(el.id))
  })
}
function filterCustomersByCampaign(customers, selectedCampaigns) {
  if (selectedCampaigns.length == 0) return customers

  let selectedValues = selectedCampaigns.map(campaign => campaign.value)

  return customers.filter(customer => {
    return customer.campaigns.some(el => selectedValues.includes(el.id))
  })
}
function filterCustomersByMachine(customers, selectedMachines) {
  if (selectedMachines.length == 0) return customers

  let selectedValues = selectedMachines.map(machine => machine.value)

  return customers.filter(customer => {
    return customer.machines.some(el => selectedValues.includes(el.id))
  })
}
function filterCustomersByGender(customers, selectedGenders) {
  if (selectedGenders.length == 0) return customers

  let selectedValues = selectedGenders.map(gender => gender.value)

  return customers.filter(customer => {
    return selectedValues.includes(customer.gender)
  })
}
function filterCustomersByAge(customers, selectedAges) {
  if (selectedAges.length == 0) return customers

  let selectedValues = selectedAges.map(age => age.value)

  return customers.filter(customer => {
    return selectedValues.includes(customer.age)
  })
}
function filterCustomersByZip(customers, selectedZips) {
  if (selectedZips.length == 0) return customers

  let selectedValues = selectedZips.map(zip => zip.value)

  return customers.filter(customer => {
    return selectedValues.includes(customer.zip_code)
  })
}
function filterCustomersByNotification(customers, notification) {
  if (notification == false) {
    return customers
  } else {
    return customers.filter(customer => {
      return customer.notification === notification && customer.fcm_token_blank === false
    })
  }
}
function filterByOptOut(customers, notification) {
  if (notification == false) {
    return customers.filter(customer => {
      return customer.twilio_opt_out === false
    })
  } else {
    return customers
  }
}
