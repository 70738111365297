import React from "react"

export default class Review extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      loading: false,
    }

    this.deleteMessage = this.deleteMessage.bind(this)
  }

  deleteMessage(review) {
    if (confirm("Are you sure you want to delete this comment?")) {
      this.setState({
        loading: true
      })

      let that = this;
      const formData = new FormData();
      let data = JSON.stringify({
        id: review.id,
      })
      formData.append('product_review', data)

      fetch(review.url_update, {
        method: 'PUT',
          headers: {},
          body: formData
        }).then(response => {
          return response.json();
        }).then((data) => {
          if (data.errors) {

          } else {
            that.setState({
              show: false
            })

            window.location.reload()
          }
        }
      )
    }
  }

  render () {
    let { review } = this.props;
    let { show, loading } = this.state;

    const displayStars = (rating) => {
      if (rating == 5.0) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
          </>
        )
      } else if (rating >= 4.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
          </>
        )
      } else if (rating >= 4) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 3.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 3) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 2.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 2) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 1.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 1) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 0.5) {
        return (
          <>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else {
        return (
          <>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      }
    }

    return (
      show ? (
        <>
          <li key={`Comments-${review.id}-Customer-${review.customer.id}`} style={{ margin:"5px 0",display:"flex",justifyContent:"space-between" }}>
            <div>
              <div style={{ color:"orange" }}>
                <span style={{ fontSize:"12px" }}>{displayStars(review.rating)}</span>
                <span style={{ color:"black",paddingLeft:"4px" }}>{review.message}</span>
              </div>

              <div>
                <strong> {review.customer.name} </strong>
                <span style={{ color:"#616161",fontSize:"12px" }}>({review.customer.id}) </span>
                {
                  !review.customer.age && !review.customer.gender ? (
                    <></>
                  ) : (
                    <span>[{review.customer.age ? review.customer.age + "/" : ""}{review.customer.gender} - {review.customer.machine.name}]</span>
                  )
                }
                <span style={{ color:"#616161",fontSize:"12px",marginLeft:"2px" }}>({review.created_at})</span>
              </div>
            </div>

            <div>
              <button type="button" className="btn btn-danger mlr-5" onClick={loading ? null : () => this.deleteMessage(review)}>
                {
                  loading ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    <i className="fas fa-trash-alt"></i>
                  )
                }
              </button>
            </div>
          </li>
          <hr></hr>
        </>
      ) : (
        null
      )
    );
  }
}
