import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Row from "./Row";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true,
      errorTrackerList: this.props.error_trackers,
      selectedByAll: true,
      selectedByOps: false,
      selectedByEng: false,
      selectedBySys: false,
    };

    this.getByAll = this.getByAll.bind(this)
    this.getByOps = this.getByOps.bind(this)
    this.getByEng = this.getByEng.bind(this)
    this.getBySys = this.getBySys.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  getByAll() {
    this.setState({
      selectedByAll: true,
      selectedByOps: false,
      selectedByEng: false,
      selectedBySys: false,
      errorTrackerList: this.props.error_trackers
    })
  }

  getByOps() {
    let errorTrackerList = this.props.error_trackers.filter(jt => jt.role == "ops")

    this.setState({
      selectedByAll: false,
      selectedByOps: true,
      selectedByEng: false,
      selectedBySys: false,
      errorTrackerList: errorTrackerList
    })
  }

  getByEng() {
    let errorTrackerList = this.props.error_trackers.filter(jt => jt.role == "eng")

    this.setState({
      selectedByAll: false,
      selectedByOps: false,
      selectedByEng: true,
      selectedBySys: false,
      errorTrackerList: errorTrackerList
    })
  }

  getBySys() {
    let errorTrackerList = this.props.error_trackers.filter(jt => jt.role == "sys")

    this.setState({
      selectedByAll: false,
      selectedByOps: false,
      selectedByEng: false,
      selectedBySys: true,
      errorTrackerList: errorTrackerList
    })
  }

  render () {
    let { error_trackers, showdrop_logo } = this.props;
    let {
      errorTrackerList,
      onDesktop,
      selectedByAll,
      selectedByEng,
      selectedByOps,
      selectedBySys,
    } = this.state;
    let pageName = 'error_trackers';

    let opsTrackers = error_trackers.filter(jt => jt.role == "ops");
    let recentOpsTrackers = opsTrackers.filter(jt => jt.recent_error );
    let engTrackers = error_trackers.filter(jt => jt.role == "eng");
    let recentEngTrackers = engTrackers.filter(jt => jt.recent_error );
    let sysTrackers = error_trackers.filter(jt => jt.role == "sys");
    let recentSysTrackers = sysTrackers.filter(jt => jt.recent_error );

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Error Trackers'}
              url={null}
            />

            <div className="row pb-10 pos-r" style={{ minHeight:"40px" }}>
              <div className="col">
                <div>
                  <span style={{ color:"orange" }}>{opsTrackers.length} <span style={{ fontWeight:"bold" }}>({recentOpsTrackers.length})</span></span> ops
                </div>
                <div>
                  <span style={{ color:"red" }}>{engTrackers.length} <span style={{ fontWeight:"bold" }}>({recentEngTrackers.length})</span></span> engineering
                </div>
                <div>
                  <span>{sysTrackers.length} <span style={{ fontWeight:"bold" }}>({recentSysTrackers.length})</span></span> system
                </div>
                <div style={{ fontSize:"11px" }}>(count in parenthesis is within the last day)</div>
              </div>
            </div>

            <div className="row bg-extra-gray" style={{ margin:"0" }}>
              <div className="col">
                <div style={{ display:"flex",width:"80%",margin:"auto" }}>
                  <div className="hoverable" onClick={this.getByAll} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByAll ? "#20cb9a" : "white"}`,color:`${selectedByAll ? "white" : "black"}` }}>
                    All
                  </div>

                  <div className="hoverable" onClick={this.getByOps} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByOps ? "#20cb9a" : "white"}`,color:`${selectedByOps ? "white" : "black"}` }}>
                    Ops
                  </div>

                  <div className="hoverable" onClick={this.getByEng} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByEng ? "#20cb9a" : "white"}`,color:`${selectedByEng ? "white" : "black"}` }}>
                    Eng
                  </div>

                  <div className="hoverable" onClick={this.getBySys} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedBySys ? "#20cb9a" : "white"}`,color:`${selectedBySys ? "white" : "black"}` }}>
                    Sys
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ margin:"0" }}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" style={{ minWidth:"50px" }}></th>
                    <th scope="col">Endpoint</th>
                    <th scope="col" style={{ minWidth:"110px" }}>Created At</th>
                    <th scope="col" style={{ minWidth:"320px",maxWidth:"320px" }}>Message</th>
                    <th scope="col" className="w200"></th>
                  </tr>
                </thead>
                <tbody>
                  {errorTrackerList.map(job_tracker => {
                    return (
                      <Row job_tracker={job_tracker} />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
