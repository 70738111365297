import React from "react"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class ConversationRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.markAsRead = this.markAsRead.bind(this)
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  markAsRead() {
    this.setState({
      loading: true
    })

    let that = this;
    let data = JSON.stringify({
      id: this.props.conversation.id,
    })

    fetch(this.props.url_mark_as_read, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload()
      }
    })
  }

  render () {
    let { conversation } = this.props;
    let { loading } = this.state;

    const unreadCount = (count) => {
      if (count > 0) {
        return (
          <div className="bg-red bold color-white br-100 center-text" style={{ fontSize:"12px",width:"22px !important",height:"22px !important",padding:"2px" }}>{count}</div>
        )
      } else {
        return null
      }
    }
    const checkIfUnsubbed = (conversation) => {
      if (conversation.unsubbed) {
        return (
          <div style={{ color:"red" }}>
            <div style={{ textDecoration:"line-through" }}>{conversation.phone}</div>
            <div style={{ fontSize:"8px",fontStyle:"italic" }}>Ubsubbed</div>
          </div>
        )
      } else {
        return (
          <>{conversation.phone}</>
        )
      }
    }
    const displayMessageInLastDay = (count) => {
      if (count >= 5) {
        return (
          <span style={{ color:"red",paddingLeft:"2px",fontSize:"12px",fontWeight:"bold" }}>[{count}]</span>
        )
      } else if (count > 0) {
        return (
          <span style={{ color:"gray",paddingLeft:"2px",fontSize:"12px" }}>[{count}]</span>
        )
      } else {
        return null;
      }
    }

    return (
      <tr className="clickable" onClick={(e) => {e.stopPropagation(); this.goToUrl(conversation.url)}}>
        <td>{unreadCount(conversation.unread_count)}</td>
        <td>
          {
            conversation.unread_count > 0 ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={renderTooltip("Mark as Read")}
              >
                <button style={{ fontSize:"12px !important",padding:"3px 5px",width:"27px",height:"27px" }} type="button" className="btn btn-sm btn-primary fs-17" onClick={(e) => {e.stopPropagation(); this.markAsRead()}} disabled={loading ? "disabled" : ''}>
                  {
                    loading ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      <i className="fas fa-eye-slash"></i>
                    )
                  }
                </button>
              </OverlayTrigger>
            ) : (
              <button style={{ fontSize:"12px !important",padding:"3px 5px",width:"27px",height:"27px" }} type="button" className="btn btn-sm btn-secondary" onClick={null} disabled="disabled">
                <i className="fas fa-eye-slash"></i>
              </button>
            )
          }
        </td>
        <th scope="row">{conversation.id}</th>
        <td>{checkIfUnsubbed(conversation)}</td>
        <td>
          {conversation.text_messages}
          {displayMessageInLastDay(conversation.messages_in_last_day)}
        </td>
        <td>{conversation.latest_message}</td>
        <td>{conversation.latest_timestamp}</td>
      </tr>
    )
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
