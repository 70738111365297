import React from "react"
import IndexButtons from "../shared/buttons/IndexButtons";

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: this.props.request.minor_endpoint
    };

    this.toggleRow = this.toggleRow.bind(this)
  }

  toggleRow() {
    let collapsed = !this.state.collapsed

    this.setState({
      collapsed: collapsed
    })
  }

  render () {
    let { collapsed } = this.state;
    let { request } = this.props;

    let collapsedRow = (request) => {
      return (
        <tr style={{ color:"#00000060" }}>
          <th style={{ width:"50px" }} className="hoverable" onClick={this.toggleRow}><i className="fas fa-plus"></i></th>
          <th style={{ width:"50px" }} scope="row">{request.id}</th>
          <td>{request.created_at}</td>
          <td>{request.endpoint}</td>
          <td style={{ wordWrap:"break-word" }}>{"<collapsed>"}</td>
          <td style={{ wordWrap:"break-word" }}>{"<collapsed>"}</td>
          <td>
            <IndexButtons
              id={request.id}
              url_show={null}
              url_edit={null}
              url_delete={null}
              hide_message={""}
            />
          </td>
        </tr>
      )
    }
    let expandedRow = (request) => {
      return (
        <tr>
          <th style={{ width:"50px" }} className="hoverable" onClick={this.toggleRow}><i className="fas fa-minus"></i></th>
          <th style={{ width:"50px" }} scope="row">{request.id}</th>
          <td>{request.created_at}</td>
          <td>{request.endpoint}</td>
          <td style={{ wordWrap:"break-word" }}>{request.params}</td>
          <td style={{ wordWrap:"break-word" }}>{request.body}</td>
          <td>
            <IndexButtons
              id={request.id}
              url_show={null}
              url_edit={null}
              url_delete={null}
              hide_message={""}
            />
          </td>
        </tr>
      )
    }

    let checkName = (request) => {
      if (collapsed) {
        return collapsedRow(request)
      } else {
        return expandedRow(request)
      }
    }

    return (
      checkName(request)
    );
  }
}
