import React from "react"

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render () {
    let { brand } = this.props;

    let displayStars = (rating) => {
      if (rating == 5.0) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
          </>
        )
      } else if (rating >= 4.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
          </>
        )
      } else if (rating >= 4) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 3.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 3) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 2.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 2) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 1.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 1) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 0.5) {
        return (
          <>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else {
        return (
          <>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      }
    }

    return (
      <div className="container" style={{ maxWidth:"400px",width:"400px",backgroundColor:"#20cb9a" }}>
        {
          brand.products.map((product,i) => {
            return (
              <>
                <div className="row" style={{ padding:"20px 20px 0" }}>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"#ffcb13",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px" }}>
                        <img src={product.image} style={{ width:"100%",height:"100%" }}  />
                      </div>
                    </div>
                  </div>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"#ffcb13",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"#ffcb13",height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        <div style={{ position:"absolute",top:"-5px",left:"0",right:"0",fontSize:"50px",fontWeight:"bold" }}>{product.location_count}</div>
                        <div style={{ position:"absolute",top:"53px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>Locations</div>
                        <div style={{ position:"absolute",top:"71px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>Live</div>
                      </div>
                    </div>
                  </div>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"#ffcb13",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px" }}></div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ padding:"0 20px 0" }}>
                  <div className="col" style={{ padding:"0",margin:"5px 5px 0" }}>
                    <div style={{ fontWeight:"bold" }}>This week:</div>
                  </div>
                </div>

                <div className="row" style={{ padding:"0 20px" }}>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"white",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        <div style={{ position:"absolute",top:"3px",left:"0",right:"0",fontSize:"42px",fontWeight:"bold" }}>{product.this_week.samples_taken_count}</div>
                        <div style={{ position:"absolute",top:"53px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>samples</div>
                        <div style={{ position:"absolute",top:"71px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>taken</div>
                      </div>
                    </div>
                  </div>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"white",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        <div style={{ position:"absolute",top:"3px",left:"0",right:"0",fontSize:"42px",fontWeight:"bold" }}>{product.this_week.gender_percent}%</div>
                        <div style={{ position:"absolute",top:"53px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>{product.this_week.gender}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"white",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        <div style={{ position:"absolute",top:"3px",left:"0",right:"0",fontSize:"42px",fontWeight:"bold" }}>{product.this_week.age_percent}%</div>
                        <div style={{ position:"absolute",top:"53px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>{product.this_week.age}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ padding:"0 20px 0" }}>
                  <div className="col" style={{ padding:"0",margin:"5px 5px 0" }}>
                    <div style={{ fontWeight:"bold" }}>Your campaign so far:</div>
                  </div>
                </div>

                <div className="row" style={{ padding:"0 20px" }}>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"white",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        <div style={{ position:"absolute",top:"3px",left:"0",right:"0",fontSize:"42px",fontWeight:"bold" }}>{product.lifetime.samples_taken_count}</div>
                        <div style={{ position:"absolute",top:"53px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>samples</div>
                        <div style={{ position:"absolute",top:"71px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>taken</div>
                      </div>
                    </div>
                  </div>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"white",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        <div style={{ position:"absolute",top:"3px",left:"0",right:"0",fontSize:"42px",fontWeight:"bold" }}>{product.lifetime.gender_percent}%</div>
                        <div style={{ position:"absolute",top:"53px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>{product.lifetime.gender}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"white",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        <div style={{ position:"absolute",top:"3px",left:"0",right:"0",fontSize:"42px",fontWeight:"bold" }}>{product.lifetime.age_percent}%</div>
                        <div style={{ position:"absolute",top:"53px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>{product.lifetime.age}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ padding:"0 20px 0" }}>
                  <div className="col" style={{ padding:"0",margin:"5px 5px 0" }}>
                    <div style={{ fontWeight:"bold" }}>Customer reactions:</div>
                  </div>
                </div>

                <div className="row" style={{ padding:`${brand.products.length > (i+1) ? "0 20px" : "0 20px 20px"}` }}>
                  <div className="col" style={{ height:"110px",padding:"0",margin:"5px" }}>
                    <div style={{ backgroundColor:"white",height:"100%",margin:"0",padding:"4px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:"white",height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        <div style={{ position:"absolute",top:"-8px",left:"0",right:"0",fontSize:"42px",fontWeight:"bold" }}>{product.avg_rating}</div>
                        <div style={{ position:"absolute",top:"40px",left:"0",right:"0",fontSize:"14px",fontWeight:"bold",color:"#ffcb13" }}>{displayStars(product.avg_rating)}</div>
                        <div style={{ position:"absolute",top:"56px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>avg. ratings</div>
                        <div style={{ position:"absolute",top:"74px",left:"0",right:"0",fontSize:"16px",fontWeight:"bold" }}>recieved</div>
                      </div>
                    </div>
                  </div>

                  <div className="col col-8" style={{ height:"110px",padding:"0",margin:"5px",maxWidth:"230px" }}>
                    <div style={{ backgroundColor:`${product.written_feedback ? "white" : "#c7c7c7"}`,height:"100%",margin:"0",padding:"10px",borderRadius:"4px" }}>
                      <div style={{ backgroundColor:`${product.written_feedback ? "white" : "#c7c7c7"}`,height:"100%",margin:"0",borderRadius:"4px",textAlign:"center",position:"relative" }}>
                        {
                          product.written_feedback ? (
                            <div style={{ fontStyle:"italic",top:"50%",position:"absolute",transform:"translateY(-50%)" }}>"{product.written_feedback}"</div>
                          ) : (
                            <div style={{ fontStyle:"italic",top:"50%",position:"absolute",transform:"translateY(-50%)",color:"#00000070" }}>Reviews coming soon!</div>
                          )
                        }

                      </div>
                    </div>
                  </div>
                </div>

                {
                  brand.products.length > (i+1) ? (
                    <div className="row" style={{ padding:"0 20px 0" }}>
                      <div className="col" style={{ padding:"0",margin:"5px 5px 0" }}>
                        <hr style={{ borderTop:"3px solid white" }}></hr>
                      </div>
                    </div>
                  ) : (
                    null
                  )
                }
              </>
            )
          })
        }


        <div className="row">

        </div>

      </div>
    );
  }
}
