import React from "react"

export default class Column extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnActive: this.props.column.active,
      maxQuantityChanged: false,
      maxQuantity: this.props.column.max_quantity,
      selectedProduct: this.props.column.product.id
    };

    this.changeMaxQuantity = this.changeMaxQuantity.bind(this)
    this.setMaxOnColumn = this.setMaxOnColumn.bind(this)
    this.changeProduct = this.changeProduct.bind(this)
    this.toggleActive = this.toggleActive.bind(this)
  }

  changeProduct(event) {
    let productId = Number(event.target.value);

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.id,
      product_id: productId,
    })
    formData.append('planogram_column', data)

    fetch(this.props.column.set_product, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          selectedProduct: productId,
        })
      }
    })
  }

  changeMaxQuantity(event) {
    this.setState({
      maxQuantity: event.target.value,
      maxQuantityChanged: true
    })
  }

  toggleActive() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.id
    })
    formData.append('planogram_column', data)
    let currentActive = this.state.columnActive;

    this.setState({
      columnActive: !currentActive
    })

    fetch(this.props.column.set_active, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          columnActive: data.active,
          selectedProduct: data.product_id,
        })
      }
    })
  }

  setMaxOnColumn() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.id,
      max_quantity: this.state.maxQuantity,
    })
    formData.append('planogram_column', data)

    fetch(this.props.column.set_max_quantity_on_column, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          maxQuantity: data.max,
          maxQuantityChanged: false
        })
      }
    })
  }

  render () {
    let { index, products } = this.props;
    let { columnActive, maxQuantity, maxQuantityChanged, selectedProduct } = this.state;
    let bgClass = '';

    if (columnActive) {
      bgClass = "bg-white"
    } else {
      bgClass = "bg-light-gray"
    }

    const renderRow = () => {
      if (columnActive) {
        return (
          <>
            <div className="f1 space-between">
              <select className="form-select mt-10" value={selectedProduct} onChange={this.changeProduct} disabled={columnActive ? null : 'disabled'}>
                <option value="" disabled selected>Select a Product</option>

                {products.map(product => {
                  return (
                    <option key={product.id} value={product.id}>{product.name}</option>
                  )
                })}
              </select>
            </div>

            <div className="f1 w50fs max-quantity m5 fs-16">
              <div className="fs-8 center-text">Max Quantity</div>
              <input className="w50 center-text bold" type="text" onChange={this.changeMaxQuantity} value={maxQuantity} />
            </div>

            <div className="f1 fs-14 w40fs">
              <button className={`${maxQuantityChanged ? "bg-green" : "bg-semi-light-gray"} color-white border-none`} style={{ padding:"4px 8px",marginTop:"18px",borderRadius:"4px" }} onClick={this.setMaxOnColumn} disabled={maxQuantityChanged ? null : "disabled"}>SET</button>
            </div>
          </>
        )
      } else {
        return null;
      }
    }

    const renderCheckbox = () => {
      if (columnActive) {
        return (
          <input className="form-check-input" type="checkbox" value="" onChange={this.toggleActive} checked></input>
        )
      } else {
        return (
          <input className="form-check-input" type="checkbox" value="" onChange={this.toggleActive}></input>
        )
      }
    }

    return (
      <div className={`d-flex m10 p10 color-black mh-72 ${bgClass}`}>
        <div className="f1 w30fs m5 fs-24 center-text mt-8">
          <div className="form-check">
            {renderCheckbox()}
          </div>
        </div>

        <div className="f1 w50fs center-text fs-20 bold mt-12">{index}</div>

        {renderRow()}
      </div>
    )
  }
}
