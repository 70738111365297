import React from "react"
import Column from "./Column";

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maxQuantity: "",
      selectedOverrideProduct: "",
    }

    this.activateAll = this.activateAll.bind(this)
    this.changeMaxQuantity = this.changeMaxQuantity.bind(this)
    this.changeOverrideProduct = this.changeOverrideProduct.bind(this)
    this.deactivateAll = this.deactivateAll.bind(this)
    this.setEmptyOnShelf = this.setEmptyOnShelf.bind(this)
    this.setMaxQuantityOnRow = this.setMaxQuantityOnRow.bind(this)
    this.setMaxOnShelf = this.setMaxOnShelf.bind(this)
  }

  activateAll(e) {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: e.currentTarget.value,
      machine_id: this.props.machine.id,
    })
    formData.append('planogram_product', data)

    fetch(this.props.machine.activate_all, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload();
      }
    })
  }

  changeMaxQuantity(event) {
    this.setState({
      maxQuantity: event.target.value
    })
  }

  changeOverrideProduct(event) {
    let productId = Number(event.target.value);

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.row.id,
      machine_id: this.props.machine.id,
      product_id: productId,
    })
    formData.append('planogram_product', data)

    fetch(this.props.row.set_products, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          selectedProduct: productId,
          selectedOverrideProduct: productId,
        })

        window.location.reload()
      }
    })
  }

  deactivateAll(e) {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: e.currentTarget.value,
      machine_id: this.props.machine.id,
    })
    formData.append('planogram_product', data)

    fetch(this.props.machine.deactivate_all, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload();
      }
    })
  }

  setEmptyOnShelf(e) {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      machine_id: this.props.machine.id,
      planogram_row_id: e.target.value,
    })
    formData.append('planogram_product', data)

    fetch(this.props.machine.set_empty_on_shelf, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload();
      }
    })
  }

  setMaxQuantityOnRow() {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.row.id,
      machine_id: this.props.row.machine_id,
      max_quantity: this.state.maxQuantity
    })
    formData.append('planogram_product', data)

    fetch(this.props.row.set_max_quantity_on_row, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          maxQuantity: "",
        })

        window.location.reload();
      }
    })
  }

  setMaxOnShelf(e) {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      machine_id: this.props.machine.id,
      planogram_row_id: e.target.value,
    })
    formData.append('planogram_product', data)

    fetch(this.props.machine.set_max_on_shelf, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload();
      }
    })
  }

  render () {
    let {
      row,
      index,
      products,
      resetValues,
      empty,
      onDesktop,
      maxedOut,
      machine,
    } = this.props;
    let {
      maxQuantity,
      selectedOverrideProduct,
    } = this.state;

    let shelfEdit = () => {
      return (
        <div className={`f1 ${onDesktop ? "w100fs" : ""} p10 center-text`}>
          <div>
            <div className="mb-10 fs-18 bold">Shelf {index + 1}</div>

            {
              machine.planogram.inventory_editable ? (
                <div style={{ display:"flex",flexDirection:`${onDesktop ? "column" : "row"}` }}>
                  <button className={`p10 w100p mb-10 color-white bg-red border-none bold ${onDesktop ? '' : "m5"}`} style={{ borderRadius:"4px" }} onClick={machine.planogram.inventory_editable ? this.setEmptyOnShelf : null} value={row.id}>
                    Empty
                  </button>

                  <button className={`p10 w100p mb-10 color-white bg-green border-none bold ${onDesktop ? '' : "m5"}`} style={{ borderRadius:"4px" }} onClick={machine.planogram.inventory_editable ? this.setMaxOnShelf : null} value={row.id}>
                    Max
                  </button>
                </div>
              ) : (
                null
              )
            }
          </div>

          <hr></hr>

          <div style={{ display:"flex",flexDirection:`${onDesktop ? "column" : "row"}` }}>
            <button className={`p10 w100p mb-10 color-white bg-red border-none bold ${onDesktop ? '' : "m5"}`} style={{ borderRadius:"4px" }} onClick={this.deactivateAll} value={row.id}>
              <div>Deactivate</div>
              <div>All</div>
            </button>

            <button className={`p10 w100p mb-10 color-white bg-green border-none bold ${onDesktop ? '' : "m5"}`} style={{ borderRadius:"4px" }} onClick={this.activateAll} value={row.id}>
              <div>Activate</div>
              <div>All</div>
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className="d-flex bg-dark-gray color-white m10" style={{ marginLeft:"12px" }}>
        {
          onDesktop ? (
            shelfEdit()
          ) : (
            <></>
          )
        }

        <div className="w100p f1 mb-5px">
          <div className="d-flex bg-dark-gray color-white m10">
            {
              onDesktop ? (
                <></>
              ) : (
                shelfEdit()
              )
            }
          </div>

          <div className="d-flex m10 p10 color-black bg-showdrop-yellow">
            <div className="f1 space-between mtb-10-5">
              <select className="form-select" value={selectedOverrideProduct} onChange={this.changeOverrideProduct}>
                <option value="" disabled selected>Select a Product</option>

                {products.map(product => {
                  return (
                    <option key={product.id} value={product.id}>{product.name}</option>
                  )
                })}
              </select>
            </div>

            <div className="f1 w50fs max-quantity m5 fs-16">
              <div className="fs-8 center-text">Max Quantity</div>
              <input className="w50 center-text bold" type="text" onChange={this.changeMaxQuantity} value={maxQuantity} />
            </div>

            <div className="f1 fs-14 w40fs">
              <button className={`${maxQuantity ? "bg-green" : "bg-semi-light-gray"} color-white border-none`} style={{ padding:"4px 8px",marginTop:"18px",borderRadius:"4px" }} onClick={this.setMaxQuantityOnRow} disabled={maxQuantity ? null : "disabled"}>SET</button>
            </div>
          </div>

          {row.columns.map((column,i) => {
            return (
              <Column
                onDesktop={onDesktop}
                index={i + 1}
                column={column}
                maxedOut={maxedOut}
                empty={empty}
                products={products}
                resetValues={resetValues}
                machine={machine}
              />
            )
          })}
        </div>

      </div>
    )
  }
}
