import React from "react"
import Nav from "../shared/Nav";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { onDesktop } = this.state;
    let { showdrop_logo } = this.props;
    let pageName = '';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">

          </div>
        </div>
      </div>
    );
  }
}
