import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      downloading: false,
      onDesktop: true,
    };

    this.downloadRawProductReviewsCsv = this.downloadRawProductReviewsCsv.bind(this)
    this.deleteMessage = this.deleteMessage.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  downloadRawProductReviewsCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_raw_product_reviews_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `raw-product-reviews_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  deleteMessage(review) {
    if (confirm("Are you sure you want to delete this comment?")) {
      this.setState({
        loading: true
      })

      const formData = new FormData();
      let data = JSON.stringify({
        id: review.id,
      })
      formData.append('product_review', data)

      fetch(review.url_update, {
        method: 'PUT',
          headers: {},
          body: formData
        }).then(response => {
          return response.json();
        }).then((data) => {
          if (data.errors) {
          } else {
            window.location.reload()
          }
        }
      )
    }
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render () {
    let { product_reviews, showdrop_logo, url_new } = this.props;
    let { downloading, loading, onDesktop } = this.state;
    let pageName = 'product_reviews';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Product Reviews'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="col pos-a">
                <span className="bold">{product_reviews.length}</span> product reviews
              </div>

              <div className="col">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Raw Product Reviews")}
                >
                  <button type="button" className="btn btn-sm btn-success mb-5px fs-17 w35 floatr" onClick={this.downloadRawProductReviewsCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <div className="row ptb20-lr10 bg-white">
              <h3>All Commments</h3>
            </div>

            <div className="row pb-10 pos-r">
              <div className="col bg-white">
                <table className="table table-sm">
                  <thead>
                    <th style={{ width:"80px" }}>
                      <caption style={{ padding:"0" }}>
                        <button type="button" className="btn btn-sm btn-success mlr-5" style={{ fontSize:"12px",padding:"3px 6px",marginLeft:"10px" }} onClick={(e) => { e.stopPropagation(); this.goToUrl(url_new) }}>
                          <i className="fas fa-plus"></i>
                        </button>
                      </caption>
                    </th>
                    <th>ID</th>
                    <th>Created At</th>
                    <th>Brand</th>
                    <th>Product</th>
                    <th>Customer</th>
                    <th>Rating</th>
                    <th>Message</th>
                  </thead>

                  <tbody>
                    {this.props.product_reviews.map(review => {
                      return (
                        <tr>
                          <td style={{ width:"80px" }}>
                            <button type="button" className="btn btn-sm btn-warning mlr-5" style={{ fontSize:"12px",padding:"3px 6px",marginLeft:"10px" }} onClick={(e) => { e.stopPropagation(); this.goToUrl(review.edit_url) }}>
                              <i className="fas fa-pencil-alt"></i>
                            </button>
                          </td>
                          <td>{review.id}</td>
                          <td>{review.created_at}</td>
                          <td>{review.brand.name}</td>
                          <td>{review.product.name}</td>
                          <td>{review.customer.phone}</td>
                          <td>{review.rating}</td>
                          <td>{review.message}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
