import React from "react"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class NewHeader extends React.Component {
  constructor(props) {
    super(props);
    self = this;

    this.viewRecords = this.viewRecords.bind(this)
  }

  viewRecords(event) {
    window.location.assign(event.currentTarget.value);
  }

  render () {
    let { name, url } = this.props;

    return (
      <ul className="nav justify-content-between p20">
        <li className="nav-item">
          <h4 className="bold">{name}</h4>
        </li>

        {
          url ? (
            <li className="nav-item">
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={renderTooltip("View All")}
              >
                <button value={url} type="button" className="btn btn-primary mlr-5" onClick={this.viewRecords}>
                  <i className="fas fa-list"></i>
                </button>
              </OverlayTrigger>
            </li>
          ) : (
            null
          )
        }
      </ul>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
