import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: '',
      name: this.props.wave.name,
      onDesktop: true,
      selectedBrand: this.props.wave.brand.id,
    };

    this.changeName = this.changeName.bind(this)
    this.changeBrand = this.changeBrand.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeBrand(event) {
    let brandId = Number(event.target.value);

    this.setState({
      selectedBrand: brandId,
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      name: this.state.name,
      brand_id: this.state.selectedBrand,
    })
    formData.append('wave', data)

    fetch(this.props.wave.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { wave, brands, showdrop_logo, url_new } = this.props;
    let {
      errors,
      onDesktop,
      name,
      selectedBrand,
    } = this.state;
    let pageName = 'waves';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={wave.name}
              url_show={wave.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">Brand</dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedBrand} onChange={this.changeBrand}>
                  <option value="" disabled selected>Select a Brand</option>

                  {brands.map(brand => {
                    return (
                      <option key={brand.id} value={brand.id}>{brand.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Brand</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
