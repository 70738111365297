import React from "react"
import Nav from "../shared/Nav";
import NewHeader from "../shared/headers/NewHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";
import Select from "react-select";

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: '',
      errors: '',
      image: null,
      imageUploaded: false,
      name: '',
      onDesktop: true,
      selectedCategories: [],
      selectedTags: [],
    };

    this.changeDescription = this.changeDescription.bind(this)
    this.changeImage = this.changeImage.bind(this)
    this.changeName = this.changeName.bind(this)
    this.newRecord = this.newRecord.bind(this)
    this.setSelectedTags = this.setSelectedTags.bind(this)
    this.setSelectedCategories = this.setSelectedCategories.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeDescription(event) {
    this.setState({
      description: event.target.value
    })
  }

  changeImage(event) {
    this.setState({
      image: event.target.files[0],
      imageUploaded: true
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  newRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      brand_id: this.props.brand.id,
      category_ids: this.state.selectedCategories.map(category => category.value),
      description: this.state.description,
      name: this.state.name,
      tags: this.state.selectedTags.map(tag => tag.label),
    })
    formData.append('product', data)

    if (this.state.imageUploaded) {
      formData.append('image', this.state.image)
    }

    fetch(this.props.url_create, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  setSelectedTags(option) {
    this.setState({
      selectedTags: option,
    })
  }

  setSelectedCategories(option) {
    this.setState({
      selectedCategories: option,
    })
  }

  render () {
    let {
      categories,
      products_url,
      tags,
      showdrop_logo,
    } = this.props;
    let {
      description,
      errors,
      name,
      onDesktop,
      selectedCategories,
      selectedTags,
    } = this.state;
    let pageName = 'brands';

    let categoryOptions = categories.map(category => {
      return { value: category.id, label: category.name }
    })
    let tagsOptions = tags.map(tag => {
      return { value: tag.name, label: tag.name }
    })

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <NewHeader
              name={'New Product'}
              url={products_url}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">
                Name
                <div className="fs-10 thin">Character Count: {name.length}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Description
                <div className="fs-10 thin">Character Count: {description.length}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Description" onChange={this.changeDescription} value={description} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">Image</dt>
              <dd className="col-sm-9">
                <label>-</label>
                <input className="form-control" type="file" id="formFile" onChange={this.changeImage} />
              </dd>

              <dt className="col-sm-3 text-right">Categories</dt>
              <dd className="col-sm-9">
                <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
                  <Select
                    isMulti
                    inputId="category-select"
                    value={selectedCategories}
                    onChange={this.setSelectedCategories}
                    options={categoryOptions}
                    maxMenuHeight={150}
                  />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">Tags</dt>
              <dd className="col-sm-9">
                <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
                  <Select
                    isMulti
                    inputId="tag-select"
                    value={selectedTags}
                    onChange={this.setSelectedTags}
                    options={tagsOptions}
                    maxMenuHeight={150}
                  />
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-success" onClick={this.newRecord}>Create Brand Product</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
