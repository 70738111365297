import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";

export default class Configure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allowPayments: this.props.machine.allow_payments,
      appOnly: this.props.machine.app_only,
      combineInstruction: this.props.machine.combine_instruction_texts,
      errors: '',
      headerImage: this.props.machine.header_image,
      headerImageUploaded: false,
      maxTransaction: this.props.machine.allowed_quantity_per_transaction,
      onDesktop: true,
      selectedPlanogramType: this.props.machine.planogram_type,
    };

    this.changeAllowPayments = this.changeAllowPayments.bind(this)
    this.changeAppOnly = this.changeAppOnly.bind(this)
    this.changeCombineInstruction = this.changeCombineInstruction.bind(this)
    this.changeHeaderImage = this.changeHeaderImage.bind(this)
    this.changeMaxTransaction = this.changeMaxTransaction.bind(this)
    this.changePlanogramType = this.changePlanogramType.bind(this)
    this.configureMachine = this.configureMachine.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeAllowPayments() {
    let allowPayments = !this.state.allowPayments;

    this.setState({
      allowPayments: allowPayments
    })
  }

  changeAppOnly() {
    let appOnly = !this.state.appOnly;

    this.setState({
      appOnly: appOnly
    })
  }

  changeCombineInstruction() {
    let combineInstruction = !this.state.combineInstruction;

    this.setState({
      combineInstruction: combineInstruction
    })
  }

  changeHeaderImage(event) {
    this.setState({
      headerImage: event.target.files[0],
      headerImageUploaded: true
    })
  }

  changeMaxTransaction(event) {
    this.setState({
      maxTransaction: event.target.value
    })
  }

  changePlanogramType(event) {
    this.setState({
      selectedPlanogramType: event.target.value,
    })
  }

  configureMachine() {
    window.location.assign(this.props.url_configure_machine)
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      app_only: this.state.appOnly,
      allow_payments: this.state.allowPayments,
      allowed_quantity_per_transaction: this.state.maxTransaction,
      combine_instruction_texts: this.state.combineInstruction,
      configure: true,
      planogram_type: this.state.selectedPlanogramType,
    })
    formData.append('machine', data)

    if (this.state.headerImageUploaded) {
      formData.append('header_image', this.state.headerImage)
    }

    fetch(this.props.machine.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { machine, planogram_types, showdrop_logo, url_new } = this.props;
    let {
      allowPayments,
      appOnly,
      combineInstruction,
      errors,
      maxTransaction,
      onDesktop,
      selectedPlanogramType,
    } = this.state;
    let pageName = 'configure_machine'

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={machine.name}
              url_show={machine.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{machine.id}</dd>

              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">{machine.name}</dd>

              <dt className="col-sm-3 text-right">Location</dt>
              <dd className="col-sm-9">{machine.location || "-"}</dd>

              <dt className="col-sm-3 text-right">Email</dt>
              <dd className="col-sm-9">{machine.email || "-"}</dd>

              <dt className="col-sm-3 text-right">Password</dt>
              <dd className="col-sm-9">{machine.password || "-"}</dd>

              <dt className="col-sm-3 text-right">
                Planogram Type
                <div className="fs-10 thin">(is the machine single or multi vend?)</div>
              </dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedPlanogramType} onChange={this.changePlanogramType}>
                  <option value="" disabled selected>Select a Planogram Type</option>

                  {planogram_types.map(planogram_type => {
                    return (
                      <option key={planogram_type.id} value={planogram_type.id}>{planogram_type.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">
                Max Per Transaction
                <div className="fs-10 thin">(What is the max number of items a customer can take?)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Max Number" onChange={this.changeMaxTransaction} value={maxTransaction} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Header Image
                <div className="fs-10 thin">(The image seen by the customer at the top of the phone input screen to get a PIN)</div>
              </dt>
              <dd className="col-sm-9">
                <label>{machine.header_image_name}</label>
                <input className="form-control" type="file" id="formFile" onChange={this.changeHeaderImage} />
              </dd>

              <dt className="col-sm-3 text-right">
                Combine Instruction Messages?
                <div className="fs-10 thin">(Combine all instruction messages into 1 message?)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeCombineInstruction} checked={combineInstruction} />
                  </div>
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Mobile App Only?
                <div className="fs-10 thin">(Customers are forced to use the app)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeAppOnly} checked={appOnly} />
                  </div>
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Allow Payments?
                <div className="fs-10 thin">(This machine allows products to be purchased)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeAllowPayments} checked={allowPayments} />
                  </div>
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Save</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
