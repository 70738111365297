import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnCount: this.props.planogram.column_count,
      columnMax: this.props.planogram.column_max,
      errors: '',
      name: this.props.planogram.name,
      onDesktop: true,
      rowCount: this.props.planogram.row_count,
    };

    this.changeColumnCount = this.changeColumnCount.bind(this)
    this.changeColumnMax = this.changeColumnMax.bind(this)
    this.changeName = this.changeName.bind(this)
    this.changeRowCount = this.changeRowCount.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeColumnCount(event) {
    this.setState({
      columnCount: event.target.value
    })
  }

  changeColumnMax(event) {
    this.setState({
      columnMax: event.target.value
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  changeRowCount(event) {
    this.setState({
      rowCount: event.target.value
    })
  }

  editRecord() {
    let that = this;
    let data = JSON.stringify({
      column_count: this.state.columnCount,
      column_max: this.state.columnMax,
      name: this.state.name,
      row_count: this.state.rowCount,
    })

    fetch(this.props.planogram.edit_path, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { planogram, showdrop_logo, url_new } = this.props;
    let { columnCount, columnMax, errors, onDesktop, name, rowCount } = this.state;
    let pageName = 'planograms';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={planogram.name}
              url_show={planogram.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                # of Rows
                <div className="fs-10 thin">(Number of shelves in the machine)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Row Count" onChange={this.changeRowCount} value={rowCount} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                # of Columns
                <div className="fs-10 thin">(Number of positions in each shelf)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Column Count" onChange={this.changeColumnCount} value={columnCount} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Column Max Quantity
                <div className="fs-10 thin">(The max quantity for each position)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Column Max" onChange={this.changeColumnMax} value={columnMax} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <p className="color-red bold">Warning! By updating any field on this planogram, you essentially "reset" the planogram. All products will need to be setup again.</p>
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Planogram Template</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
