import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idDirection: '',
      nameDirection: '',
      onDesktop: true,
      sortedCompanies: this.props.companies,
    }

    this.sortById = this.sortById.bind(this)
    this.sortByName = this.sortByName.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  sortById() {
    let companies = this.state.sortedCompanies
    let direction = '';

    if (this.state.idDirection == "DESC") {
      companies = companies.sort((a, b) => a.id > b.id ? -1 : 1);
      direction = "ASC"
    } else {
      companies = companies.sort((a, b) => a.id > b.id ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      nameDirection: '',
      idDirection: direction,
      sortedCompanies: companies,
    })
  }

  sortByName() {
    let companies = this.state.sortedCompanies
    let direction = '';

    if (this.state.nameDirection == "DESC") {
      companies = companies.sort((a, b) => a.name > b.name ? -1 : 1);
      direction = "ASC"
    } else {
      companies = companies.sort((a, b) => a.name > b.name ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      nameDirection: direction,
      idDirection: '',
      sortedCompanies: companies,
    })
  }

  render () {
    let { showdrop_logo, url_new } = this.props;
    let { idDirection, nameDirection, onDesktop, sortedCompanies } = this.state;
    let pageName = 'companies';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Companies'}
              url={url_new}
            />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" className="sortable" onClick={this.sortById}>
                    ID
                    <RenderArrow direction={idDirection} />
                  </th>
                  <th scope="col" className="sortable" onClick={this.sortByName}>
                    Name
                    <RenderArrow direction={nameDirection} />
                  </th>
                  <th scope="col" className="w150"></th>
                </tr>
              </thead>
              <tbody>
                {sortedCompanies.map(company => {
                  return (
                    <tr className="clickable" onClick={(e) => {e.stopPropagation(); this.goToUrl(company.url)}}>
                      <th scope="row">{company.id}</th>
                      <td>{company.name}</td>
                      <td>
                        <IndexButtons
                          id={company.id}
                          url_show={null}
                          url_edit={null}
                          url_delete={null}
                          hide_message={"Are you sure?"}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function RenderArrow(props) {
  let { direction } = props;

  if (direction) {
    if (direction == "DESC") {
      return (
        <i className="fas fa-caret-down"></i>
      )
    } else {
      return (
        <i className="fas fa-caret-up"></i>
      )
    }
  } else {
    return null;
  }
}
