import React from "react"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Option extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changed: false,
      loading: false,
      overstockInterval: this.props.option.machine_product.overstock_interval,
      overstockPriority: this.props.option.machine_product.overstock_priority,
      price: this.props.option.machine_product.price,
      purchasable: this.props.option.machine_product.purchasable,
      sampleable: this.props.option.machine_product.sampleable,
      selectedPackage: this.props.option.package.id,
    };

    this.changeOverstockInterval = this.changeOverstockInterval.bind(this)
    this.changeOverstockPriority = this.changeOverstockPriority.bind(this)
    this.changePackage = this.changePackage.bind(this)
    this.changePrice = this.changePrice.bind(this)
    this.changePurchasable = this.changePurchasable.bind(this)
    this.changeSampleable = this.changeSampleable.bind(this)
    this.removeOption = this.removeOption.bind(this)
    this.updateMachineProduct = this.updateMachineProduct.bind(this)
  }

  changeOverstockInterval(event) {
    this.setState({
      changed: true,
      overstockInterval: event.target.value
    })
  }

  changeOverstockPriority(event) {
    this.setState({
      changed: true,
      overstockPriority: event.target.value
    })
  }

  changePackage(event) {
    this.setState({
      changed: true,
      selectedPackage: event.target.value
    })
  }

  changePrice(event) {
    this.setState({
      changed: true,
      price: event.target.value
    })
  }

  changePurchasable() {
    let purchasable = !this.state.purchasable;

    this.setState({
      changed: true,
      purchasable: purchasable
    })
  }

  changeSampleable() {
    let sampleable = !this.state.sampleable;

    this.setState({
      changed: true,
      sampleable: sampleable
    })
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  removeOption(option) {
    if (confirm("Are you sure? This will also end the campaign.")) {
      let that = this;
      const formData = new FormData();
      let data = JSON.stringify({
        campaign_package_id: option.package.id,
        brand_id: option.brand.id,
        product_id: option.product.id,
        campaign_id: option.campaign.id,
        id: option.id,
      })
      formData.append('planogram_option', data)

      fetch(this.props.url_delete, {
        method: 'POST',
          headers: {},
          body: formData
        }).then(response => {
          return response.json();
        }).then((data) => {
          if (data.errors) {
            that.setState({
              errors: data.errors
            })
          } else {
            window.location.reload()
          }
        }
      )
    }
  }

  updateMachineProduct(option) {
    this.setState({
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      campaign_id: this.props.option.campaign.id,
      id: option.machine_product.id,
      option_id: this.props.option.id,
      overstock_interval: this.state.overstockInterval,
      overstock_priority: this.state.overstockPriority,
      campaign_package_id: this.state.selectedPackage,
      price: this.state.price,
      purchasable: this.state.purchasable,
      sampleable: this.state.sampleable,
    })
    formData.append('machine_product', data)

    fetch(this.props.option.url_update, {
      method: 'PUT',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors
          })
        } else {
          that.setState({
            loading: false,
          })
        }
      }
    )
  }

  render () {
    let { option } = this.props;
    let { changed, loading, overstockInterval, overstockPriority, price, purchasable, sampleable, selectedPackage } = this.state;

    return (
      <dl className="row" style={{ borderBottom:"1px solid #00000050",margin:"0",padding:"10px" }}>
        <dt className="col-sm-4" style={{ textAlign:"right" }}>Brand</dt>
        <dd className="col-sm-8">{option.brand.name}</dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}>Package</dt>
        <dd className="col-sm-8">
          <select className="form-select" value={selectedPackage} onChange={this.changePackage} style={{ width:"350px",fontSize:"12px" }}>
            {option.campaign_packages.map(campaign_package => {
              return (
                <option key={campaign_package.id} value={campaign_package.id}>{campaign_package.name}</option>
              )
            })}
          </select>
        </dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}>Product</dt>
        <dd className="col-sm-8">{option.product.name} [{option.product.id}]</dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}>Campaign</dt>
        <dd className="col-sm-8">{option.campaign.id}</dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}>
          Sampleable
          <div className="fs-10 thin">(Do we allow this product to be given away as free samples?)</div>
        </dt>
        <dd className="col-sm-8">
          <div className="input-group mb-3" style={{ display:"flex" }}>
            <div className="form-check">
              <input type="checkbox" className="form-check-input" onChange={this.changeSampleable} checked={sampleable} />
            </div>
          </div>
        </dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}>
          Purchasable
          <div className="fs-10 thin">(Do we allow this product to be purchased?)</div>
        </dt>
        <dd className="col-sm-8">
          <div className="input-group mb-3" style={{ display:"flex" }}>
            <div className="form-check">
              <input type="checkbox" className="form-check-input" onChange={this.changePurchasable} checked={purchasable} />
            </div>
          </div>
        </dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}>
          Price
          <div className="fs-10 thin">Ex: (1.99, no $)</div>
        </dt>
        <dd className="col-sm-8" style={{ display:"flex" }}>
          <input type="text" className="form-control" placeholder="Not Set" onChange={this.changePrice} value={price} disabled={purchasable ? "" : "disabled"} style={{ width:"100px",fontSize:"12px" }} />
        </dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}>
          Overstock Interval
          <div className="fs-10 thin">(Number of days until a product is considered overstock in this machine, Ex. 25)</div>
        </dt>
        <dd className="col-sm-8" style={{ display:"flex" }}>
          <input type="text" className="form-control" placeholder="25" onChange={this.changeOverstockInterval} value={overstockInterval} style={{ width:"100px",fontSize:"12px" }} />
        </dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}>
          Overstock Priority
          <div className="fs-10 thin">(When product is overstock, set importance of which will show to customer first.)</div>
        </dt>
        <dd className="col-sm-8" style={{ display:"flex" }}>
          <select className="form-select" value={overstockPriority} onChange={this.changeOverstockPriority} style={{ width:"100px",fontSize:"12px" }}>
            {['high', 'medium', 'low'].map(priority => {
              return (
                <option key={priority} value={priority}>{priority}</option>
              )
            })}
          </select>
        </dd>

        <dt className="col-sm-4" style={{ textAlign:"right" }}></dt>
        <dd className="col-sm-8">
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 100 }}
            overlay={renderTooltip("Save Option")}
          >
            <button type="button" className={`btn ${loading || !changed ? "btn-secondary" : "btn-success"}`} onClick={loading || !changed ? null : () => this.updateMachineProduct(option)} disabled={loading || !changed ? 'disabled': ''} style={{ marginRight:"4px" }}>
              {
                loading ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  "Save"
                )
              }
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 100 }}
            overlay={renderTooltip("Remove Product from Planogram")}
          >
            <button type="button" className="btn btn-danger" onClick={() => this.removeOption(option)}>
              Remove
            </button>
          </OverlayTrigger>
        </dd>
      </dl>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
