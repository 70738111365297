import React from "react"

export default function ExtractTable({ extracts }) {
  const sortedExtracts = extracts.sort((a, b) => b.id - a.id)

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th>id</th>
          <th>name</th>
          <th>source</th>
          <th>month</th>
          <th>created at</th>
          <th>status</th>
          <th>max date</th>
          <th>creates</th>
          <th>updates</th>
          <th>skips</th>
        </tr>
      </thead>
      <tbody>
        {sortedExtracts.map((extract) => (
          <ExtractTableRow key={extract.id} extract={extract} />
        ))}
      </tbody>
    </table>
  )
}

function ExtractTableRow({ extract }) {
  let rowClassName = null
  if (extract.status === "failed") { rowClassName = "table-danger" }
  else if (extract.status !== "succeeded") { rowClassName = "table-warning" }
  const month = undefinedOrNull(extract.year) && undefinedOrNull(extract.month) ? "" : `${extract.year}-${extract.month}`
  const createdAt = new Date(extract.created_at).toLocaleString()
  return (
    <tr className={rowClassName}>
      <td>{extract.id}</td>
      <td>{extract.name}</td>
      <td>{extract.source}</td>
      <td>{month}</td>
      <td>{createdAt}</td>
      <td>{extract.status}</td>
      <td>{extract.maximum_date}</td>
      <td>{extract.ra_impression_created || 0}</td>
      <td>{extract.ra_impression_updated || 0}</td>
      <td>{extract.ra_impression_skipped || 0}</td>
    </tr>
  )
}

function undefinedOrNull(value) {
  return value === undefined || value === null
}
