import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProductRow from "./ProductRow";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadedProducts: this.props.products,
      downloading: false,
      onDesktop: true,
      selectedByDate: true,
      selectedByRating: false,
    };

    this.downloadRawProductReviewsCsv = this.downloadRawProductReviewsCsv.bind(this)
    this.deleteMessage = this.deleteMessage.bind(this)
    this.getByDate = this.getByDate.bind(this)
    this.getByRating = this.getByRating.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  downloadRawProductReviewsCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_raw_product_reviews_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `raw-product-reviews_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  deleteMessage(review) {
    if (confirm("Are you sure you want to delete this comment?")) {
      this.setState({
        loading: true
      })

      const formData = new FormData();
      let data = JSON.stringify({
        id: review.id,
      })
      formData.append('product_review', data)

      fetch(review.url_update, {
        method: 'PUT',
          headers: {},
          body: formData
        }).then(response => {
          return response.json();
        }).then((data) => {
          if (data.errors) {
          } else {
            window.location.reload()
          }
        }
      )
    }
  }

  getByDate() {
    this.setState({
      selectedByDate: true,
      selectedByRating: false,
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({})
    formData.append('product_rating', data)

    fetch(this.props.url_fetch_by_date, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false
        })
      } else {
        that.setState({
          loadedProducts: data.products,
          loading: false
        })
      }
    })
  }

  getByRating() {
    this.setState({
      selectedByDate: false,
      selectedByRating: true,
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({})
    formData.append('product_rating', data)

    fetch(this.props.url_fetch_by_rating, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false
        })
      } else {
        that.setState({
          loadedProducts: data.products,
          loading: false
        })
      }
    })
  }

  render () {
    let { product_reviews_count, showdrop_logo } = this.props;
    let { downloading, loadedProducts, loading, onDesktop, selectedByDate, selectedByRating } = this.state;
    let pageName = 'product_ratings';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Product Ratings'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="col pos-a">
                <span className="bold">{product_reviews_count}</span> product reviews
              </div>

              <div className="col">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Raw Product Reviews")}
                >
                  <button type="button" className="btn btn-sm btn-success mb-5px fs-17 w35 floatr" onClick={this.downloadRawProductReviewsCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <div className="row bg-extra-gray" style={{ margin:"0" }}>
              <div className="col">
                <div style={{ display:"flex",width:"80%",margin:"auto" }}>
                  <div className="hoverable" onClick={this.getByDate} disabled={loading ? 'disabled' : ''} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByDate ? "#20cb9a" : "white"}`,color:`${selectedByDate ? "white" : "black"}` }}>
                    {
                      loading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        'By Date'
                      )
                    }
                  </div>

                  <div className="hoverable" onClick={this.getByRating} disabled={loading ? 'disabled' : ''} style={{ flex:"1",textAlign:"center",margin:"10px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",backgroundColor:`${selectedByRating ? "#20cb9a" : "white"}`,color:`${selectedByRating ? "white" : "black"}` }}>
                    {
                      loading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        'By Rating'
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion" id="accordionExample">
              {
                loading ? (
                  null
                ) : (
                  loadedProducts.map((product,i) => {
                    return (
                      <ProductRow
                        i={i}
                        product={product}
                      />
                    )
                  })
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
