import React from "react"
import Review from "./Review"

export default class ProductRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadedProduct: {}
    }
  }

  componentDidMount() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.product.id,
    })
    formData.append('product_rating', data)

    fetch(this.props.product.url_fetch_product, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loadedProduct: data.product,
          loading: false
        })
      }
    })
  }

  render () {
    let { loadedProduct, loading } = this.state;
    let { i } = this.props;

    let filteredReviews = loadedProduct ? loadedProduct.written_reviews : []
    let filteredWrittenReviews = loadedProduct && (filteredReviews && filteredReviews.length > 0) ? filteredReviews.filter(review => { return review.message != null && review.message != "" }) : []
    let avgRating = loadedProduct ? loadedProduct.avg_rating : 0

    const displayStars = (rating) => {
      if (rating == 5.0) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
          </>
        )
      } else if (rating >= 4.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
          </>
        )
      } else if (rating >= 4) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 3.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 3) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 2.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 2) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 1.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 1) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 0.5) {
        return (
          <>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else {
        return (
          <>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      }
    }
    const displayRecentReviewCount = (count) => {
      if (count > 0) {
        return (
          <div style={{ backgroundColor:"red",borderRadius:"100px",color:"white",fontWeight:"bold",height:"30px",width:"30px",paddingTop:"3px" }}>{count}</div>
        )
      } else {
        return null
      }
    }
    const displayReviews = (reviews, i) => {
      if (reviews.length > 0) {
        return (
          <div id={`collapseProduct${i}`} className="collapse" aria-labelledby={`headingProduct${i}`}>
            <div className="card-body">
              <ul className="no-list">
                {reviews.map(review => {
                  return (
                    <Review review={review} />
                  )
                })}
              </ul>

            </div>
          </div>
        )
      } else {
        return null
      }
    }
    const displayBreakdown = (filteredReviews) => {
      let five_star_reviews = filteredReviews.filter(review => { return review.rating == 5 })
      let five_star_review_percentage = filteredReviews.length > 0 ? (five_star_reviews.length / filteredReviews.length) * 100 : 0
      let four_star_reviews = filteredReviews.filter(review => { return review.rating == 4 })
      let four_star_review_percentage = filteredReviews.length > 0 ? (four_star_reviews.length / filteredReviews.length) * 100 : 0
      let three_star_reviews = filteredReviews.filter(review => { return review.rating == 3 })
      let three_star_review_percentage = filteredReviews.length > 0 ? (three_star_reviews.length / filteredReviews.length) * 100 : 0
      let two_star_reviews = filteredReviews.filter(review => { return review.rating == 2 })
      let two_star_review_percentage = filteredReviews.length > 0 ? (two_star_reviews.length / filteredReviews.length) * 100 : 0
      let one_star_reviews = filteredReviews.filter(review => { return review.rating == 1 })
      let one_star_review_percentage = filteredReviews.length > 0 ? (one_star_reviews.length / filteredReviews.length) * 100 : 0

      return (
        <div style={{ color:"black" }}>
          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>5 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${five_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(five_star_review_percentage)}%</div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>4 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${four_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(four_star_review_percentage)}%</div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>3 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${three_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(three_star_review_percentage)}%</div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>2 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${two_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(two_star_review_percentage)}%</div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>1 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${one_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(one_star_review_percentage)}%</div>
          </div>
        </div>
      )
    }
    let displayWrittenReviewCount = (count) => {
      if (count > 1) {
        return (
          <>({count} Comments)</>
        )
      } else if (count > 0) {
        return (
          <>({count} Comment)</>
        )
      } else {
        return null
      }
    }

    return (
      loading ? (
        <></>
      ) : (
        loadedProduct ? (
          <div key={`Review-${i}-Product-${loadedProduct.id}`} className="card">
            <div className="card-header" id={`headingProduct${i}`}>
              <h2 className="mb-0">
                <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target={`#collapseProduct${i}`} aria-expanded="true" aria-controls={`collapseProduct${i}`}>
                  <div className="d-flex">
                    <span className="f1" style={{ maxWidth:"40px",textAlign:"center",paddingTop:"10px" }}>
                      {displayRecentReviewCount(loadedProduct.recent_reviews_amount)}
                    </span>

                    <span className="f1" style={{ maxWidth:"60px",textAlign:"center" }}>
                      {loadedProduct.logo ? (
                        <img className="w50 h-auto" src={loadedProduct.logo_url} />
                      ) : (
                        null
                      )}

                      <div style={{ border:"1px solid #00000050",borderRadius:"4px",margin:"2px 4px" }}>
                        <div style={{ fontSize:"14px",fontWeight:"bold" }}>{numberWithCommas(loadedProduct.claimed_count)}</div>
                        <div style={{ fontSize:"10px" }}>claimed</div>
                      </div>
                    </span>

                    <span className="f1">
                      <div className="fs-18">
                        {loadedProduct.brand_name} - {loadedProduct.name}
                        <span style={{ fontSize:"12px",color:"#616161" }}> (ID: {loadedProduct.id})</span>
                      </div>

                      <div style={{ display:"flex" }}>
                        <span style={{ color:"orange" }}>{displayStars(avgRating)}</span>
                        <span style={{ marginLeft:"4px" }}>{Number(avgRating) ? avgRating : "0.0"}</span>
                        <span style={{ marginLeft:"4px",color:"#616161",fontSize:"14px" }}>
                          <span>({filteredReviews.length} Ratings) </span>
                          <span style={{ color:"#20cb9a",fontWeight:"bold" }}>{displayWrittenReviewCount(filteredWrittenReviews.length)}</span>
                        </span>
                      </div>

                      <div style={{ color:"orange",paddingTop:"7px" }}>
                        {displayBreakdown(filteredReviews)}
                      </div>
                    </span>
                  </div>
                </button>
              </h2>
            </div>

            {displayReviews(filteredWrittenReviews, i)}
          </div>
        ) : (
          <></>
        )
      )
    );
  }
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
