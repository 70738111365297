import React from "react"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class IndexButtons extends React.Component {
  constructor(props) {
    super(props);

    this.copyRecord = this.copyRecord.bind(this)
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  hideRecord(id, url, hide_message) {
    if( confirm(hide_message) ) {
      let data = JSON.stringify({
        id: id,
      })

      fetch(url, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: data
      }).then(response => {
        return response.json();
      }).then(data => {
        window.location.assign(data.url)
      })
    }
  }

  copyRecord() {
    let that = this;
    let data = JSON.stringify({})

    fetch(this.props.url_copy, {
      method: 'PUT',
      headers: {},
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload()
      }
    })
  }

  render () {
    let { id, url_show, url_edit, url_copy, url_delete, hide_message } = this.props;

    return (
      <div style={{ textAlign:"right" }}>
        <CopyButton
          url={url_copy}
          onClick={(e) => {e.stopPropagation(); this.copyRecord()}}
        />

        <ShowButton
          url={url_show}
          onClick={(e) => {e.stopPropagation(); this.goToUrl(url_show)}}
        />

        <EditButton
          url={url_edit}
          onClick={(e) => {e.stopPropagation(); this.goToUrl(url_edit)}}
        />

        <DeleteButton
          url={url_delete}
          onClick={(e) => {e.stopPropagation(); this.hideRecord(id, url_delete, hide_message)}}
        />
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};

function CopyButton(props) {
  let { onClick, url } = props;

  if (url) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={renderTooltip("Copy")}
      >
        <button value={url} type="button" className="btn btn-sm btn-info mlr-5" onClick={onClick}>
          <i className="fas fa-copy"></i>
        </button>
      </OverlayTrigger>
    )
  } else {
    return null;
  }
}

function ShowButton(props) {
  let { onClick, url } = props;

  if (url) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={renderTooltip("View")}
      >
        <button value={url} type="button" className="btn btn-sm btn-primary mlr-5" onClick={onClick}>
          <i className="fas fa-eye"></i>
        </button>
      </OverlayTrigger>
    )
  } else {
    return null;
  }
}

function EditButton(props) {
  let { onClick, url } = props;

  if (url) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={renderTooltip("Edit")}
      >
        <button value={url} type="button" className="btn btn-sm btn-warning mlr-5" onClick={onClick}>
          <i className="fas fa-pencil-alt"></i>
        </button>
      </OverlayTrigger>
    )
  } else {
    return null;
  }
}

function DeleteButton(props) {
  let { onClick, url } = props;

  if (url) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={renderTooltip("Delete")}
      >
        <button type="button" className="btn btn-sm btn-danger mlr-5" onClick={onClick}>
          <i className="fas fa-trash-alt"></i>
        </button>
      </OverlayTrigger>
    )
  } else {
    return null;
  }
}
