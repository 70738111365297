import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      onDesktop: true,
      standard_latest_version: this.props.standard_latest_version,
      value: "",
      version: this.props.latest_version,
    };

    this.changeValue = this.changeValue.bind(this)
    this.createVersion = this.createVersion.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeValue(event) {
    this.setState({
      value: event.target.value
    });
  }

  createVersion(e) {
    this.setState({
      creating: true
    })

    let that = this;

    let data = JSON.stringify({
      version: this.state.value,
    })

    fetch(this.props.url_create, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          creating: false,
          version: data.version,
          standard_latest_version: data.standard_latest_version,
        })
      }
    })
  }

  render () {
    let { showdrop_logo } = this.props;
    let { creating, onDesktop, standard_latest_version, value, version } = this.state;
    let pageName = 'mobile_version';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Mobile Version'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="col" style={{ paddingLeft:"20px" }}>
                Force mobile updates below version: <span className="bold">#{version}</span> ({standard_latest_version})
              </div>

              <div style={{ padding:"20px 20px 40px" }}>
                <input className="form-control br-r-4 f1" placeholder="Enter Version" onChange={this.changeValue} value={value} disabled={creating ? 'disabled' : ''} />

                <button className="btn btn-primary btn-sm bold f1" style={{ width:"100%",margin:"10px 0",padding:"10px" }} type="submit" onClick={creating ? null : this.createVersion} disabled={creating ? 'disabled' : ''}>
                  {
                    creating ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      "Set"
                    )
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
