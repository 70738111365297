import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: '',
      onDesktop: true
    }

    this.enterMaintenance = this.enterMaintenance.bind(this)
    this.getTemperature = this.getTemperature.bind(this)
    this.setOutOfStock = this.setOutOfStock.bind(this)
    this.setTemperatureMode0 = this.setTemperatureMode0.bind(this)
    this.setTemperatureMode1 = this.setTemperatureMode1.bind(this)
    this.setTemperatureMode2 = this.setTemperatureMode2.bind(this)
    this.setTemperatureLock = this.setTemperatureLock.bind(this)
    this.startConfiguration = this.startConfiguration.bind(this)
    this.syncAdvertisements = this.syncAdvertisements.bind(this)
    this.syncCodes = this.syncCodes.bind(this)
    this.syncProducts = this.syncProducts.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  enterMaintenance() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_enter_maintenance, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  getTemperature() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_get_temp, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setOutOfStock() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_out_of_stock, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setTemperatureMode0() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_temp_mode0, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setTemperatureMode1() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_temp_mode1, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setTemperatureMode2() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_temp_mode2, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  setTemperatureLock() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_set_temp_lock, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  startConfiguration() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_start_configuration, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })

      window.location.reload();
    })
  }

  syncAdvertisements() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_advertisements, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })
    })
  }

  syncCodes() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_codes, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })
    })
  }

  syncProducts() {
    let that = this;

    that.setState({
      message: '',
      loading: true
    })

    fetch(this.props.url_products, {
      method: 'GET',
      headers: {},
    }).then(response => {
      return response.json();
    }).then((data) => {
      that.setState({
        message: data.message,
        loading: false
      })
    })
  }

  render () {
    let { machine, showdrop_logo, url_new } = this.props;
    let { loading, message, onDesktop } = this.state;
    let pageName = 'configure_machine'

    const showMessage = () => {
      if (message) {
        return (
          <p>{message}</p>
        )
      } else {
        return null;
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={`${machine.id} // ${machine.nickname}`}
              subtext={null}
              url_edit={machine.url_edit}
              url_new={url_new}
            />

            <div className="m10 center-text color-green italic">
              {showMessage()}
            </div>

            {
              machine.out_of_stock ? (
                <div className="m10 center-text color-red bold fs-18">
                  Machine is Currently Out of Stock!!
                </div>
              ) : (
                null
              )
            }

            <div className="d-flex m10">
              <button className="f1 m10 p10 border-none color-white bold bg-secondary" style={{ borderRadius:"4px" }}  onClick={this.setOutOfStock} disabled={loading ? 'disabled' : ''}>
                {
                  machine.out_of_stock ? (
                    <>
                      <i className="fas fa-unlock pdr-5"></i>
                      Set Machine In Stock
                    </>
                  ) : (
                    <>
                      <i className="fas fa-lock pdr-5"></i>
                      Set Machine Out of Stock
                    </>
                  )
                }
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-red" style={{ borderRadius:"4px" }}  onClick={this.enterMaintenance} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-wrench pdr-5"></i>
                Enter Maintenance
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-primary" style={{ borderRadius:"4px" }} onClick={this.startConfiguration} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-toolbox pdr-5"></i>
                Start Configuration
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-primary" style={{ borderRadius:"4px" }}  onClick={this.syncCodes} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-code pdr-5"></i>
                Sync Codes
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-primary" style={{ borderRadius:"4px" }}  onClick={this.syncProducts} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-shopping-cart pdr-5"></i>
                Sync Products
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-primary" style={{ borderRadius:"4px" }}  onClick={this.syncAdvertisements} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-bullhorn pdr-5"></i>
                Sync Advertisements
              </button>
            </div>

            <div className="d-flex m10">
              <button className="f1 m10 p10 border-none color-white bold bg-success" style={{ borderRadius:"4px" }}  onClick={this.getTemperature} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-thermometer-full pdr-5"></i>
                Get Current Temperature
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-info" style={{ borderRadius:"4px" }}  onClick={this.setTemperatureMode0} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-power-off pdr-5"></i>
                Set Ambient Temperature
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-info" style={{ borderRadius:"4px" }}  onClick={this.setTemperatureMode1} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-wind pdr-5"></i>
                Set Weak Cooling Temperature
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-info" style={{ borderRadius:"4px" }}  onClick={this.setTemperatureMode2} disabled={loading ? 'disabled' : ''}>
                <i className="fas fa-snowflake pdr-5"></i>
                Set Strong Cooling Temperature
              </button>

              <button className="f1 m10 p10 border-none color-white bold bg-secondary" style={{ borderRadius:"4px" }}  onClick={this.setTemperatureLock} disabled={loading ? 'disabled' : ''}>
                {
                  machine.temperature_lock ? (
                    <>
                      <i className="fas fa-unlock pdr-5"></i>
                      Unlock Temperature Mode
                    </>
                  ) : (
                    <>
                      <i className="fas fa-lock pdr-5"></i>
                      Lock Temperature Mode
                    </>
                  )
                }
              </button>
            </div>

            <div style={{ paddingBottom:"10px" }}>
              <div className="pos-r" style={{ paddingLeft:"20px" }}>
                Current Temperature:
                <strong> {machine.temperature}°</strong>
              </div>

              <div className="pos-r" style={{ paddingLeft:"20px" }}>
                Current Temperature Mode:
                <strong> {machine.temperature_mode} {machine.temperature_lock ? "(Locked)" : ""} </strong>
              </div>
            </div>

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{machine.id}</dd>

              <dt className="col-sm-3 text-right">Nickname (Internal)</dt>
              <dd className="col-sm-9">{machine.nickname || '-'}</dd>

              <dt className="col-sm-3 text-right">Name (Customer Facing)</dt>
              <dd className="col-sm-9">{machine.name || '-'}</dd>

              <dt className="col-sm-3 text-right">Location</dt>
              <dd className="col-sm-9">{machine.location || '-'}</dd>

              <dt className="col-sm-3 text-right">Zipcode</dt>
              <dd className="col-sm-9">{machine.zipcode || '-'}</dd>

              <dt className="col-sm-3 text-right">Latitude</dt>
              <dd className="col-sm-9">{machine.latitude || '-'}</dd>

              <dt className="col-sm-3 text-right">Longitude</dt>
              <dd className="col-sm-9">{machine.longitude || '-'}</dd>

              <dt className="col-sm-3 text-right">Notes</dt>
              <dd className="col-sm-9">{machine.notes || '-'}</dd>

              <dt className="col-sm-3 text-right">Retail Aware Location ID</dt>
              <dd className="col-sm-9">{machine.ra_machine_id || '-'}</dd>

              <dt className="col-sm-3 text-right">Mariano's Store ID</dt>
              <dd className="col-sm-9">{machine.marianos_store_id || '-'}</dd>

              <dt className="col-sm-3 text-right">Mariano's Facility ID</dt>
              <dd className="col-sm-9">{machine.marianos_facility_id || '-'}</dd>

              <dt className="col-sm-3 text-right">Mariano's Address</dt>
              <dd className="col-sm-9">{machine.marianos_address || '-'}</dd>

              <dt className="col-sm-3 text-right">Email</dt>
              <dd className="col-sm-9">{machine.email || '-'}</dd>

              <dt className="col-sm-3 text-right">Password</dt>
              <dd className="col-sm-9">{machine.password || '-'}</dd>

              <dt className="col-sm-3 text-right">Associate Password</dt>
              <dd className="col-sm-9">{machine.associate_password || '-'}</dd>

              <dt className="col-sm-3 text-right">Out Of Stock?</dt>
              <dd className="col-sm-9">{machine.out_of_stock ? "true" : "false"}</dd>

              <dt className="col-sm-3 text-right">Out Of Stock Message</dt>
              <dd className="col-sm-9">{machine.out_of_stock_message || '-'}</dd>

              <dt className="col-sm-3 text-right">Combine Instruction Messages?</dt>
              <dd className="col-sm-9">{machine.combine_instruction_texts ? "true" : "false"}</dd>

              <dt className="col-sm-3 text-right">Automatic?</dt>
              <dd className="col-sm-9">{machine.automatic ? "true" : "false"}</dd>

              <dt className="col-sm-3 text-right">App Only?</dt>
              <dd className="col-sm-9">{machine.app_only ? "true" : "false"}</dd>

              <dt className="col-sm-3 text-right">Allow Payments?</dt>
              <dd className="col-sm-9">{machine.allow_payments ? "true" : "false"}</dd>

              <dt className="col-sm-3 text-right">Legacy Machine?</dt>
              <dd className="col-sm-9">{machine.non_api ? "true" : "false"}</dd>

              <dt className="col-sm-3 text-right">Customer Facing URL</dt>
              <dd className="col-sm-9">
                <a href={machine.customer_facing_url} target="_blank">{machine.customer_facing_url}</a>
              </dd>

              <dt className="col-sm-3 text-right">Company ID</dt>
              <dd className="col-sm-9">{machine.company || "-"}</dd>

              <dt className="col-sm-3 text-right">Machine Portal URL</dt>
              <dd className="col-sm-9">
                <a href={machine.machine_portal_url} target="_blank">{machine.machine_portal_url}</a>
              </dd>

              <dt className="col-sm-3 text-right">Associate Replenishment URL</dt>
              <dd className="col-sm-9">
                <a href={machine.associate_replenishment_url} target="_blank">{machine.associate_replenishment_url}</a>
              </dd>

              <dt className="col-sm-3 text-right">Codes Remaining</dt>
              <dd className="col-sm-9">{machine.codes_remaining}</dd>

              <dt className="col-sm-3 text-right">Planogram</dt>
              <dd className="col-sm-9">{machine.planogram.name ? machine.planogram.name : '-'}</dd>

              <dt className="col-sm-3 text-right">Planogram Type</dt>
              <dd className="col-sm-9">{machine.planogram_type || '-'}</dd>

              <dt className="col-sm-3 text-right">Max Per Transaction</dt>
              <dd className="col-sm-9">{machine.allowed_quantity_per_transaction || '-'}</dd>

              <dt className="col-sm-3 text-right">Header Image</dt>
              <dd className="col-sm-9">
                {machine.header_image ? (
                  <img className="w300 h-auto" src={machine.header_image_url} />
                ) : (
                  null
                )}

                <div>{machine.header_image_name}</div>
              </dd>

              <dt className="col-sm-3 text-right">QR Code</dt>
              <dd className="col-sm-9">
                <div dangerouslySetInnerHTML={{ __html: machine.qr_code}}></div>
              </dd>

              <dt className="col-sm-3 text-right">Campaigns</dt>
              <dd className="col-sm-9">
                <div className="list-group">
                  {
                    machine.campaigns.length > 0 ? (
                      machine.campaigns.map(campaign => {
                        return (
                          <a className={`list-group-item list-group-item-action ${campaign.active || campaign.upcoming ? '' : 'opacity25'}`} href={campaign.url}>
                            {campaign.id} - {campaign.brand.name}
                          </a>
                        )
                      })
                    ) : (
                      "-"
                    )
                  }
                </div>
              </dd>

              <dt className="col-sm-3 text-right">QR Code (Download App)</dt>
              <dd className="col-sm-9">
                <div dangerouslySetInnerHTML={{ __html: machine.qr_code_download}}></div>
              </dd>

              <dt className="col-sm-3 text-right">QR Code (Replenishment)</dt>
              <dd className="col-sm-9">
                <div dangerouslySetInnerHTML={{ __html: machine.qr_code_associate}}></div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}
