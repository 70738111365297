import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloading: false,
      onDesktop: true,
    };

    this.downloadRawOrdersCsv = this.downloadRawOrdersCsv.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  downloadRawOrdersCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_raw_orders_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `raw-orders_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  render () {
    let { orders, showdrop_logo } = this.props;
    let { downloading, onDesktop } = this.state;
    let pageName = 'orders';

    const isNewOrder = (new_order) => {
      if (new_order) {
        return (
          <div className="bg-red bold color-white p5 w30 h30 br-100 center-text">
            <i className="fas fa-star"></i>
          </div>
        )
      } else {
        return null
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Orders'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="col pos-a">
                <span className="bold">{orders.length}</span> orders
              </div>

              <div className="col">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Raw Orders")}
                >
                  <button type="button" className="btn btn-sm btn-success mb-5px fs-17 w35 floatr" onClick={this.downloadRawOrdersCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <table className="table table-long table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Ordered At</th>
                  <th scope="col">Customer ID</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Customer Email</th>
                  <th scope="col">Customer Phone</th>
                  <th scope="col">Shopify Email</th>
                  <th scope="col">Shopify Phone</th>
                  <th scope="col">Shopify Customer Email</th>
                  <th scope="col">Shopify Customer Phone</th>
                  <th scope="col">Shopify Customer First Name</th>
                  <th scope="col">Shopify Customer Last Name</th>
                  <th scope="col">Shopify Quantity</th>
                  <th scope="col">Shopify Total Price</th>
                  <th scope="col">Shopify Discount Code</th>
                  <th scope="col">Shopify Billing City</th>
                  <th scope="col">Shopify Billing Zip</th>
                  <th scope="col">Shopify Billing Phone</th>
                  <th scope="col">Shopify Shipping City</th>
                  <th scope="col">Shopify Shipping Zip</th>
                  <th scope="col">Shopify Shipping Phone</th>
                  <th scope="col">Brand ID</th>
                  <th scope="col">Brand Name</th>
                  <th scope="col" className="w200"></th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => {
                  return (
                    <tr>
                      <td>{isNewOrder(order.new_order)}</td>
                      <th scope="row">{order.id}</th>
                      <td>{order.created_at}</td>
                      <td>{order.ordered_at}</td>
                      <td>{order.customer.id}</td>
                      <td>{order.customer.name}</td>
                      <td>{order.customer.email}</td>
                      <td>{order.customer.phone}</td>
                      <td>{order.shopify.email}</td>
                      <td>{order.shopify.phone}</td>
                      <td>{order.shopify.customer.email}</td>
                      <td>{order.shopify.customer.phone}</td>
                      <td>{order.shopify.customer.first_name}</td>
                      <td>{order.shopify.customer.last_name}</td>
                      <td>{order.shopify.quantity}</td>
                      <td>{order.shopify.total_price}</td>
                      <td>{order.shopify.discount_code}</td>
                      <td>{order.shopify.billing.city}</td>
                      <td>{order.shopify.billing.zip}</td>
                      <td>{order.shopify.billing.phone}</td>
                      <td>{order.shopify.shipping.city}</td>
                      <td>{order.shopify.shipping.zip}</td>
                      <td>{order.shopify.shipping.phone}</td>
                      <td>{order.brand.id}</td>
                      <td>{order.brand.name}</td>
                      <td>
                        <IndexButtons
                          id={order.id}
                          url_show={null}
                          url_edit={null}
                          url_delete={null}
                          hide_message={""}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
