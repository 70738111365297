import React from "react";
import ahoy from "ahoy.js";
import { Modal, Button, ModalBody } from "react-bootstrap";

export default class Download extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    }

    this.closeModal = this.closeModal.bind(this)
    this.handleClickApple = this.handleClickApple.bind(this)
    this.handleClickAndroid = this.handleClickAndroid.bind(this)
  }

  componentDidMount() {
    if (this.props.popup) {
      this.setState({
        showModal: true
      })
    }
  }

  closeModal() {
    let showModalState = !this.state.showModal;

    this.setState({
      showModal: showModalState
    })
  }

  handleClickApple(e) {
    ahoy.track("DOWNLOAD CLICK (APPLE)", {
      machine_uuid: this.props.uuid,
      campaign_ids: this.props.campaign_ids,
      engagement_type: "text",
    });

    window.open(this.props.download_apple_url, "_blank");
  }

  handleClickAndroid(e) {
    ahoy.track("DOWNLOAD CLICK (ANDROID)", {
      machine_uuid: this.props.uuid,
      campaign_ids: this.props.campaign_ids,
      engagement_type: "text",
    });

    window.open(this.props.download_android_url, "_blank");
  }

  render() {
    let { download_android_image, download_apple_image, showdrop_logo } = this.props;

    return (
      <div className="h800">
        <RedirectCustomerModal
          showModal={this.state.showModal}
          closeModal={this.closeModal}
        />

        <div className="container vh-85">
          <div className="row align-items-center justify-content-center">
            <div className="col-8 col-md-6 col-lg-4 center-text">
              <img className="img-fluid rounded" src={showdrop_logo} />
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <img className="img-fluid rounded hoverable mb-20" src={download_apple_image} onClick={this.handleClickApple}></img>
            </div>

            <div className="col-lg-6">
              <img className="img-fluid rounded hoverable mb-20" src={download_android_image} onClick={this.handleClickAndroid}></img>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function RedirectCustomerModal(props) {
  let { closeModal, showModal } = props;

  if (showModal) {
    return (
      <Modal show={showModal} onHide={closeModal} size="sm" centered>
        <Modal.Header closeButton>
          <Modal.Title className="bold w100p m-auto center-text fs-30">
            <i className="fas fa-hand-paper vertical-top" style={{ padding:"10px 5px",fontSize:"24px",transform:"rotate(-10deg) scaleX(-1)",color:"orange" }}></i>

            Hey There!
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h5 style={{ lineHeight:"1.5" }}>Please download the Showdrop app for free samples every week!</h5>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal} className="bg-showdrop border-showdrop">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  } else {
    return null;
  }
}
