import React from "react";
import ahoy from "ahoy.js";
import Select from "react-select";

export default class Text extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: "",
      phone: "",
      phoneSubmitted: false,
      selectedGender: "",
      selectedYear: "",
      showIntro: false,
      showThanks: false,
      submitUrl: "",
      success: false,
      survey: {},
      surveyResponse: "",
      surveyResponseOption: "",
      zipcode: "",
    };

    this.changeGender = this.changeGender.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.changeSurveyResponse = this.changeSurveyResponse.bind(this)
    this.changeSurveyResponseOption = this.changeSurveyResponseOption.bind(this)
    this.changeYear = this.changeYear.bind(this)
    this.changeZipCode = this.changeZipCode.bind(this)
    this.createRecord = this.createRecord.bind(this)
    this.updateRecord = this.updateRecord.bind(this)
  }

  changeGender(event) {
    let genderId = Number(event.value);

    this.setState({
      selectedGender: genderId,
    })
  }

  changePhone({ target: { value } }) {
    this.setState(prevState => ({
      phone: normalizeInput(value, prevState.phone)
    }));
  }

  changeSurveyResponse(event) {
    let targetValue = event.target.value
    let surveyState = this.state.survey;
    let surveyResponse = surveyState && surveyState.description == "zipcode" ? targetValue.replace(/[^\d]/g, '').slice(0, 5) : targetValue

    this.setState({
      surveyResponse: surveyResponse,
    })
  }

  changeSurveyResponseOption(event) {
    let surveyResponseOption = event.value == undefined ? event.target.value : event.value.toString();

    this.setState({
      surveyResponseOption: surveyResponseOption,
    })
  }

  changeYear(event) {
    this.setState({
      selectedYear: event.value,
    })
  }

  changeZipCode(event) {
    let zip = event.target.value.replace(/[^\d]/g, '').slice(0, 5)

    this.setState({
      zipcode: zip
    })
  }

  createRecord() {
    let that = this;

    this.setState({
      loading: true,
      phoneSubmitted: true,
      message: "",
    })

    const formData = new FormData();
    let data = JSON.stringify({
      phone: this.state.phone,
    })
    formData.append('customer', data)

    fetch(this.props.url_text, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          loading: false,
          message: data.errors,
          phoneSubmitted: false
        })
      } else {
        if (data.show_intro) {
          that.setState({
            loading: false,
            submitUrl: data.url,
            showIntro: true,
          })
        } else if (data.survey) {
          that.setState({
            loading: false,
            submitUrl: data.url,
            survey: data.survey,
          })
        } else {
          const formData = new FormData();
          let formParams = JSON.stringify({
            dob: that.state.selectedYear,
            gender: that.state.selectedGender,
            phone: data.phone,
            survey_description: that.state.survey.description,
            survey_id: that.state.survey.id,
            survey_response: that.state.surveyResponse,
            survey_response_option: that.state.surveyResponseOption,
            zip_code: that.state.zipcode,
          })
          formData.append('customer', formParams)

          fetch(data.url, {
            method: 'POST',
            headers: {},
            body: formData
          }).then(response => {
            return response.json();
          }).then((data) => {
            if (data.errors) {
              that.setState({
                loading: false,
                message: data.errors,
                phoneSubmitted: false,
              })
            } else {
              that.setState({
                loading: false,
                message: data.message,
                phone: '',
                selectedGender: '',
                selectedYear: '',
                showIntro: false,
                showThanks: false,
                success: true,
                survey: {},
                surveyResponse: '',
                surveyResponseOption: '',
                zipcode: '',
              })
            }
          })
        }
      }
    })
  }

  updateRecord() {
    let that = this;

    this.setState({
      loading: true,
    })

    const formData = new FormData();
    let data = JSON.stringify({
      dob: this.state.selectedYear,
      gender: this.state.selectedGender,
      phone: this.state.phone,
      survey_id: this.state.survey.id,
      survey_response: this.state.surveyResponse,
      survey_response_option: this.state.surveyResponseOption,
      zip_code: this.state.zipcode,
    })
    formData.append('customer', data)

    fetch(this.state.submitUrl, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          loading: false,
          message: data.errors,
        })
      } else {
        that.setState({
          loading: false,
          message: data.message,
          phone: '',
          selectedGender: '',
          selectedYear: '',
          showIntro: false,
          showThanks: true,
          success: true,
          survey: {},
          surveyResponse: '',
          surveyResponseOption: '',
          zipcode: '',
        })
      }
    })
  }

  render() {
    let {
      header_image,
      showdrop_logo,
      url_privacy,
      url_terms,
    } = this.props;
    let {
      loading,
      message,
      phone,
      phoneSubmitted,
      selectedGender,
      selectedYear,
      showIntro,
      showThanks,
      success,
      survey,
      surveyResponse,
      surveyResponseOption,
      zipcode,
    } = this.state;

    let footer = () => {
      return (
        <div className="fixed-bottom bg-dark-gray" style={{ textAlign:"center" }}>
          <img className="w250" src={showdrop_logo} />
        </div>
      )
    }

    let inputQuestion = () => {
      let surveyInputType = () => {
        if (survey.description == "email") {
          return (
            <input className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-4" onChange={this.changeSurveyResponse} value={surveyResponse} type="email" />
          )
        } else if (survey.description == "zipcode") {
          return (
            <input className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-4" onChange={this.changeSurveyResponse} value={surveyResponse} placeholder="XXXXX" pattern="[0-9]{1,5}" />
          )
        } else {
          return (
            <input className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-4" onChange={this.changeSurveyResponse} value={surveyResponse} />
          )
        }
      }

      let surveyButton = () => {
        if (survey.description == "email") {
          if (surveyResponse && surveyResponse.includes('@') && surveyResponse.includes('.')) {
            return buttonEnabled()
          } else {
            return buttonDisabled()
          }
        } else if (survey.description == "zipcode") {
          if (surveyResponse && surveyResponse.length == 5) {
            return buttonEnabled()
          } else {
            return buttonDisabled()
          }
        } else {
          if (surveyResponse) {
            return buttonEnabled()
          } else {
            return buttonDisabled()
          }
        }
      }

      let buttonEnabled = () => {
        return (
          <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" onClick={loading ? null : this.updateRecord} disabled={loading ? "disabled" : ""}>
            {
              loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                "Get my sample!"
              )
            }
          </button>
        )
      }

      let buttonDisabled = () => {
        return (
          <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
            {
              loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                "Get my sample!"
              )
            }
          </button>
        )
      }

      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-2rem bold">
                  1 quick question before you sample!
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">{survey.message}</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  {surveyInputType()}
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <div className="form-landing">
                  {surveyButton()}
                </div>
              </div>
            </div>

            <RenderSuccess
              message={message}
              success={success}
            />
          </div>

          {footer()}
        </div>
      )
    }

    let dropdownQuestion = () => {
      let styles = {
        control: (base, _) => ({
          ...base,
          background: "#c7c7c7",
          height: "46px"
        }),
        option: (styles, {isFocused, isSelected}) => ({
          ...styles,
          background: isFocused
              ? "#20cb9a50"
              : isSelected
                  ? "#20cb9a"
                  : undefined,
          zIndex: 1
        }),
        menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0
        }),
        menuList: base => ({
          ...base,
          padding: 0
        })
      };

      let options = () => {
        if (survey.description == "dob") {
          const currentYear = (new Date()).getFullYear();
          const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
          return range(currentYear, currentYear - 100, -1).map(year => {
            return { value: year, label: year }
          });
        } else if (survey.description == "gender") {
          return (
            [
              { value: 1, label: "Male" },
              { value: 2, label: "Female" },
              { value: 0, label: "Non-Binary" }
            ]
          )
        } else {
          return (
            survey.options.map(option => {
              return { value: option.value, label: option.label }
            })
          )
        }
      }

      let surveyButton = () => {
        if (surveyResponseOption) {
          return buttonEnabled()
        } else {
          return buttonDisabled()
        }
      }

      let buttonEnabled = () => {
        return (
          <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" onClick={loading ? null : this.updateRecord} disabled={loading ? "disabled" : ""}>
            {
              loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                "Get my sample!"
              )
            }
          </button>
        )
      }

      let buttonDisabled = () => {
        return (
          <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
            {
              loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                "Get my sample!"
              )
            }
          </button>
        )
      }

      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-2rem bold">
                  1 quick question before you sample!
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">{survey.message}</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <Select options={options()} onChange={this.changeSurveyResponseOption} styles={styles} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <div className="form-landing">
                  {surveyButton()}
                </div>
              </div>
            </div>

            <RenderSuccess
              message={message}
              success={success}
            />
          </div>

          {footer()}
        </div>
      )
    }

    let buttonQuestion = () => {
      let surveyButton = () => {
        if (surveyResponseOption) {
          return buttonEnabled()
        } else {
          return buttonDisabled()
        }
      }

      let buttonEnabled = () => {
        return (
          <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" onClick={loading ? null : this.updateRecord} disabled={loading ? "disabled" : ""}>
            {
              loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                "Get my sample!"
              )
            }
          </button>
        )
      }

      let buttonDisabled = () => {
        return (
          <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
            {
              loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                "Get my sample!"
              )
            }
          </button>
        )
      }

      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-2rem bold">
                  1 quick question before you sample!
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">{survey.message}</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing" style={{ display:"flex" }}>
                  {
                    survey.options.map(option => {
                      return (
                        <button className={`btn btn-sm btn-block color-white bold br-4 ${option.value == surveyResponseOption ? "" : "opacity60"}`} style={{ padding:"5px",margin:"10px 5px",fontSize:"16px",backgroundColor:`${option.value == surveyResponseOption ? "#20cb9a70" : "#a8a8a870"}`,border:`3px solid ${option.value == surveyResponseOption ? "#20cb9a" : "#a8a8a8"}` }} type="submit" value={option.value} onClick={this.changeSurveyResponseOption}>
                          {option.label}
                        </button>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <div className="form-landing">
                  {surveyButton()}
                </div>
              </div>
            </div>

            <RenderSuccess
              message={message}
              success={success}
            />
          </div>

          {footer()}
        </div>
      )
    }

    let intro = () => {
      let styles = {
        control: (base, _) => ({
          ...base,
          background: "#c7c7c7",
          height: "46px"
        }),
        option: (styles, {isFocused, isSelected}) => ({
          ...styles,
          background: isFocused
              ? "#20cb9a50"
              : isSelected
                  ? "#20cb9a"
                  : undefined,
          zIndex: 1
        }),
        menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0
        }),
        menuList: base => ({
          ...base,
          padding: 0
        })
      };
      let years = () => {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        return range(currentYear, currentYear - 100, -1).map(year => {
          return { value: year, label: year }
        });
      }
      let genders = () => {
        return (
          [
            { value: 1, label: "Male" },
            { value: 2, label: "Female" },
            { value: 0, label: "Non-Binary" }
          ]
        )
      }
      let introButton = () => {
        if ((!(selectedGender === "")) && selectedYear && (zipcode.length == 5)) {
          return buttonEnabled()
        } else {
          return buttonDisabled()
        }
      }
      let buttonEnabled = () => {
        return (
          <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" onClick={loading ? null : this.updateRecord} disabled={loading ? "disabled" : ""}>
            {
              loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                "Get my sample!"
              )
            }
          </button>
        )
      }
      let buttonDisabled = () => {
        return (
          <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
            {
              loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                "Get my sample!"
              )
            }
          </button>
        )
      }

      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-2rem bold">
                  3 quick questions before you sample!
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your birth year?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <Select options={years()} onChange={this.changeYear} styles={styles} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your gender?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <Select options={genders()} onChange={this.changeGender} styles={styles} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your US zipcode?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <input className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-4" placeholder="XXXXX" pattern="[0-9]{1,5}" onChange={this.changeZipCode} value={zipcode} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <div className="form-landing">
                  {introButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    let thanks = () => {
      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h1 className="color-primary fs-3rem bold lh-1">
                  Hello & Welcome
                </h1>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-20">
                <h3 className="color-primary fs-175rem lh-1">
                  You're on your way to sampling greatness
                </h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-20">
                <p className="color-white fs-125rem lh-1">
                  Check your phone for a PIN that you can input into the kiosk for your free sample!
                </p>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <p className="color-white fs-125rem lh-1">
                  You will get a new PIN each time you sample.
                </p>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <p className="color-white fs-125rem lh-1">
                  Reach out to info@showdrop.com if you have any issues.
                </p>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <a href="https://www.instagram.com/showdrop/" target="_blank" style={{ display:"flex",textDecoration:"underline",color:"white" }}>
                  <div>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg" style={{ width:"50px" }}></img>
                  </div>

                  <div style={{ paddingLeft:"10px",lineHeight:"1",fontSize:"16px" }}>
                    <p style={{ margin:"0",paddingTop:"7px" }}>Follow us on Instagram</p>
                    <p style={{ margin:"0" }}>@showdrop</p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {footer()}
        </div>
      )
    }

    let imageHeader = () => {
      if (header_image) {
        return (
          <div className="row align-items-center justify-content-center">
            <div className="col" style={{ padding:"0",backgroundColor:"white",textAlign:"center" }}>
              <img style={{ padding:"20px 30px",maxHeight:"150px" }} src={header_image}></img>
            </div>
          </div>
        )
      } else {
        return null;
      }
    }

    if (showIntro) {
      return intro()
    } else if (JSON.stringify(survey) !== '{}') {
      if (survey.presentation == "short_answer") {
        return inputQuestion()
      } else if (survey.presentation == "dropdown") {
        return dropdownQuestion()
      } else if (survey.presentation == "buttons") {
        return buttonQuestion()
      } else if (survey.presentation == "checkboxes") {
        // TODO: add this as a question type
        return null
      } else if (survey.presentation == "multiple_choice") {
        // TODO: add this as a question type
        return null
      } else if (survey.presentation == "paragraph") {
        // TODO: add this as a question type
        return null
      }
    } else if (showThanks) {
      return thanks()
    } else {
      return (
        <div className="h1000">
          <div className={`container pb-40 ${header_image ? "pt-50" : "pt-100"}`}>
            {imageHeader()}

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-15rem bold">
                  Enter your phone number below for PIN to unlock your free sample
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white pt-10 bold pdb-5" style={{ lineHeight:"1.4",fontSize:"13px" }}>
                  We ask for your phone number to regulate sampling but will not share your number with any third parties
                </h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <label className="sr-only" for="customer_phone">Phone Number</label>

                  <div className="d-flex">
                    <button className="btn btn-sm btn-block bg-semi-light-gray border-semi-light-gray color-dark-gray bold w40 br-l-4" onClick={null}>
                      +1
                    </button>

                    <input type="tel" className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-r-4 bold" placeholder="(XXX) XXX-XXXX" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={this.changePhone} value={phone} />

                    {
                      phone && phone.length == 14 ? (
                        <button className="btn btn-sm btn-block color-white bold w50" type="submit" onClick={phoneSubmitted || loading ? null : this.createRecord} disabled={phoneSubmitted || loading ? 'disabled' : ''} ref={(node) => {
                          if (node) {
                            node.style.setProperty("background-color", "#20cb9a", "important")
                          }
                        }}>
                          <i className="fas fa-angle-double-right fs-20"></i>
                        </button>
                      ) : (
                        <button className="btn btn-sm btn-block color-white bold w50" type="submit" disabled='disabled' ref={(node) => {
                          if (node) {
                            node.style.setProperty("background-color", "#a8a8a8", "important")
                          }
                        }}>
                          <i className="fas fa-angle-double-right fs-20"></i>
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            <RenderSuccess
              message={message}
              success={success}
            />

            <div className="row align-items-center justify-content-center pt-10">
              <div className="col-10 col-md-8 col-lg-6">
                <p className="center-text color-light-gray fs-10 italic">
                  <span className="pdr-2">By using Showdrop, you agree to our</span>
                  <a className="color-primary" href={url_terms} target="_blank">Terms of Service</a>
                  <span className="pdlr-2">and</span>
                  <a className="color-primary" href={url_privacy} target="_blank">Privacy Policy</a>.
                </p>
              </div>
            </div>

          </div>

          {footer()}
        </div>
      );
    }
  }
}

function normalizeInput(value, _) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

function RenderSuccess(props) {
  let { message, success } = props;

  const successMessage = () => {
    if (success) {
      return (
        <p className="bold fs-12 color-green">{message}</p>
      )
    } else {
      return (
        <p className="bold fs-12 color-red">{message}</p>
      )
    }
  }

  if (message) {
    return (
      <div className="row align-items-center justify-content-center center-text">
        <div className="col-10 col-md-6 col-lg-4 pt-10">
          {successMessage()}
        </div>
      </div>
    )
  } else {
    return null;
  }
}
