import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { debounce } from "lodash";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true,
      selectedCustomer: null,
      customerCampaignsOnly: true,
      customerCampaigns: null,
      selectedCampaign: null,
      selectedProduct: null,
      rebateQuantity: null,
      rebateAmount: null,
      createRebateEventInProgress: false,
      alerts: []
    };

    this.searchCustomers = debounce(this.searchCustomers, 500).bind(this)
    this.setSelectedCustomer = this.setSelectedCustomer.bind(this)
    this.toggleCustomerCampaignsOnly = this.toggleCustomerCampaignsOnly.bind(this)
    this.searchCampaigns = debounce(this.searchCampaigns, 500).bind(this)
    this.fetchCustomerCampaigns = this.fetchCustomerCampaigns.bind(this)
    this.setSelectedCampaign = this.setSelectedCampaign.bind(this)
    this.setSelectedProduct = this.setSelectedProduct.bind(this)
    this.setRebateQuantity = this.setRebateQuantity.bind(this)
    this.setRebateAmount = this.setRebateAmount.bind(this)
    this.createRebateEvent = this.createRebateEvent.bind(this)
    this.addAlert = this.addAlert.bind(this)
  }

  componentDidMount() {
    const onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  searchCustomers(query, callback) {
    const url = this.props.search_customers_url + '?' + new URLSearchParams({query})
    fetchJson(url, { method: 'GET', headers: {} },
      (msg) => this.addAlert('danger', `searchCustomers failed: ${msg}`),
      data => {
        const options = data.customers.map(customer => {
          const label = [`#${customer.id}`, customer.name, customer.email, customer.phone].filter(Boolean).join(' ')
          return { value: customer.id, label, customer }
        })
        callback(options)
      })
  }

  setSelectedCustomer(option) {
    this.setState({
      selectedCustomer: option,
      customerCampaigns: null,
      selectedCampaign: null,
      selectedProduct: null,
      rebateQuantity: null,
      rebateAmount: null
     })
    if (this.state.customerCampaignsOnly) {
      this.fetchCustomerCampaigns(option.customer.id)
    }
  }

  toggleCustomerCampaignsOnly() {
    this.setState(
      state => ({
          customerCampaignsOnly: !state.customerCampaignsOnly,
          customerCampaigns: null,
          selectedCampaign: null,
          selectedProduct: null,
          rebateQuantity: null,
          rebateAmount: null
      }),
      () => {
        if (this.state.customerCampaignsOnly && this.state.selectedCustomer) {
          this.fetchCustomerCampaigns(this.state.selectedCustomer.customer.id)
        }
      })
  }

  searchCampaigns(query, callback) {
    const url = this.props.search_campaigns_url + '?' + new URLSearchParams({query})
    fetchJson(url, { method: 'GET', headers: {} },
      (msg) => this.addAlert('danger', `searchCampaigns failed: ${msg}`),
      data => {
        const options = data.campaigns.map(campaignToOption)
        callback(options)
      })
  }

  fetchCustomerCampaigns(customer_id) {
    const url = this.props.customer_campaigns_url + '?' + new URLSearchParams({customer_id})
    fetchJson(url, { method: 'GET', headers: {} },
      (msg) => this.addAlert('danger', `fetchCustomerCampaigns failed: ${msg}`),
      data => {
        const options = data.campaigns.map(campaignToOption)
        this.setState({ customerCampaigns: options })
      })
  }

  setSelectedCampaign(option) {
    const selectedProduct = option.campaign.products && option.campaign.products.length === 1
      ? productToOption(option.campaign.products[0])
      : null;
    this.setState({
      selectedCampaign: option,
      selectedProduct,
    })
  }

  setSelectedProduct(option) {
    this.setState({ selectedProduct: option })
  }

  setRebateQuantity(event) {
    const input = event.target.value.trim()
    if (input === "") this.setState({ rebateQuantity: null })
    else this.setState({ rebateQuantity: input })
  }

  setRebateAmount(event) {
    const input = event.target.value.trim()
    if (input === "") this.setState({ rebateAmount: null })
    else this.setState({ rebateAmount: input })
  }

  createRebateEvent() {
    const { selectedCustomer, selectedCampaign, selectedProduct, rebateQuantity, rebateAmount, createRebateEventInProgress } = this.state

    if (!selectedCustomer || !selectedCampaign || !selectedProduct || createRebateEventInProgress) {
      this.addAlert('warning', `Customer, Campaign and Product must be selected`)
      return
    }

    const rebateQuantityInt = parseInt(rebateQuantity, 10)
    const rebateAmountFloat = parseFloat(rebateAmount)
    if (isNaN(rebateQuantityInt) || rebateQuantityInt < 0 || isNaN(rebateAmountFloat) || rebateAmountFloat < 0) {
      this.addAlert('warning', `Quantity or Amount are invalid`)
      return
    }

    this.setState({
      createRebateEventInProgress: true
    })

    const url = this.props.create_rebate_event_url
    const data = {
      customer_id: selectedCustomer.customer.id,
      campaign_id: selectedCampaign.campaign.id,
      product_id: selectedProduct.product.id,
      rebate_quantity: rebateQuantityInt,
      rebate_amount: rebateAmountFloat
    }
    fetchJson(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) },
      (msg) => {
        this.addAlert('danger', `createRebateEvent failed: ${msg}`)
        this.setState({ createRebateEventInProgress: false })
      },
      _ => {
        this.setState({
          selectedCustomer: null,
          customerCampaigns: null,
          selectedCampaign: null,
          selectedProduct: null,
          rebateQuantity: null,
          rebateAmount: null,
          createRebateEventInProgress: false,
          alerts: [{context: 'success', message: `Created RebateEvent for Customer #${selectedCustomer.customer.id}`}]
        })
      })
  }

  addAlert(context, message) {
    this.setState(state => ({ alerts: [{ context, message }, ...state.alerts] }))
  }

  render () {
    const { selectedCustomer, customerCampaignsOnly, customerCampaigns, selectedCampaign, selectedProduct, rebateQuantity, rebateAmount, createRebateEventInProgress, alerts } = this.state;

    const products = selectedCampaign?.campaign?.products
    const productOptions = products ? products.map(productToOption) : []
    const productSelectIsDisabled = selectedCampaign == null || (productOptions.length === 1 && productOptions[0].product.id === selectedProduct.product.id)

    const createIsDisabled = !selectedCustomer || !selectedCampaign || !selectedProduct || createRebateEventInProgress

    return (
    <AdminNavigation
      showdrop_logo={this.props.showdrop_logo}
      counts={this.props.counts}
      onDesktop={this.state.onDesktop}>
        <div className="form-group">
          <label htmlFor="customer-select">Customers</label>
          <AsyncSelect
            inputId="customer-select"
            value={selectedCustomer}
            loadOptions={this.searchCustomers}
            onChange={this.setSelectedCustomer}
          />
        </div>
        <div className="form-group">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="customer-campaigns-only" checked={customerCampaignsOnly} onChange={this.toggleCustomerCampaignsOnly} />
            <label className="form-check-label" htmlFor="customer-campaigns-only">Customer Campaigns Only</label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="campaign-select">Campaigns</label>
          {customerCampaignsOnly ? (
            <Select
              inputId="campaign-select"
              value={selectedCampaign}
              options={customerCampaigns}
              onChange={this.setSelectedCampaign}
              isDisabled={customerCampaigns === null}
            />) : (
            <AsyncSelect
              inputId="campaign-select"
              value={selectedCampaign}
              loadOptions={this.searchCampaigns}
              onChange={this.setSelectedCampaign}
            />)}
        </div>
        {selectedCampaign && <CampaignDetails campaign={selectedCampaign.campaign} />}
        <div className="form-group">
          <label htmlFor="product-select">Products</label>
          <Select
            inputId="product-select"
            value={selectedProduct}
            options={productOptions}
            onChange={this.setSelectedProduct}
            isDisabled={productSelectIsDisabled}
          />
        </div>
        <div className="form-group">
          <div className="form-row">
            <div className="col">
              <label htmlFor="quantity-input">Quantity</label>
              <input name="quantity-input" type="number" step="1" min="0" className="form-control" value={rebateQuantity === null ? '' : rebateQuantity} onChange={this.setRebateQuantity} disabled={!selectedProduct} />
            </div>
            <div className="col">
              <label htmlFor="amount-input">Amount</label>
              <input name="amount-input" type="number" step="0.01" min="0" className="form-control" value={rebateAmount === null ? '' : rebateAmount} onChange={this.setRebateAmount} disabled={!selectedProduct} />
            </div>
          </div>
        </div>
        <div className="form-group">
          <button type="button" className="btn btn-primary" onClick={this.createRebateEvent} disabled={createIsDisabled}>Create Rebate Event</button>
        </div>
        {alerts.map(alert => (<AlertSD key={alert.message} context={alert.context} message={alert.message} />))}
      </AdminNavigation>
      )
  }
}

function AdminNavigation({ showdrop_logo, counts, onDesktop, children }) {
  const pageName = "rebate_events"
  return (
    <div className="container-fluid bg-extra-gray fs-14">
      <AdminHeader
        logo={showdrop_logo}
        counts={counts}
        onDesktop={onDesktop}
        pageName={pageName}
      />

      <div className="row full-height">
        <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
          <Nav
            active={pageName}
            counts={counts}
          />
        </div>

        <div className="col bg-white">
          <IndexHeader
            name={'Rebate Events'}
            url={null}
          />
          {children}
        </div>
      </div>
    </div>
  )
}

function campaignToOption(campaign) {
    const machineName = campaign.machine?.name && `@ ${campaign.machine.name}`
    const productName = brandAndProductLabel(campaign)
    const label = [`#${campaign.id}`, productName, machineName].filter(Boolean).join(' ')
    return { value: campaign.id, label, campaign }
}

function brandAndProductLabel(campaign) {
  const productName = !campaign.products || campaign.products.length === 0  ? 'NO PRODUCT'
    : campaign.products.length === 1 ? campaign.products[0].name
    : 'MULTIPLE PRODUCTS'
  return [campaign.brand?.name, productName].filter(Boolean).join(' - ')
}

function productToOption(product) {
  const label = [`#${product.id}`, product.name].filter(Boolean).join(' ')
  return { value: product.id, label, product }
}

function AlertSD({ context, message }) {
  return (<div className={`alert alert-${context}`} role="alert" style={{maxHeight: "50px", overflowY: "scroll"}}>{message}</div>)
}

function CampaignDetails({ campaign }) {
  const startDate = formatDate(campaign.started_at)
  const endDate = formatDate(campaign.ended_at)
  return (
    <div className="form-group">
      <div className="form-row">
        <div className="col">Campaign Started at: {startDate}</div>
        <div className="col">Campaign Ended at: {endDate}</div>
      </div>
    </div>)
}

function formatDate(str) {
  const date = str && new Date(str)
  return date && `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
}

function fetchJson(resource, options, errorHandler, successHandler) {
  const defaultHeaders = { 'Accept': 'application/json', 'X-Requested-With': 'XMLHttpRequest' }
  const { headers, ...otherOptions } = options
  const allHeaders = { ...defaultHeaders, ...headers }
  const allOptions = { ...otherOptions, headers: allHeaders }
  fetch(resource, allOptions)
    .then(response => {
      if (response.ok) return response.json().then(data => successHandler(data))
      else return response.text().then(error => errorHandler(error))
    })
    .catch(error => errorHandler(error.message))
}
