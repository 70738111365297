import React from "react";
import Select from "react-select";

export default class ProductRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviews: this.props.product.written_reviews,
      selectedAge: "all",
      selectedGender: "all",
    };

    this.setSelectedAge = this.setSelectedAge.bind(this)
    this.setSelectedGender = this.setSelectedGender.bind(this)
  }

  setSelectedAge(option) {
    this.setState({
      selectedAge: option.value,
    })
  }

  setSelectedGender(option) {
    this.setState({
      selectedGender: option.value,
    })
  }

  render() {
    let { product } = this.props;
    let { reviews, selectedAge, selectedGender, selectedLocation } = this.state;

    let displayStars = (rating) => {
      if (rating == 5.0) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
          </>
        )
      } else if (rating >= 4.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
          </>
        )
      } else if (rating >= 4) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 3.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 3) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 2.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 2) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 1.5) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 1) {
        return (
          <>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else if (rating >= 0.5) {
        return (
          <>
            <i className="fas fa-star-half-alt"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      } else {
        return (
          <>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </>
        )
      }
    }
    let displayWrittenReviewCount = (count) => {
      if (count > 1) {
        return (
          <>({count} Comments)</>
        )
      } else if (count > 0) {
        return (
          <>({count} Comment)</>
        )
      } else {
        return null
      }
    }
    let displayRecentReviewCount = (count) => {
      if (count > 0) {
        return (
          <div style={{ width:"35px",paddingTop:"3px",textAlign:"center" }}>
            <div style={{ backgroundColor:"red",borderRadius:"100px",color:"white",fontWeight:"bold",height:"30px",width:"30px",paddingTop:"3px",marginLeft:"3px" }}>{count}</div>
            <div style={{ fontStyle:"italic",fontSize:"8px",color:"black",fontWeight:"normal",paddingTop:"4px" }}>Recent</div>
            <div style={{ fontStyle:"italic",fontSize:"8px",color:"black",fontWeight:"normal",position:"relative",top:"-2px" }}>Reviews</div>
          </div>
        )
      } else {
        return null
      }
    }
    let reviewRow = (review, i, totalReviews) => {
      return (
        <>
          <li style={{ margin:"5px 0" }}>
            <div style={{ color:"orange" }}>
              <span style={{ fontSize:"12px" }}>{displayStars(review.rating)}</span>
              <span style={{ color:"black",paddingLeft:"4px" }}>{review.message}</span>
            </div>

            <div>
              <strong> Customer {review.customer.id} </strong>
              {
                !review.customer.age && !review.customer.gender ? (
                  <></>
                ) : (
                  <span>[{review.customer.age ? review.customer.age + "/" : ""}{review.customer.gender}]</span>
                )
              }
              <span style={{ color:"#616161",fontSize:"12px" }}>({review.created_at})</span>
            </div>
          </li>

          {
            (i+1) == totalReviews ? (
              null
            ) : (
              <hr></hr>
            )
          }
        </>
      )
    }
    let displayWrittenReviews = (reviews) => {
      if (reviews.length > 0) {
        return (
          <div className="card-body" style={{ textAlign:"left",marginLeft:"10px" }}>
            <ul className="no-list m0">
              {reviews.map((review, i) => {
                return (
                  reviewRow(review, i, reviews.length)
                )
              })}
            </ul>
          </div>
        )
      } else {
        return null
      }
    }
    const displayBreakdown = (filteredReviews) => {
      let five_star_reviews = filteredReviews.filter(review => { return review.rating == 5 })
      let five_star_review_percentage = filteredReviews.length > 0 ? (five_star_reviews.length / filteredReviews.length) * 100 : 0
      let four_star_reviews = filteredReviews.filter(review => { return review.rating == 4 })
      let four_star_review_percentage = filteredReviews.length > 0 ? (four_star_reviews.length / filteredReviews.length) * 100 : 0
      let three_star_reviews = filteredReviews.filter(review => { return review.rating == 3 })
      let three_star_review_percentage = filteredReviews.length > 0 ? (three_star_reviews.length / filteredReviews.length) * 100 : 0
      let two_star_reviews = filteredReviews.filter(review => { return review.rating == 2 })
      let two_star_review_percentage = filteredReviews.length > 0 ? (two_star_reviews.length / filteredReviews.length) * 100 : 0
      let one_star_reviews = filteredReviews.filter(review => { return review.rating == 1 })
      let one_star_review_percentage = filteredReviews.length > 0 ? (one_star_reviews.length / filteredReviews.length) * 100 : 0

      return (
        <div style={{ color:"black" }}>
          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>5 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${five_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(five_star_review_percentage)}%</div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>4 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${four_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(four_star_review_percentage)}%</div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>3 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${three_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(three_star_review_percentage)}%</div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>2 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${two_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(two_star_review_percentage)}%</div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingRight:"5px",fontWeight:"bold" }}>1 star</div>
            <div style={{ width:"120px",height:"10px",border:"1px solid #c7c7c7",marginBottom:"5px" }}>
              <div style={{ width:`${one_star_review_percentage}%`,height:"8px",backgroundColor:"orange" }}></div>
            </div>
            <div style={{ lineHeight:"1.1",fontSize:"11px",paddingLeft:"5px",fontStyle:"italic" }}>{Math.round(one_star_review_percentage)}%</div>
          </div>
        </div>
      )
    }
    // Genders
    let allGendersOption = { value: "all", label: "All Genders" }
    let genderOptions = [
      { value: "1", label: "Male" },
      { value: "2", label: "Female" },
      { value: "0", label: "Non-Binary" },
      { value: "999", label: "N/A" },
    ]
    let genderSelectOptions = [allGendersOption, ...genderOptions]
    let selectedGenderOption = genderSelectOptions.find(o => o.value == selectedGender)

    // Ages
    let allAgesOption = { value: "all", label: "All Ages" }
    let ageOptions = [
      { value: "1", label: "18-25" },
      { value: "2", label: "26-35" },
      { value: "3", label: "36-45" },
      { value: "4", label: "46-55" },
      { value: "5", label: "56-65" },
      { value: "6", label: "65+" },
      { value: "999", label: "N/A" },
    ]
    let ageSelectOptions = [allAgesOption, ...ageOptions]
    let selectedAgeOption = ageSelectOptions.find(o => o.value == selectedAge)

    // Locations
    let allLocationsOption = { value: "all", label: "All Locations" }
    let locationOptions = product.machines.map(machine => {
      return { value: machine.id, label: machine.name }
    })
    let locationSelectOptions = [allLocationsOption, ...locationOptions]
    let selectedLocationOption = locationSelectOptions.find(o => o.value == selectedLocation)

    // Reviews
    const filteredReviews = filterReviews(reviews, selectedAge, selectedGender)
    const filteredWrittenReviews = filteredReviews.filter(review => { return review.message != null })
    let avgRating = (filteredReviews.reduce((total,next) => total + next.rating, 0) / filteredReviews.length).toFixed(1)

    return (
      <div style={{ position:"relative" }}>
        <div className="card" style={{ marginBottom:"20px",borderBottom:"1px solid #00000020" }}>
          <div className="card-header" id={`headingProduct${product.id}`} style={{ position:"relative",padding:"0" }}>
            <h2 className="mb-0">
              <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target={`#collapseProduct${product.id}`} aria-expanded="true" aria-controls={`collapseProduct${product.id}`}>
                <div className="d-flex">
                  <span className="f1" style={{ maxWidth:"50px",textAlign:"center" }}>
                    {displayRecentReviewCount(product.recent_reviews_amount)}
                  </span>

                  <span className="f1" style={{ maxWidth:"60px" }}>
                    {product.logo ? (
                      <img className="w50 h-auto" src={product.logo_url} />
                    ) : (
                      null
                    )}
                  </span>

                  <span className="f1">
                    <div className="fs-18">
                      {product.name}
                    </div>
                    <div>
                      <span style={{ color:"orange" }}>{displayStars(avgRating)}</span>
                      <span style={{ marginLeft:"4px" }}>{Number(avgRating) ? avgRating : "0.0"}</span>
                      <span style={{ marginLeft:"4px",color:"#616161",fontSize:"14px" }}>
                        <span>({filteredReviews.length} Ratings) </span>
                        <span style={{ color:"#20cb9a",fontWeight:"bold" }}>{displayWrittenReviewCount(filteredWrittenReviews.length)}</span>
                      </span>
                    </div>
                    <div style={{ color:"orange",paddingTop:"7px" }}>
                      {displayBreakdown(filteredReviews)}
                    </div>
                  </span>
                </div>

                <div style={{ fontSize:"14px" }}>
                  {displayWrittenReviews(filteredWrittenReviews.slice(0,5))}
                </div>
                {
                  filteredWrittenReviews.length > 5 ? (
                    <div style={{ position:"absolute",left:"0",right:"0",marginLeft:"0",marginRight:"0",width:"100%",textAlign:"center",bottom:"4px",fontSize:"10px",fontStyle:"italic",color:"#616161" }}>Click To See More Comments</div>
                  ) : (
                    <></>
                  )
                }
              </button>
            </h2>
          </div>

          <div id={`collapseProduct${product.id}`} className="collapse" aria-labelledby={`headingProduct${product.id}`}>
            {displayWrittenReviews(filteredWrittenReviews.slice(5,filteredWrittenReviews.length))}
          </div>
        </div>

        <div style={{ fontSize:"14px",width:"300px",position:"absolute",right:"0",top:"0",padding:"6px 10px" }}>
          <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
            {genderSelectOptions.length > 1 && (
              <Select
                inputId="gender-select"
                value={selectedGenderOption}
                onChange={this.setSelectedGender}
                options={genderSelectOptions}
                maxMenuHeight={150}
              />
            )}
          </div>

          <div className="form-group" style={{ marginBottom:"10px" }} onClick={e => e.stopPropagation()}>
            {ageSelectOptions.length > 1 && (
              <Select
                inputId="age-select"
                value={selectedAgeOption}
                onChange={this.setSelectedAge}
                options={ageSelectOptions}
                maxMenuHeight={150}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
function filterReviews(reviews, selectedAge, selectedGender) {
  let filteredReviews = reviews
  filteredReviews = filterReviewsByAge(filteredReviews, selectedAge)
  filteredReviews = filterReviewsByGender(filteredReviews, selectedGender)

  return filteredReviews
}
function filterReviewsByAge(reviews, selectedAge) {
  // add unknown
  if (selectedAge == "all") return reviews

  return reviews.filter(review => {
    return review.customer.age_filter == selectedAge
  })
}
function filterReviewsByGender(reviews, selectedGender) {
  // add unknown
  if (selectedGender == "all") return reviews

  return reviews.filter(review => {
    return review.customer.gender_filter == selectedGender
  })
}
