import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: this.props.survey_question.description,
      errors: '',
      message: this.props.survey_question.message,
      onDesktop: true,
      options: this.props.survey_question.options,
      selectedPresentation: this.props.survey_question.presentation,
    };

    this.changeDescription = this.changeDescription.bind(this)
    this.changeMessage = this.changeMessage.bind(this)
    this.changeOptions = this.changeOptions.bind(this)
    this.changePresentation = this.changePresentation.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeDescription(event) {
    this.setState({
      description: event.target.value
    })
  }

  changeMessage(event) {
    this.setState({
      message: event.target.value
    })
  }

  changeOptions(event) {
    this.setState({
      options: event.target.value
    })
  }

  changePresentation(event) {
    this.setState({
      selectedPresentation: event.target.value
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      description: this.state.description,
      message: this.state.message,
      options: this.state.options,
      presentation: this.state.selectedPresentation,
    })
    formData.append('survey_question', data)

    fetch(this.props.survey_question.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { presentations, survey_question, showdrop_logo, url_new } = this.props;
    let {
      description,
      errors,
      message,
      options,
      onDesktop,
      selectedPresentation,
    } = this.state;
    let pageName = 'survey_questions';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={`Edit Survey Question #${survey_question.id}`}
              url_show={survey_question.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">
                Message
                <div className="fs-10 thin">Whats the question you want to ask?</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Message" onChange={this.changeMessage} value={message} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Options
                <div className="fs-10 thin">(Ex. "Yes,No" OR "True,False" OR "No I dont,Yes I do")</div>
                <div className="fs-10 thin">Comma seperated values that will be displayed to the user</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Options" onChange={this.changeOptions} value={options} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Description
                <div className="fs-10 thin">1 word unique keyword that describes the question</div>
                <div className="fs-10 thin">(Ex. 'gender', 'dob', 'zipcode', etc)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Description" onChange={this.changeDescription} value={description} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Presentation
                <div className="fs-10 thin">How will the customer answer?</div>
              </dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedPresentation} onChange={this.changePresentation}>
                  <option value="" disabled selected>Select a Presentation</option>

                  {presentations.map(presentation => {
                    return (
                      <option key={presentation.value} value={presentation.value}>{presentation.value}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Survey Question</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
