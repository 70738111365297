import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idDirection: '',
      nameDirection: '',
      onDesktop: true,
      sortedBrands: this.props.brands,
    }

    this.sortById = this.sortById.bind(this)
    this.sortByName = this.sortByName.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  sortById() {
    let brands = this.state.sortedBrands
    let direction = '';

    if (this.state.idDirection == "DESC") {
      brands = brands.sort((a, b) => a.id > b.id ? -1 : 1);
      direction = "ASC"
    } else {
      brands = brands.sort((a, b) => a.id > b.id ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      nameDirection: '',
      idDirection: direction,
      sortedBrands: brands,
    })
  }

  sortByName() {
    let brands = this.state.sortedBrands
    let direction = '';

    if (this.state.nameDirection == "DESC") {
      brands = brands.sort((a, b) => a.name > b.name ? -1 : 1);
      direction = "ASC"
    } else {
      brands = brands.sort((a, b) => a.name > b.name ? 1 : -1);
      direction = "DESC"
    }

    this.setState({
      nameDirection: direction,
      idDirection: '',
      sortedBrands: brands,
    })
  }

  render () {
    let { showdrop_logo, url_new } = this.props;
    let { idDirection, nameDirection, onDesktop, sortedBrands } = this.state;
    let pageName = 'brands';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Brands'}
              url={url_new}
            />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" className="sortable" onClick={this.sortById}>
                    ID
                    <RenderArrow direction={idDirection} />
                  </th>
                  <th scope="col" className="sortable" onClick={this.sortByName}>
                    Name
                    <RenderArrow direction={nameDirection} />
                  </th>
                  <th scope="col">Shopify Linked?</th>
                  <th scope="col">Active Campaigns</th>
                  <th scope="col" className="w150"></th>
                </tr>
              </thead>
              <tbody>
                {sortedBrands.map(brand => {
                  return (
                    <tr className={`clickable ${brand.campaigns > 0 ? "bold" : "color-medium-gray"}`} onClick={(e) => {e.stopPropagation(); this.goToUrl(brand.url)}}>
                      <th scope="row">{brand.id}</th>
                      <td>{brand.name}</td>
                      <td>{brand.shopify ? 'Yes' : 'No'}</td>
                      <td>{brand.campaigns > 0 ? brand.campaigns : '-'}</td>
                      <td>
                        <IndexButtons
                          id={brand.id}
                          url_show={null}
                          url_edit={null}
                          url_delete={null}
                          hide_message={"Are you sure? Removing a Brand will also remove Campaigns, Products & Orders associated with this Brand."}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function RenderArrow(props) {
  let { direction } = props;

  if (direction) {
    if (direction == "DESC") {
      return (
        <i className="fas fa-caret-down"></i>
      )
    } else {
      return (
        <i className="fas fa-caret-up"></i>
      )
    }
  } else {
    return null;
  }
}
