import React from "react"
import { Modal } from "react-bootstrap";

export default class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    }

    this.handleClose = this.handleClose.bind(this)
    this.finishReplenishment = this.finishReplenishment.bind(this)
    this.showModal = this.showModal.bind(this)
  }

  handleClose() {
    let showState = !this.state.show;

    this.setState({
      show: showState
    })
  }

  finishReplenishment(url) {
    const formData = new FormData();
    let data = JSON.stringify({})
    formData.append('coil', data)

    fetch(url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  showModal() {
    this.setState({
      show: true
    })
  }

  render () {
    let { product } = this.props;
    let { show } = this.state;

    let replenishmentComplete = (product) => {
      return (
        <li style={{ border:"2px solid #00000020",borderRadius:"4px",marginBottom:"20px",position:"relative" }}>
          <i className="fas fa-check-circle" style={{ position:"absolute",right:"0",top:"0",fontSize:"25px",padding:"10px",color:"#20cb9a" }}></i>

          <div style={{ position:"absolute",width:"100%",height:"100%",top:"0",bottom:"0",left:"0",right:"0",backgroundColor:"#20cb9a50",zIndex:"10" }} onClick={product.new_product_in_coils ? this.showModal : () => window.location.assign(product.coil_url)}></div>

          <a style={{ textDecoration:"none",color:"#00000050" }}>
            <div style={{ display:"flex" }}>
              <div style={{ padding:"10px" }}>
                <img src={product.image} style={{ width:"100px",filter:"grayscale(1)" }}></img>
              </div>

              <div style={{ borderLeft:"2px solid #00000020",padding:"10px",width:"100%" }}>
                <div style={{ fontSize:"18px",fontWeight:"bold" }}>{product.brand.name}</div>
                <div style={{ fontSize:"12px" }}>{product.name}</div>
                <div style={{ fontSize:"12px" }}>{product.current_quantity}/{product.total_quantity}</div>
              </div>
            </div>

            <div style={{ backgroundColor:"#20cb9a",color:"white",borderRadius:"4px",padding:"10px 20px",fontWeight:"bold",textAlign:"center" }}>
              Completed!
              <div style={{ fontSize:"10px" }}>(Product is now fully stocked)</div>
            </div>
          </a>
        </li>
      )
    }
    let replenishmentNotNeeded = (product) => {
      return (
        <li style={{ border:"2px solid #00000020",borderRadius:"4px",marginBottom:"20px",position:"relative" }}>
          <i className="fas fa-times-circle" style={{ position:"absolute",right:"0",top:"0",fontSize:"25px",padding:"10px",color:"#a8a8a8" }}></i>

          <div style={{ position:"absolute",width:"100%",height:"100%",top:"0",bottom:"0",left:"0",right:"0",backgroundColor:"#a8a8a850",zIndex:"10" }}></div>

          <div style={{ color:"#00000050" }}>
            <div style={{ display:"flex" }}>
              <div style={{ padding:"10px" }}>
                <img src={product.image} style={{ width:"100px",filter:"grayscale(1)" }}></img>
              </div>

              <div style={{ borderLeft:"2px solid #00000020",padding:"10px",width:"100%" }}>
                <div style={{ fontSize:"18px",fontWeight:"bold" }}>{product.brand.name}</div>
                <div style={{ fontSize:"12px" }}>{product.name}</div>
                <div style={{ fontSize:"12px" }}>{product.current_quantity}/{product.total_quantity}</div>
              </div>
            </div>

            <div style={{ backgroundColor:"#a8a8a8",color:"#f0f0f0",borderRadius:"4px",padding:"10px 20px",fontWeight:"bold",textAlign:"center" }}>
              Inventory Full
              <div style={{ fontSize:"10px" }}>(Restocking is not needed at this time)</div>
            </div>
          </div>
        </li>
      )
    }
    let replenishmentIncomplete = (product) => {
      return (
        <li style={{ border:"2px solid #00000020",borderRadius:"4px",marginBottom:"20px",position:"relative" }}>
          <i className="fas fa-exclamation-circle" style={{ position:"absolute",right:"0",top:"0",fontSize:"25px",padding:"10px",color:"#ef5c63" }}></i>

          <div style={{ textDecoration:"none",color:"black" }}>
            <div style={{ display:"flex" }}>
              <div style={{ padding:"10px" }}>
                <img src={product.image} style={{ width:"100px" }}></img>
              </div>

              <div style={{ borderLeft:"2px solid #00000020",padding:"10px",width:"100%",position:"relative" }}>
                <div style={{ fontSize:"18px",fontWeight:"bold" }}>{product.brand.name}</div>
                <div style={{ fontSize:"12px" }}>{product.name}</div>
                <div style={{ fontSize:"12px",position:"absolute",bottom:"0",left:"0",width:"100%",padding:"5px",textAlign:"center",backgroundColor:"#f0f0f0" }}>
                  {
                    product.total_quantity - product.current_quantity >= 100 ? (
                      <span style={{ fontWeight:"bold",fontSize:"15px",backgroundColor:"#ef5c63",width:"30px",height:"30px",borderRadius:"100px",color:"white",textAlign:"center",marginRight:"4px",display:"inline-block",padding:"4px 0" }}>{product.total_quantity - product.current_quantity}</span>
                    ) : (
                      <span style={{ fontWeight:"bold",fontSize:"18px",backgroundColor:"#ef5c63",width:"30px",height:"30px",borderRadius:"100px",color:"white",textAlign:"center",marginRight:"4px",display:"inline-block",padding:"2px 0" }}>{product.total_quantity - product.current_quantity}</span>
                    )
                  }
                  <span>Products Needed</span>
                </div>
              </div>
            </div>

            <div style={{ display:"flex" }}>
              <div style={{ backgroundColor:"#ef5c63",flex:"0.9",color:"#f0f0f0",borderRadius:"4px",padding:"10px 20px",fontWeight:"bold",textAlign:"center" }} onClick={product.new_product_in_coils ? this.showModal : () => window.location.assign(product.coil_url)}>
                Incomplete
                <div style={{ fontSize:"10px" }}>(Click to continue replenishing inventory)</div>
              </div>

              <div style={{ backgroundColor:"#20cb9a",flex:"0.1",fontSize:"14px",lineHeight:"2.5",color:"white",borderRadius:"4px",padding:"10px",fontWeight:"bold",textAlign:"center" }} onClick={() => this.finishReplenishment(product.complete_replenishment_url)}>
                Complete
              </div>
            </div>
          </div>
        </li>
      )
    }
    let replenishmentNeeded = (product) => {
      return (
        <li style={{ border:"2px solid #00000020",borderRadius:"4px",marginBottom:"20px" }}>
          <div style={{ textDecoration:"none",color:"black" }}>
            <div style={{ display:"flex" }}>
              <div style={{ padding:"10px" }}>
                <img src={product.image} style={{ width:"100px" }}></img>
              </div>

              <div style={{ borderLeft:"2px solid #00000020",padding:"10px",width:"100%",position:"relative" }}>
                <div style={{ fontSize:"18px",fontWeight:"bold" }}>{product.brand.name}</div>
                <div style={{ fontSize:"12px" }}>{product.name}</div>
                <div style={{ fontSize:"12px",position:"absolute",bottom:"0",left:"0",width:"100%",padding:"5px",textAlign:"center",backgroundColor:"#f0f0f0" }}>
                  {
                    product.total_quantity - product.current_quantity >= 100 ? (
                      <span style={{ fontWeight:"bold",fontSize:"15px",backgroundColor:"#FFA500",width:"30px",height:"30px",borderRadius:"100px",color:"white",textAlign:"center",marginRight:"4px",display:"inline-block",padding:"4px 0" }}>{product.total_quantity - product.current_quantity}</span>
                    ) : (
                      <span style={{ fontWeight:"bold",fontSize:"18px",backgroundColor:"#FFA500",width:"30px",height:"30px",borderRadius:"100px",color:"white",textAlign:"center",marginRight:"4px",display:"inline-block",padding:"2px 0" }}>{product.total_quantity - product.current_quantity}</span>
                    )
                  }
                  <span>Products Needed</span>
                </div>
              </div>
            </div>

            <div style={{ display:"flex" }}>
              <div style={{ backgroundColor:"#FFA500",flex:"0.9",color:"white",borderRadius:"4px",padding:"10px 20px",fontWeight:"bold",textAlign:"center" }} onClick={product.new_product_in_coils ? this.showModal : () => window.location.assign(product.coil_url)}>
                Restock Now
                <div style={{ fontSize:"10px" }}>(Click to start replenishing inventory)</div>
              </div>

              <div style={{ backgroundColor:"#20cb9a",flex:"0.1",fontSize:"14px",lineHeight:"2.5",color:"white",borderRadius:"4px",padding:"10px",fontWeight:"bold",textAlign:"center" }} onClick={() => this.finishReplenishment(product.complete_replenishment_url)}>
                Complete
              </div>
            </div>
          </div>
        </li>
      )
    }
    let checkProductState = (product) => {
      if (product.replenish_complete) {
        return replenishmentComplete(product)
      } else if (!product.needs_restocking) {
        return replenishmentNotNeeded(product)
      } else if (product.replenish_incomplete) {
        return replenishmentIncomplete(product)
      } else {
        return replenishmentNeeded(product)
      }
    }

    return (
      <>
        {checkProductState(product)}

        <CheckInventoryModal
          url={product.coil_url}
          show={show}
          img={product.replenishment_image}
          handleClose={this.handleClose}
          newProduct={product.new_product_in_coils}
        />
      </>
    )
  }
}

function CheckInventoryModal(props) {
  let { handleClose, img, newProduct, show, url } = props;

  if (show && newProduct) {
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div style={{ textAlign:"center" }}>
            <img src={`${img}`} style={{ width:"50%" }}></img>
          </div>

          <div style={{ padding:"20px 0",fontSize:"18px",textAlign:"center" }}>
            Don't forget that you'll have to remove any remaining product in the coil before filling with the new product!
          </div>

          <button style={{ width:"100%",padding:"10px",borderRadius:"4px",border:"none",backgroundColor:"#20cb9a",fontSize:"18px",fontWeight:"bold",color:"white" }} onClick={() => window.location.assign(url)}>Got It!</button>
        </Modal.Body>
      </Modal>
    )
  } else {
    return null;
  }
}
