import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: this.props.customers,
      downloading: false,
      onDesktop: true,
      phone: "",
      searching: false,
    }

    this.changePhone = this.changePhone.bind(this)
    this.downloadCsv = this.downloadCsv.bind(this)
    this.searchPhone = this.searchPhone.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changePhone({ target: { value } }) {
    this.setState(prevState => ({
      phone: normalizeInput(value, prevState.phone)
    }));
  }

  downloadCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `customers_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  searchPhone() {
    this.setState({
      searching: true
    })

    let that = this;
    let data = JSON.stringify({
      phone: this.state.phone,
    })

    fetch(this.props.url_search_by_phone, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          searching: false,
          customers: data.customers
        })
      }
    })
  }

  render () {
    let { showdrop_logo, survey_questions } = this.props;
    let { customers, downloading, onDesktop, phone, searching } = this.state;
    let pageName = 'customers';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h800 o-auto mobile-block">
            <IndexHeader
              name={'Customers'}
              url={null}
            />

            <div className="d-flex" style={{ padding:"20px 0" }}>
              <div className="f1">
                <div className="d-flex" style={{ padding:"0 10px" }}>
                  <input type="tel" className="form-control br-r-4 f1" placeholder="Enter Phone.." onChange={this.changePhone} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" value={phone} disabled={searching ? 'disabled' : ''} />

                  <button className="btn btn-primary btn-sm bold f1" style={{ maxWidth:"100px",marginLeft:"10px" }} type="submit" onClick={searching ? null : this.searchPhone} disabled={searching ? 'disabled' : ''}>
                    {
                      searching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        "Search"
                      )
                    }
                  </button>

                  <div className="f1" style={{ maxWidth:"50px",marginLeft:"10px" }}>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 100, hide: 100 }}
                      overlay={renderTooltip("Download Customers")}
                    >
                      <button type="button" className="btn btn-sm btn-success mb-5px fs-17 w35 floatr" onClick={this.downloadCsv} disabled={downloading ? "disabled" : ''}>
                        {
                          downloading ? (
                            <i className="fas fa-circle-notch fa-spin"></i>
                          ) : (
                            <i className="fas fa-download"></i>
                          )
                        }
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>

                <div style={{ padding:"3px 10px",fontSize:"10px",fontStyle:"italic" }}>
                  Search by a specific number
                </div>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Birth Year</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Zip Code</th>
                  <th scope="col">Last Engagement</th>
                  <th scope="col">Last Machine</th>
                  {
                    survey_questions.map(sq => {
                      return (
                        <th scope="col">SRVY: {sq.description}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {customers.map(customer => {
                  return (
                    <tr>
                      <td>{customer.id}</td>
                      <td>
                        <div style={{ display:"flex" }}>
                          <div style={{ flex:"1" }}>
                            {
                              customer.email_verified ? (
                                <i className="fas fa-check-circle color-primary pdr-5 fs-20"></i>
                              ) : (
                                <i className="far fa-check-circle color-extra-light-gray pdr-5 fs-20"></i>
                              )
                            }
                          </div>

                          <div style={{ flex:"1" }}>
                            {
                              customer.share_email ? (
                                <i className="fas fa-envelope color-primary pdr-5 fs-20"></i>
                              ) : (
                                <i className="far fa-envelope color-extra-light-gray pdr-5 fs-20"></i>
                              )
                            }
                          </div>

                          <div style={{ flex:"1" }}>
                            {
                              customer.twilio_opt_out ? (
                                <i className="fas fa-sms color-extra-light-gray pdr-5 fs-20"></i>
                              ) : (
                                <i className="fas fa-sms color-primary pdr-5 fs-20"></i>
                              )
                            }
                          </div>

                          <div style={{ flex:"1" }}>
                            {
                              customer.mobile_user ? (
                                <i className="fas fa-mobile color-primary pdr-5 fs-20"></i>
                              ) : (
                                <i className="fas fa-mobile-alt color-extra-light-gray pdr-5 fs-20"></i>
                              )
                            }
                          </div>
                        </div>
                      </td>
                      <td>{customer.name || '-'}</td>
                      <td>{customer.email || '-'}</td>
                      <td>{customer.phone || '-'}</td>
                      <td>{customer.birth_year || '-'}</td>
                      <td>{customer.gender || '-'}</td>
                      <td>{customer.zip_code || '-'}</td>
                      <td>{customer.last_engagament_date || '-'}</td>
                      <td>{customer.last_machine || '-'}</td>
                      {
                        survey_questions.map(sq => {
                          let answer = customer.survey_answers.find(sa => sa.id == sq.id)

                          return (
                            <td>{answer ? answer.response : "-"}</td>
                          )
                        })
                      }
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};

function normalizeInput(value, _) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength <= 11) return currentValue;
  return currentValue.slice(0,11);
};
