import React from "react";
import ahoy from "ahoy.js";

export default class ShowInPerson extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    // This restricts a user from clicking "back"
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', function() {
      window.history.go(1);
    });
  }

  render() {
    let { campaigns, customer, machine, showdrop_logo } = this.props;

    return (
      <div className="h800">
        <DetermineDisplay
          date={customer.engagement.date}
          returning_visitor={customer.returning_visitor}
          time={customer.engagement.time}
        />

        <div className="align-items-center justify-content-center bg-dark-gray d-flex pt-10">
          <div className="col-10 col-md-6 col-lg-4 center-text fs-18 color-white">
            <div className="fs-125rem bold">Ready to shop?</div>
          </div>
        </div>

        {campaigns.map(campaign => {
          return (
            <div className="align-items-center justify-content-center bg-dark-gray d-flex pt-20 pb-20 mb-10">
              <div className="col-10 col-md-6 col-lg-4 center-text fs-18 color-white">
                <img src={campaign.brand_logo} className="h40 mb-10" />

                <BrandActionButton
                  campaign={campaign}
                  customer={customer}
                  machine={machine}
                />

                <ShowDiscountCode
                  discount={campaign.discount}
                  discountCode={campaign.discount_code}
                  hasRebates={campaign.has_rebates}
                />
              </div>
            </div>
          )
        })}

        <AdminSignOutButton
          admin={customer.admin}
          uuid={machine.uuid}
        />

        <div className="fixed-bottom bg-black pb-10">
          <div className="bb-primary bt-primary h10"></div>
          <img className="w120 floatr" src={showdrop_logo}></img>
        </div>
      </div>
    );
  }
}

function DetermineDisplay(props) {
  let { date, returning_visitor, time } = props;

  if (returning_visitor) {
    return (
      <div className="m20 mt-150 ptb100-lr20 br-10 color-white center-text bg-soft-red">
        <h2 className="bold fs-3rem">Oops!</h2>

        <p className="pdlr-20 fs-18">You have already made a request for a sample from this brand - try again when we feature a new brand!</p>
      </div>
    )
  } else {
    return (
      <div className="m20 mt-150 ptb100-lr20 br-10 color-white center-text bg-showdrop">
        <h2 className="bold fs-70">{date}</h2>

        <h4 className="bold fs-40">{time}</h4>

        <p className="pdlr-20 fs-18">Show your screen to the cashier to receive your free sample</p>
      </div>
    )
  }
}

function BrandActionButton(props) {
  let { campaign, customer, machine } = props;

  if (campaign.has_rebates) {
    return (
      <RebateButton
        campaign={campaign}
        customerId={customer.id}
        uuid={machine.uuid}
      />
    )
  } else {
    return (
      <ShopButton
        campaign={campaign}
        customerId={customer.id}
        uuid={machine.uuid}
      />
    )
  }
}

function RebateButton(props) {
  let campaignId = props.campaign.id;
  let rebateLink = props.campaign.rebate_link;
  let domain = props.campaign.domain;
  let buttonText = props.campaign.text_on_rebate_button;

  const handleClick = () => {
    ahoy.track("REBATE CLICK (APP)", {
      domain: domain,
      campaign_ids: campaignId,
      customer_id: props.customerId,
      machine_uuid: props.uuid
    });

    window.open(rebateLink, "_blank");
  };

  return (
    <button
      className="btn btn-lg btn-block bg-dark-gray bold w300 m-auto"
      onClick={handleClick}
      ref={(node) => {
        if (node) {
          node.style.setProperty("border-color", "#20cb9a", "important");
          node.style.setProperty("color", "#20cb9a", "important");
        }
      }}
    >
      {buttonText}
    </button>
  );
}

function ShopButton(props) {
  let campaignId = props.campaign.id;
  let discountLink = props.campaign.discount_link;
  let discount = props.campaign.discount;
  let domain = props.campaign.domain;

  const handleClick = () => {
    ahoy.track("SHOP CLICK (APP)", {
      domain: domain,
      campaign_ids: campaignId,
      customer_id: props.customerId,
      machine_uuid: props.uuid
    });

    window.open(discountLink, "_blank");
  };

  const buttonText = () => {
    if (props.campaign.discount_disclaimer) {
      return (
        <>SHOP {discount ? discount : "NOW"}*</>
      )
    } else {
      return (
        <>SHOP {discount ? discount : "NOW"}</>
      )
    }
  }

  return (
    <>
      <button
        className="btn btn-lg btn-block bg-dark-gray bold w300 m-auto"
        onClick={handleClick}
        ref={(node) => {
          if (node) {
            node.style.setProperty("border-color", "#20cb9a", "important");
            node.style.setProperty("color", "#20cb9a", "important");
          }
        }}
      >
        {buttonText()}
      </button>

      <DisclaimerCheck disclaimer={props.campaign.discount_disclaimer}/>
    </>
  );
}

function DisclaimerCheck(props) {
  let { disclaimer } = props;

  if (disclaimer) {
    return (
      <p className="fs-12 p10 m0 italic">*{disclaimer}</p>
    )
  } else {
    return null;
  }
}

function AdminSignOutButton(props) {
  let { uuid, admin } = props;

  if (admin) {
    return (
      <div className="row-clean align-items-center justify-content-center center-text mt-40">
        <div className="col-10 col-md-6 col-lg-4">
          <a
          className="fs-20 color-white bg-red bold p10"
            href={
              "/machines/" + uuid + "/start?sign_out=true"
            }
          >
            ADMIN SIGNOUT
          </a>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

function ShowDiscountCode(props) {
  let { discount, discountCode, hasRebates } = props;

  if (discount && !hasRebates) {
    return (
      <p className="pt-10 italic color-light-gray fs-14">
        Discount code:

        <span className="color-white pdl-5">{discountCode}</span>
      </p>
    )
  } else {
    return null;
  }
}
