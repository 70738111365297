import React from "react";
import CampaignRow from "./CampaignRow";
import ProductRow from "./ProductRow";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Bar } from "react-chartjs-2";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { brand, campaigns, showdrop_logo } = this.props;

    let orderWord = () => {
      if (brand.order_count == 1) {
        return "Order"
      } else {
        return "Orders"
      }
    }
    let displayLogo = () => {
      if (brand.logo) {
        return (
          <img className="w300 h-auto pdtb-20" src={brand.logo}></img>
        )
      } else {
        return (
          <h1 className="pdtb-20">{brand.name}</h1>
        )
      }
    }
    let rebateSignUpCount = () => {
      return SumArray(campaigns.map(campaign => campaign.rebate_sign_ups))
    }
    let rebateRedemptionsCount = () => {
      return SumArray(campaigns.map(campaign => campaign.rebate_redemptions))
    }
    let showRebateSignUpHeader = () => {
      if (rebateSignUpCount() > 0) {
        return (
          <>
            <th className="bg-extra-gray">Rebate Sign Ups</th>
            {
              rebateRedemptionsCount() > 0 ? (
                <th className="bg-extra-gray">Rebate Redemptions</th>
              ) : (
                null
              )
            }
          </>
        )
      } else {
        return null;
      }
    }
    let showClickThroughHeader = () => {
      if (clickThroughCount() > 0) {
        return (
          <th className="bg-extra-gray">Customer Clicks</th>
        )
      } else {
        return null;
      }
    }
    let showRebateCountForAll = () => {
      if (rebateSignUpCount() > 0) {
        return (
          <>
            <td>{numberWithCommas(rebateSignUpCount())}</td>
            {
              rebateRedemptionsCount() > 0 ? (
                <td>{numberWithCommas(rebateRedemptionsCount())}</td>
              ) : (
                null
              )
            }
          </>
        )
      } else {
        return null;
      }
    }
    let clickThroughCount = () => {
      return SumArray(campaigns.map(campaign => campaign.click_throughs))
    }
    let showClicksForAll = () => {
      if (clickThroughCount() > 0) {
        return (
          <td>{numberWithCommas(clickThroughCount())}</td>
        )
      } else {
        return null
      }
    }
    let showRebateLegend = () => {
      if (rebateSignUpCount() > 0) {
        return (
          <tr>
            <td className="bold bg-extra-gray">Rebate Sign Ups</td>
            <td># of rebate sign ups</td>
          </tr>
        )
      } else {
        return null;
      }
    }
    const displayShopifyOrders = (brand) => {
      if (brand.order_count > 0) {
        return (
          <div className="row ptb20-lr10 bg-extra-gray">
            <div className="col col-lg-4">
              <div className="table-responsive w100p m-auto bg-white">
                <table className="table table-sm center-text table-black-border mb-0">
                  <thead>
                    <tr>
                      <th className="bg-extra-gray">Directly Attributable Shopify Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{numberWithCommas(brand.order_count)} {orderWord()}</td>
                    </tr>

                    <tr>
                      <td>{brand.order_amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      }
    }
    let demographicBarGraph = (graph_data) => {
      if (JSON.stringify(graph_data) === '{}') {
        return (
          <></>
        )
      } else {
        const data = {
          labels: graph_data.labels,
          datasets: graph_data.datasets,
        };
        const options = {
          barPercentage: 1,
          categoryPercentage: 1,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
              max: 100
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              position: "bottom",
            },
            tooltip: {
              mode: 'index',
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || '';
                  if (context.parsed.x !== null) {
                    label += ' ' +context.parsed.x + '%';
                  }
                  return label;
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
        };

        return (
          <Bar data={data} options={options} height={"160px"} />
        );
      }
    };

    return (
      <div className="container-fluid bg-white fs-14 admin full-height">
        <div className="row bg-showdrop">
          <img className="h50 w-auto m-auto p10" src={showdrop_logo} />
        </div>

        <div className="row bg-white">
          <div className="col">
            {displayLogo()}
          </div>
        </div>

        <div className="row bg-extra-gray">
          <div className="col">
            <h2 className="bold" style={{ marginTop:"10px" }}>Overview of Campaigns</h2>
          </div>
        </div>

        <div className="row bg-extra-gray">
          <div className="col">
            <div style={{ flex:"1",padding:"10px" }}>
              <div style={{ padding:"0 0 10px",fontSize:"16px",fontWeight:"bold" }}>Total Demographic Breakdown</div>

              <div style={{ height:"160px" }}>
                {
                  brand.gender_data_exists ? (
                    demographicBarGraph(brand.gender_data)
                  ) : (
                    <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                      <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Gender Data Unavailable</div>
                    </div>
                  )
                }
              </div>

              <div style={{ height:"160px" }}>
                {
                  brand.age_data_exists ? (
                    demographicBarGraph(brand.age_data)
                  ) : (
                    <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                      <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Age Data Unavailable</div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row ptb20-lr10 bg-extra-gray">
          <div className="col col-lg-12">
            <div className="table-responsive w100p m-auto bg-white">
              <table className="table table-sm center-text table-black-border mb-0">
                <thead>
                  <tr>
                    <th className="bg-extra-gray"></th>
                    <th className="bg-extra-gray">Launch Date</th>
                    <th className="bg-extra-gray">Location</th>
                    <th className="bg-extra-gray">Product</th>
                    <th></th>
                    <th className="bg-light-purple">Impressions</th>
                    <th className="bg-purple">Dwells</th>
                    <th className="bg-showdrop-yellow">QR Scans</th>
                    <th className="bg-showdrop">Samples Claimed</th>
                    {
                      clickThroughCount() > 0 || rebateSignUpCount() > 0 ? (
                        <th></th>
                      ) : (
                        null
                      )
                    }
                    {showClickThroughHeader()}
                    {showRebateSignUpHeader()}
                  </tr>
                </thead>
                <tbody>
                  {campaigns.map(campaign => {
                    return (
                      <CampaignRow
                        campaign={campaign}
                        clickThroughCount={clickThroughCount()}
                        rebateRedemptionsCount={rebateRedemptionsCount()}
                        rebateSignUpCount={rebateSignUpCount()}
                      />
                    );
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{numberWithCommas(SumArray(campaigns.map(campaign => campaign.impressions)))}</td>
                    <td>{numberWithCommas(SumArray(campaigns.map(campaign => campaign.dwells)))}</td>
                    <td>{numberWithCommas(SumArray(campaigns.map(campaign => campaign.qr_scans)))}</td>
                    <td>{numberWithCommas(SumArray(campaigns.map(campaign => campaign.samples_claimed)))}</td>
                    {
                      clickThroughCount() > 0 || rebateSignUpCount() > 0 ? (
                        <td></td>
                      ) : (
                        null
                      )
                    }
                    {showClicksForAll()}
                    {showRebateCountForAll()}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {displayShopifyOrders(brand)}

        <div className="row ptb20-lr10 bg-extra-gray">
          <div className="col col-lg-6">
            <div className="table-responsive w100p m-auto bg-white">
              <table className="table table-sm table-black-border mb-0">
                <thead className="center-text">
                  <tr>
                    <th className="bg-extra-gray" colSpan={2}>Legend</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="bold bg-light-purple">Impressions</td>
                    <td># of times people walk by the Showdrop kiosk</td>
                  </tr>

                  <tr>
                    <td className="bold bg-purple">Dwells</td>
                    <td># of times people spend time directly in front of kiosk screen</td>
                  </tr>

                  <tr>
                    <td className="bold bg-showdrop-yellow">QR Scans</td>
                    <td># of customers who began sample retrieval process</td>
                  </tr>

                  <tr>
                    <td className="bold bg-showdrop">Samples Claimed</td>
                    <td># of customers who gave contact info to receive PIN to retrieve sample</td>
                  </tr>

                  <tr>
                    <td className="bold bg-extra-gray">Customer Clicks</td>
                    <td># of times customers clicked on the links sent to them</td>
                  </tr>

                  {showRebateLegend()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row ptb20-lr10 bg-extra-gray"></div>

        <div className="row ptb20-lr10 bg-white"></div>

        <div className="row bg-white">
          <div className="col">
            <h2 className="bold">Product Reviews</h2>
          </div>
        </div>

        <div className="accordion">
          {this.props.products.map((product,i) => {
            return (
              <ProductRow
                product={product}
                i={i}
              />
            )
          })}
        </div>

        <div className="row ptb20-lr10 bg-white"></div>
      </div>
    );
  }
}

function SumArray(data) {
  return data.reduce((a, v) => (a = a + v), 0);
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
