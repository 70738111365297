import React from "react";
import { Modal, Button } from "react-bootstrap";
import ahoy from "ahoy.js";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: props.customer.returning_visitor
    }

    this.closeModal = this.closeModal.bind(this)
  }

  closeModal() {
    let showModalState = !this.state.showModal;

    this.setState({
      showModal: showModalState
    })
  }

  componentDidMount() {
    // This restricts a user from clicking "back"
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', function() {
      window.history.go(1);
    });
  }

  render() {
    let { brand_names, campaigns, customer, machine, showdrop_logo, first_visit } = this.props;
    let { showModal } = this.state;

    return (
      <div className="h800">
        <ReturningVistorWarning
          brandNames={brand_names}
          closeModal={this.closeModal}
          showModal={showModal}
        />

        <div className={`container ${campaigns.length > 1 ? "pb-70 pt-50" : "pb-100 pt-100"}`}>
          <div className="row-clean bg-showdrop align-items-center justify-content-center p10 color-white bold fs-30 uppercase br-4 mb-20">
            Congrats!
            <i className="fab fa-gratipay pdl-5"></i>
          </div>

          <CheckForOAuth
            campaignIds={campaigns.map(campaign => campaign.id)}
            code={machine.code}
            email={customer.email}
            oauth={customer.oauth}
            uuid={machine.uuid}
            firstVisit={first_visit}
          />
        </div>

        <AdminSignOutButton
          admin={customer.admin}
          uuid={machine.uuid}
        />

        <div className="fixed-bottom bg-black pb-10">
          <div className="bb-primary bt-primary h10"></div>
          <img className="w120 floatr" src={showdrop_logo}></img>
        </div>
      </div>
    );
  }
}

function BrandActionButton(props) {
  let { campaign, customer, machine } = props;

  if (campaign.has_rebates) {
    return (
      <RebateButton
        campaign={campaign}
        customerId={customer.id}
        uuid={machine.uuid}
      />
    )
  } else {
    return (
      <ShopButton
        campaign={campaign}
        customerId={customer.id}
        uuid={machine.uuid}
      />
    )
  }
}

function RebateButton(props) {
  let campaignId = props.campaign.id;
  let rebateLink = props.campaign.rebate_link;
  let domain = props.campaign.domain;
  let buttonText = props.campaign.text_on_rebate_button;

  const handleClick = () => {
    ahoy.track("REBATE CLICK (APP)", {
      domain: domain,
      campaign_ids: campaignId,
      customer_id: props.customerId,
      machine_uuid: props.uuid
    });

    window.open(rebateLink, "_blank");
  };

  return (
    <button
      className="btn btn-lg btn-block bg-dark-gray bold w300 m-auto"
      onClick={handleClick}
      ref={(node) => {
        if (node) {
          node.style.setProperty("border-color", "#20cb9a", "important");
          node.style.setProperty("color", "#20cb9a", "important");
        }
      }}
    >
      {buttonText}
    </button>
  );
}

function ShopButton(props) {
  let campaignId = props.campaign.id;
  let discountLink = props.campaign.discount_link;
  let discount = props.campaign.discount;
  let domain = props.campaign.domain;

  const handleClick = () => {
    ahoy.track("SHOP CLICK (APP)", {
      domain: domain,
      campaign_ids: campaignId,
      customer_id: props.customerId,
      machine_uuid: props.uuid
    });

    window.open(discountLink, "_blank");
  };

  const buttonText = () => {
    if (props.campaign.discount_disclaimer) {
      return (
        <>SHOP {discount ? discount : "NOW"}*</>
      )
    } else {
      return (
        <>SHOP {discount ? discount : "NOW"}</>
      )
    }
  }

  return (
    <>
      <button
        className="btn btn-lg btn-block bg-dark-gray bold w300 m-auto"
        onClick={handleClick}
        ref={(node) => {
          if (node) {
            node.style.setProperty("border-color", "#20cb9a", "important");
            node.style.setProperty("color", "#20cb9a", "important");
          }
        }}
      >
        {buttonText()}
      </button>

      <DisclaimerCheck disclaimer={props.campaign.discount_disclaimer}/>
    </>
  );
}

function DisclaimerCheck(props) {
  let { disclaimer } = props;

  if (disclaimer) {
    return (
      <p className="fs-12 p10 m0 italic">*{disclaimer}</p>
    )
  } else {
    return null;
  }
}

function CheckForOAuth(props) {
  let { campaignIds, code, email, uuid, oauth, firstVisit } = props;

  if (!oauth) {
    return (
      <>
        <div className="row-clean align-items-center justify-content-center">
          <div className="col-10 col-md-6 col-lg-4">
            <p className="color-white center-text fs-125rem bold mgb-5 lh-125">
              Check your email for PIN
            </p>

            <div
              className="bold pdlr-5 fs-15rem center-text pb-10"
              ref={(node) => {
                if (node) {
                  node.style.setProperty("color", "#20cb9a", "important");
                }
              }}
            >
              {email}
            </div>
          </div>
        </div>

        <div className="row-clean align-items-center justify-content-center center-text">
          <div className="col-10 col-md-6 col-lg-4">
            <IncorrectEmail
              campaignIds={campaignIds}
              uuid={uuid}
              firstVisit={firstVisit}
            />
          </div>
        </div>

        <div className="row-clean align-items-center justify-content-center center-text">
          <div className="col-10 col-md-6 col-lg-4">
            <>
              <span className="italic fs-12 color-light-gray pdr-5">
                Didn't receive PIN? Check spam or "All Mail"
              </span>
            </>
          </div>
        </div>

        <div className="row-clean align-items-center justify-content-center center-text">
          <div className="col-10 col-md-6 col-lg-4">
            <>
              <span className="italic fs-12 color-light-gray pdr-5">
                Still not there? Email info@showdrop.com
              </span>
            </>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="row-clean align-items-center justify-content-center">
          <div className="col-10 col-md-6 col-lg-4">
            <p className="color-white center-text fs-125rem bold mgb-5 lh-125">
              Enter this PIN on the machine to receive your sample
            </p>

            <div
              className="bold pdlr-5 fs-3rem center-text"
              ref={(node) => {
                if (node) {
                  node.style.setProperty("color", "#20cb9a", "important");
                }
              }}
            >
              {code}
            </div>
          </div>
        </div>

        <div className="row-clean align-items-center justify-content-center center-text">
          <div className="col-10 col-md-6 col-lg-4">
            <a className="italic fs-12 color-light-gray">
              PIN not working? info@showdrop.com
            </a>
          </div>
        </div>

        <div className="row-clean align-items-center justify-content-center center-text">
          <div className="col-10 col-md-6 col-lg-4">
            <IncorrectEmail
              campaignIds={campaignIds}
              uuid={uuid}
              firstVisit={firstVisit}
            />
          </div>
        </div>
      </>
    )
  }
}

function IncorrectEmail(props) {
  let { campaignIds, uuid, firstVisit } = props;

  const handleClick = () => {
    if (!firstVisit) {
      ahoy.track("INCORRECT EMAIL CLICK", {
        machine_uuid: uuid,
        campaign_ids: campaignIds,
      });
    }

    window.open("/machines/" + uuid + "/start?sign_out=true", "_self");
  };

  return (
    <div className="pt-10">
      <span className="italic fs-12 color-light-gray pdr-5">Incorrect email?</span>

      <a
        className="italic fs-12 color-light-gray underline"
        onClick={handleClick}
      >
        Click here to re-enter
      </a>
    </div>
  )
}

function AdminSignOutButton(props) {
  let { uuid, admin } = props;

  if (admin) {
    return (
      <div className="row-clean align-items-center justify-content-center center-text mt-40">
        <div className="col-10 col-md-6 col-lg-4">
          <a
          className="fs-20 color-white bg-red bold p10"
            href={
              "/machines/" + uuid + "/start?sign_out=true"
            }
          >
            ADMIN SIGNOUT
          </a>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

function ShowDiscountCode(props) {
  let { discount, discountCode, hasRebates } = props;

  if (discount && !hasRebates) {
    return (
      <p className="pt-10 italic color-light-gray fs-14">
        Discount code:

        <span className="color-white pdl-5">{discountCode}</span>
      </p>
    )
  } else {
    return null;
  }
}

function ReturningVistorWarning(props) {
  let { brandNames, closeModal, showModal } = props;

  if (showModal) {
    return (
      <Modal show={showModal} onHide={closeModal} size="sm" centered>
        <Modal.Header closeButton>
          <Modal.Title className="bold w100p m-auto center-text fs-30">
            <i className="fas fa-exclamation-triangle pdr-10 color-yellow fs-20 vertical-top pt-14"></i>

            Heads Up!
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <ReturningVisitorModalBold
              brandNames={brandNames}
            />
          </div>

          <ReturningVisitorModalSecondary />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal} className="bg-showdrop border-showdrop">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  } else {
    return null;
  }
}

function ReturningVisitorModalBold(props) {
  let { brandNames } = props;

  return (
    <strong>Your unique PIN allows you to receive 1 FREE {brandNames} sample.</strong>
  )
}

function ReturningVisitorModalSecondary(props) {
  return (
    <div className="pt-10">
      If you have already used your PIN, come back soon for our next brand drop, otherwise use it to enjoy your FREE sample now!
    </div>
  )
}
