import React from "react"
import Nav from "../shared/Nav";
import NewHeader from "../shared/headers/NewHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandPackages: [],
      brandProducts: [],
      errors: '',
      onDesktop: true,
      selectedBrand: "",
      selectedPackage: "",
      selectedProduct: "",
      loading: false,
    };

    this.changeBrand = this.changeBrand.bind(this)
    this.changePackage = this.changePackage.bind(this)
    this.changeProduct = this.changeProduct.bind(this)
    this.configureRecord = this.configureRecord.bind(this)
    this.createOption = this.createOption.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeBrand(event) {
    let brandId = Number(event.target.value);
    let selectedBrand = this.props.brands.find(brand => { return brand.id == brandId })

    this.setState({
      selectedBrand: brandId,
      brandPackages: selectedBrand.campaign_packages,
      selectedPackage: selectedBrand.campaign_packages.length > 0 ? selectedBrand.campaign_packages[0].id : "",
      brandProducts: selectedBrand.products,
      selectedProduct: "",
    })
  }

  changePackage(event) {
    let packageId = Number(event.target.value);

    this.setState({
      selectedPackage: packageId
    })
  }

  changeProduct(event) {
    let productId = Number(event.target.value);

    this.setState({
      selectedProduct: productId
    });
  }

  configureRecord() {
    window.location.assign(this.props.url_configure)
  }

  createOption() {
    this.setState({
      loading: true
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      brand_id: this.state.selectedBrand,
      campaign_package_id: this.state.selectedPackage,
      product_id: this.state.selectedProduct,
    })
    formData.append('planogram_option', data)

    fetch(this.props.url_create, {
      method: 'POST',
        headers: {},
        body: formData
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors
          })
        } else {
          window.location.reload()
        }
      }
    )
  }

  render () {
    let { brands, showdrop_logo, url, options } = this.props;
    let { brandPackages, brandProducts, errors, onDesktop, selectedBrand, selectedPackage, selectedProduct, loading } = this.state;
    let pageName = 'planograms';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <NewHeader
              name={'New Planogram Options'}
              url={url}
            />

            <div className="p10">
              <dl className="row border-showdrop" style={{ borderBottom:"1px solid #00000050",margin:"0",padding:"10px" }}>
                <dt className="col-sm-3 text-right">Brand</dt>
                <dd className="col-sm-9">
                  <select className="form-select" value={selectedBrand} onChange={this.changeBrand}>
                    <option value="" disabled selected>Select a Brand</option>

                    {brands.map(brand => {
                      return (
                        <option key={brand.id} value={brand.id}>{brand.name}</option>
                      )
                    })}
                  </select>
                </dd>

                <dt className="col-sm-3 text-right">Package</dt>
                <dd className="col-sm-9">
                  <select className="form-select" value={selectedPackage} onChange={this.changePackage} disabled={selectedBrand ? "" : "disabled"}>
                    <option value="" disabled selected>Select a Package</option>

                    {brandPackages.map(brandPackage => {
                      return (
                        <option key={brandPackage.id} value={brandPackage.id}>{brandPackage.name}</option>
                      )
                    })}
                  </select>
                </dd>

                <dt className="col-sm-3 text-right">Product</dt>
                <dd className="col-sm-9">
                  <select className="form-select" value={selectedProduct} onChange={this.changeProduct} disabled={selectedBrand && selectedPackage ? "" : "disabled"}>
                    <option value="" disabled selected>Select a Product</option>

                    {brandProducts.map(product => {
                      return (
                        <option key={product.id} value={product.id}>{product.name}</option>
                      )
                    })}
                  </select>
                </dd>

                <dt className="col-sm-3 text-right"></dt>
                <dd className="col-sm-9">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 100 }}
                    overlay={renderTooltip("Enable Option")}
                  >
                    <button type="button" className={`btn btn-success mlr-5 ${selectedBrand && selectedPackage && selectedProduct ? "" : "bg-light-gray"}`} onClick={loading ? null : this.createOption} disabled={selectedBrand && selectedPackage && selectedProduct ? "" : "disabled"}>
                      {
                        loading ? (
                          <i className="fas fa-circle-notch fa-spin"></i>
                        ) : (
                          "Enable Option"
                        )
                      }
                    </button>
                  </OverlayTrigger>
                </dd>
              </dl>

              {options.map(option => {
                return (
                  <dl className="row" style={{ borderBottom:"1px solid #00000050",margin:"0",padding:"10px" }}>
                    <dt className="col-sm-3 text-right">Brand</dt>
                    <dd className="col-sm-9">{option.brand.name}</dd>

                    <dt className="col-sm-3 text-right">Package</dt>
                    <dd className="col-sm-9">{option.package.name}</dd>

                    <dt className="col-sm-3 text-right">Product</dt>
                    <dd className="col-sm-9">{option.product.name}</dd>
                  </dl>
                )
              })}

              <dl className="row">
                <dt className="col-sm-3 text-right"></dt>
                <dd className="col-sm-9">
                  <button type="button" className="btn btn-success" onClick={this.configureRecord}>Configure Planogram</button>
                </dd>
              </dl>
            </div>


            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
