import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import MachineRow from "./MachineRow";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true,
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { daily_stats, machines, showdrop_logo, url_new } = this.props;
    let { onDesktop } = this.state;

    let pageName = 'configure_machine'

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Machines Overview'}
              url={url_new}
            />

            <div style={{ display:"flex",flexWrap:"wrap" }}>
              <div style={{ flex:"0 0 348px",display:"flex",backgroundColor:"#f0f0f0",color:"black",padding:"10px",borderRadius:"4px",marginBottom:"4px",border:"2px solid #20cb9a",marginRight:"4px" }}>
                <div style={{ flex:"1",textAlign:"center",maxWidth:"120px",padding:"0 5px",borderRight:"1px solid white" }}>
                  <div>Today</div>
                  <div style={{ fontSize:"12px" }}>{daily_stats.day_1.date}</div>
                </div>

                <div style={{ flex:"1",padding:"0 5px" }}>
                  <div>
                    <span>Users:</span>
                    <span style={{ paddingLeft:"4px",fontWeight:"bold" }}>{daily_stats.day_1.users}</span>
                    {
                      daily_stats.day_1.new_users > 0 ? (
                        <span style={{ paddingLeft:"4px",fontWeight:"bold",fontSize:"10px",color:"#20cb9a" }}>({daily_stats.day_1.new_users} NEW)</span>
                      ) : (
                        null
                      )
                    }
                  </div>

                  <div>
                    <span>Samples:</span>
                    <span style={{ paddingLeft:"4px",fontWeight:"bold" }}>{daily_stats.day_1.samples}</span>
                  </div>
                </div>
              </div>

              <div style={{ flex:"0 0 348px",display:"flex",backgroundColor:"#f0f0f0",color:"black",padding:"10px",borderRadius:"4px",marginBottom:"4px",border:"2px solid #f0f0f0",marginRight:"4px" }}>
                <div style={{ flex:"1",textAlign:"center",maxWidth:"120px",padding:"0 5px",borderRight:"1px solid black" }}>
                  <div>Yesterday</div>
                  <div style={{ fontSize:"12px" }}>{daily_stats.day_2.date}</div>
                </div>

                <div style={{ flex:"1",padding:"0 5px" }}>
                  <div>
                    <span>Users:</span>
                    <span style={{ paddingLeft:"4px",fontWeight:"bold" }}>{daily_stats.day_2.users}</span>
                    {
                      daily_stats.day_2.new_users > 0 ? (
                        <span style={{ paddingLeft:"4px",fontWeight:"bold",fontSize:"10px",color:"#20cb9a" }}>({daily_stats.day_2.new_users} NEW)</span>
                      ) : (
                        null
                      )
                    }
                  </div>

                  <div>
                    <span>Samples:</span>
                    <span style={{ paddingLeft:"4px",fontWeight:"bold" }}>{daily_stats.day_2.samples}</span>
                  </div>
                </div>
              </div>

              <div style={{ flex:"0 0 348px",display:"flex",backgroundColor:"#f0f0f0",color:"black",padding:"10px",borderRadius:"4px",marginBottom:"4px",border:"2px solid #f0f0f0",marginRight:"4px" }}>
                <div style={{ flex:"1",textAlign:"center",maxWidth:"120px",padding:"0 5px",borderRight:"1px solid black" }}>
                  <div>2 Days Ago</div>
                  <div style={{ fontSize:"12px" }}>{daily_stats.day_3.date}</div>
                </div>

                <div style={{ flex:"1",padding:"0 5px" }}>
                  <div>
                    <span>Users:</span>
                    <span style={{ paddingLeft:"4px",fontWeight:"bold" }}>{daily_stats.day_3.users}</span>
                    {
                      daily_stats.day_3.new_users > 0 ? (
                        <span style={{ paddingLeft:"4px",fontWeight:"bold",fontSize:"10px",color:"#20cb9a" }}>({daily_stats.day_3.new_users} NEW)</span>
                      ) : (
                        null
                      )
                    }
                  </div>

                  <div>
                    <span>Samples:</span>
                    <span style={{ paddingLeft:"4px",fontWeight:"bold" }}>{daily_stats.day_3.samples}</span>
                  </div>
                </div>
              </div>

              <div style={{ flex:"0 0 348px",display:"flex",backgroundColor:"#f0f0f0",color:"black",padding:"10px",borderRadius:"4px",marginBottom:"4px",border:"2px solid #f0f0f0" }}>
                <div style={{ flex:"1",textAlign:"center",maxWidth:"120px",padding:"0 5px",borderRight:"1px solid black" }}>
                  <div>3 Days Ago</div>
                  <div style={{ fontSize:"12px" }}>{daily_stats.day_4.date}</div>
                </div>

                <div style={{ flex:"1",padding:"0 5px" }}>
                  <div>
                    <span>Users:</span>
                    <span style={{ paddingLeft:"4px",fontWeight:"bold" }}>{daily_stats.day_4.users}</span>
                    {
                      daily_stats.day_4.new_users > 0 ? (
                        <span style={{ paddingLeft:"4px",fontWeight:"bold",fontSize:"10px",color:"#20cb9a" }}>({daily_stats.day_4.new_users} NEW)</span>
                      ) : (
                        null
                      )
                    }
                  </div>

                  <div>
                    <span>Samples:</span>
                    <span style={{ paddingLeft:"4px",fontWeight:"bold" }}>{daily_stats.day_4.samples}</span>
                  </div>
                </div>
              </div>

            </div>

            <div className="m-auto w100p o-x-scroll">
              <ul className="no-list pd-0 mgb-0" style={{ display:"flex",flexWrap:"wrap" }}>
                {machines.map(machine => {
                  return (
                    <MachineRow machine={machine} />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
