import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Column from "./configuration/Column";
import IndexButtons from "../shared/buttons/IndexButtons";
import IndexHeader from "../shared/headers/IndexHeader";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { planogram, showdrop_logo, url_new, url_assign } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'planograms';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={planogram.name}
              subtext={null}
              url_edit={null}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{planogram.id}</dd>

              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">{planogram.name || '-'}</dd>

              <dt className="col-sm-3 text-right">Row Count</dt>
              <dd className="col-sm-9">{planogram.row_count || '-'}</dd>

              <dt className="col-sm-3 text-right">Column Count</dt>
              <dd className="col-sm-9">{planogram.column_count || '-'}</dd>

              <dt className="col-sm-3 text-right">Column Max</dt>
              <dd className="col-sm-9">{planogram.column_max || '-'}</dd>

              {/* <RenderRows
                rows={planogram.rows}
              /> */}
            </dl>

            <hr/>

            <div className="col">
              <IndexHeader
                name={'Machines'}
                url={url_assign}
              />

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Active?</th>
                    <th scope="col">Completed?</th>
                    <th scope="col">In Waiting?</th>
                    <th scope="col">Scheduled</th>
                    <th scope="col" className="w200"></th>
                  </tr>
                </thead>
                <tbody>
                  {planogram.machine_planograms.map(mp => {
                    return (
                      <tr>
                        <th scope="row">{mp.machine.id}</th>
                        <td>{mp.machine.name}</td>
                        <td>{mp.active ? "true" : "false"}</td>
                        <td>{mp.completed ? "true" : "false"}</td>
                        <td>{mp.ready ? "true" : "false"}</td>
                        <td>{mp.scheduled_at}</td>
                        <td>
                          <IndexButtons
                            id={mp.id}
                            url_show={null}
                            url_edit={null}
                            url_delete={mp.active ? null : mp.url_delete}
                            hide_message={"Are you sure?"}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function RenderRows(props) {
  let { rows } = props;

  if (rows.length > 0) {
    return (
      <>
        {rows.map(row => {
          return (
            <div className="w100p bg-dark-gray mb-5px d-flex">
              {row.columns.map(column => {
                return (
                  <Column
                    column={column}
                    locked={true}
                  />
                )
              })}
            </div>
          )
        })}
      </>
    )
  } else {
    return null;
  }
}
