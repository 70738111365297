import React from "react";
import ahoy from "ahoy.js";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    let { redirect_url, showdrop_logo, start_url } = this.props;

    return (
      <div className="h800">
        <div className="container vh-85 fade-in">
          <div className="row align-items-center justify-content-center mt-40 mb-20">
            <div className="col-8 col-md-6 col-lg-4 center-text">
              <img className="img-fluid rounded mt-4" src={showdrop_logo} />
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-12 pt-30">
              <h2 className="color-primary center-text fs-40 bold">Oops!</h2>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-10 col-md-8 col-lg-6">
              <p className="color-white center-text thin fs-20">
                This page doesn't exist.
              </p>
            </div>
          </div>

          <div className="row align-items-center justify-content-center pt-30 fs-20">
            <div className="col-10 col-md-8 col-lg-6">
              <a className="no-hover" href={start_url}>
                <div className="bg-showdrop p10 uppercase center-text m-auto w400 br-4 bold color-white">Click here to get a new PIN</div>
              </a>
            </div>
          </div>
        </div>

        {
          setTimeout(() => {
            window.location.assign(redirect_url)
          }, 0)
        }
      </div>
    )
  }
}
