import React from "react"
import Nav from "../../shared/Nav";
import IndexHeader from "../../shared/headers/IndexHeader";
import AdminHeader from "../../shared/headers/AdminHeader";
import CampaignRow from "./CampaignRow";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedCampaigns: [],
      unsortedCampaigns: [],
      loading: false,
      onDesktop: true,
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop,
      loading: true
    })

    let that = this;

    fetch(this.props.fetch_url, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false,
        })
      } else {
        that.setState({
          sortedCampaigns: data.campaigns,
          unsortedCampaigns: data.campaigns,
          loading: false,
        })
      }
    })
  }

  render () {
    let {
      showdrop_logo,
    } = this.props;
    let {
      sortedCampaigns,
      loading,
      onDesktop,
    } = this.state;
    let pageName = 'campaign_stats';

    const showCampaigns = () => {
      if (loading) {
        return (
          <div style={{ textAlign:"center" }}>
            <i className="fas fa-circle-notch fa-spin fs-80"></i>
          </div>
        )
      } else {
        return (
          <>
            <table className="table table-long table-sm table-hover table-bordered">
              <thead>
                <tr>
                  <th className="sticky bg-extra-gray center-text">
                    Share w/
                    <div>Brand</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">ID</th>
                  <th className="sticky bg-extra-gray center-text">Brand</th>
                  <th className="sticky bg-extra-gray center-text">Product Name</th>
                  <th className="sticky bg-extra-gray center-text">Machine Name</th>
                  <th className="sticky bg-extra-gray center-text">Impressions</th>
                  <th className="sticky bg-extra-gray center-text">Dwells</th>
                  <th className="sticky bg-extra-gray">
                    Avg
                    <div>Dwell</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    QR
                    <div>Scans</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    PIN
                    <div>Requests</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    PIN
                    <div>Shown</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    PIN
                    <div>Shown (u)</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    PIN
                    <div>Inputs</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    Valid PIN
                    <div>Inputs (u)</div>
                  </th>
                  <th className="sticky bg-blue center-text">
                    Eligible
                    <div>Choices (u)</div>
                  </th>
                  <th className="sticky bg-green color-white center-text bold">
                    Pin Status
                    <div>(Claimed)</div>
                  </th>
                  <th className="sticky bg-green color-white center-text bold">Pref %</th>
                  <th className="sticky bg-extra-gray center-text">
                    Pin Status
                    <div>(Dis Error)</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Shop Click
                    <div>Text</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Eng Click
                    <div>Text</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Rebate
                    <div>Sign Ups</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Review
                    <div>Count</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Review
                    <div>%</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Comment
                    <div>Count</div>
                  </th>
                  <th className="sticky bg-extra-gray center-text">
                    Comment
                    <div>%</div>
                  </th>
                  <th className="sticky bg-extra-gray">Orders</th>
                  <th className="sticky bg-extra-gray center-text">
                    Brand
                    <div>Orders</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedCampaigns.map(campaign => {
                  return (
                    <CampaignRow campaign={campaign} />
                  )
                })}
              </tbody>
            </table>
          </>
        )
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white">
            <IndexHeader
              name={'Campaign Stats'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="m-auto w100p o-x-scroll h1000">
                {showCampaigns()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
