import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";
import Row from "./configuration/Row";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: '',
      inStockVideo: this.props.planogram.in_stock_video,
      inStockVideoUploaded: false,
      loading: false,
      onDesktop: true,
      outOfStockVideo: this.props.planogram.out_of_stock_video,
      outOfStockVideoUploaded: false,
    };

    this.changeInStockVideo = this.changeInStockVideo.bind(this)
    this.changeOutOfStockVideo = this.changeOutOfStockVideo.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeInStockVideo(event) {
    this.setState({
      inStockVideo: event.target.files[0],
      inStockVideoUploaded: true
    })
  }

  changeOutOfStockVideo(event) {
    this.setState({
      outOfStockVideo: event.target.files[0],
      outOfStockVideoUploaded: true
    })
  }

  editRecord() {
    this.setState({
      loading: true
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({})
    formData.append('planogram', data)

    if (this.state.inStockVideoUploaded) {
      formData.append('in_stock_video', this.state.inStockVideo)
    }
    if (this.state.outOfStockVideoUploaded) {
      formData.append('out_of_stock_video', this.state.outOfStockVideo)
    }

    fetch(this.props.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors,
          loading: false,
        })
      } else {
        window.location.reload();
      }
    })
  }

  render () {
    let { planogram, products, showdrop_logo, url_assign, url_options } = this.props;
    let { errors, loading, onDesktop } = this.state;
    let pageName = 'create_planogram';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={planogram.name}
              url_show={null}
              url_new={null}
            />

            <div style={{ width:"100%",marginBottom:"20px" }}>
              <div style={{ margin:"auto",textAlign:"center" }}>
                <button type="button" className="btn btn-outline-primary" style={{ fontSize:"20px",fontWeight:"bold",padding:"10px 20px" }} onClick={(e) => {e.stopPropagation(); window.location.assign(url_assign)}}>
                  ASSIGN TO MACHINE
                </button>
              </div>
            </div>

            <div className="p10">
              <dl className="row">
                <dt className="col-sm-3 text-right">
                  In Stock Video
                  <div className="fs-10 thin">(The video on the machine played when the machine is in-stock)</div>
                </dt>
                <dd className="col-sm-9">
                  <label>{planogram.in_stock_video_name}</label>
                  <input className="form-control" type="file" id="formFile" onChange={this.changeInStockVideo} />
                </dd>

                <dt className="col-sm-3 text-right">
                  Out of Stock Video
                  <div className="fs-10 thin">(The video on the machine played when the machine is out-of-stock)</div>
                </dt>
                <dd className="col-sm-9">
                  <label>{planogram.out_of_stock_video_name}</label>
                  <input className="form-control" type="file" id="formFile" onChange={this.changeOutOfStockVideo} />
                </dd>

                <dt className="col-sm-3 text-right"></dt>
                <dd className="col-sm-9">
                  {
                    loading ? (
                      <button type="button" className="btn btn-warning" onClick={null} disabled="disabled">
                        <i className="fas fa-circle-notch fa-spin"></i>
                      </button>
                    ) : (
                      <button type="button" className="btn btn-warning" onClick={this.editRecord}>Add Videos</button>
                    )
                  }
                </dd>
              </dl>
            </div>

            <div style={{ marginBottom:"20px" }}>
              <ul className="no-list">
                {
                  products.map(product => {
                    return (
                      <li>{product.name}</li>
                    )
                  })
                }
              </ul>

              <div style={{ display:"flex" }}>
                <div style={{ flex:"1",maxWidth:"180px" }}>
                  <button type="button" className="btn btn-outline-success" onClick={(e) => {e.stopPropagation(); window.location.assign(url_options)}}>
                    Add More Products
                  </button>
                </div>
              </div>
            </div>

            <dl className="row" style={{ width:"100%" }}>
              <RenderRows
                products={products}
                planogram={planogram}
              />
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}

function RenderRows(props) {
  let { planogram, products } = props;

  if (planogram.rows.length > 0) {
    return (
      <>
        {planogram.rows.map((row, i) => {
          return (
            <Row
              index={i}
              planogram={planogram}
              products={products}
              row={row}
            />
          )
        })}
      </>
    )
  } else {
    return null;
  }
}
