import React from "react"

export default class CampaignRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandHidden: false,
      loadedCampaign: {},
      campaignLoading: true,
    }

    this.fetchCampaignData = this.fetchCampaignData.bind(this)
    this.toggleBrandHidden = this.toggleBrandHidden.bind(this)
  }

  componentDidMount() {
    this.fetchCampaignData()
  }

  fetchCampaignData() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.campaign.id,
    })
    formData.append('campaign', data)

    fetch(this.props.campaign.url_fetch_campaign_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          brandHidden: data.campaign ? data.campaign.brand_hidden : false,
          campaignLoading: false,
          loadedCampaign: data.campaign,
        })
      }
    })
  }

  toggleBrandHidden(loadedCampaign) {
    let that = this;

    fetch(loadedCampaign.url_toggle_brand_hidden, {
      method: 'POST',
      headers: {},
      body: {}
    }).then(response => {
      return response.json();
    }).then((_) => {
      that.setState({
        brandHidden: !that.state.brandHidden
      })
    })
  }

  render () {
    let {} = this.props;
    let {
      brandHidden,
      campaignLoading,
      loadedCampaign,
    } = this.state;

    let toggleIcon = () => {
      if (brandHidden) {
        return (
          <i className="fas fa-toggle-off clickable" onClick={() => this.toggleBrandHidden(loadedCampaign)} style={{ color:"#00000050" }}></i>
        )
      } else {
        return (
          <i className="fas fa-toggle-on clickable" onClick={() => this.toggleBrandHidden(loadedCampaign)}></i>
        )
      }
    }
    let dwells = loadedCampaign ? loadedCampaign.dwells : 0
    let dwellAvg = loadedCampaign ? (loadedCampaign.dwell_seconds / dwells) : 0
    let eligible = loadedCampaign ? loadedCampaign.eligible_choices : 0
    let claimed = loadedCampaign ? loadedCampaign.pin_status_claimed: 0
    let prefenceAvg = claimed / eligible

    if (campaignLoading) {
      return (
        <tr style={{ position:"relative",height:"30px" }}>
          <div style={{ position:"absolute",width:"100%",height:"100%",backgroundColor:"#00000020",color:"white",fontWeight:"bold",borderTop:"1px solid white",borderBottom:"1px solid white" }}>
            <i className="fas fa-circle-notch fa-spin"></i>
            <span style={{ paddingLeft:"4px" }}>Loading Campaign Data...</span>
          </div>
        </tr>
      )
    } else {
      if (loadedCampaign) {
        return (
          <tr key={`campaign-${loadedCampaign.id}-product-${loadedCampaign.product.id}`} className={loadedCampaign.machine.hidden ? 'opacity25' : ''}>
            <td className="center-text" style={{ fontSize:"20px",padding:"0 8px" }}>
              {toggleIcon()}
            </td>
            <td>{loadedCampaign.id}</td>
            <td>{loadedCampaign.brand.name}</td>
            <td>{loadedCampaign.product.name}</td>
            <td>{loadedCampaign.machine.name}</td>
            <td className="right-text">{numberWithCommas(loadedCampaign.impressions)}</td>
            <td className="right-text">{numberWithCommas(dwells)}</td>
            <td className="right-text">{dwells > 0 ? Math.round(dwellAvg * 100) / 100 : 0}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.qr_scans)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.pin_requests)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.pin_shown)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.pin_shown_unique)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.pin_inputs)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.pin_inputs_valid)}</td>
            <td className="bold right-text">{numberWithCommas(eligible)}</td>
            <td className="bold right-text">{numberWithCommas(claimed)}</td>
            <td className="right-text">{eligible > 0 ? Math.round(prefenceAvg * 100) : 0}%</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.pin_status_dispense_error)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.shop_click_text)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.engagement_click_text)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.rebate_sign_ups)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.review_count)}</td>
            <td className="bold right-text">{loadedCampaign.review_percent}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.comment_count)}</td>
            <td className="bold right-text">{loadedCampaign.comment_percent}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.orders)}</td>
            <td className="bold right-text">{numberWithCommas(loadedCampaign.brand_orders)}</td>
          </tr>
        )
      } else {
        return (
          <tr></tr>
        )
      }
    }
  }
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
