import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: '',
      logo: this.props.company.logo,
      logoUploaded: false,
      name: this.props.company.name,
      onDesktop: true,
    };

    this.changeLogo = this.changeLogo.bind(this)
    this.changeName = this.changeName.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeLogo(event) {
    this.setState({
      logo: event.target.files[0],
      logoUploaded: true
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      name: this.state.name,
    })
    formData.append('company', data)

    if (this.state.logoUploaded) {
      formData.append('logo', this.state.logo)
    }

    fetch(this.props.company.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { company, showdrop_logo, url_new } = this.props;
    let {
      errors,
      name,
      onDesktop,
    } = this.state;
    let pageName = 'companies';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={company.name}
              url_show={company.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">
                Name
                <div className="fs-10 thin">Character Count: {name.length}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">Logo</dt>
              <dd className="col-sm-9">
                <label>{company.logo_name}</label>
                <input className="form-control" type="file" id="formFile" onChange={this.changeLogo} />
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Company</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
