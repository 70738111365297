import React from "react"

export default class Column extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.column.active,
      maxQuantity: this.props.column.max_quantity,
      showButton: false
    };

    this.changeMaxQuantity = this.changeMaxQuantity.bind(this)
    this.setActive = this.setActive.bind(this)
    this.setInputActive = this.setInputActive.bind(this)
    this.setMaxQuantity = this.setMaxQuantity.bind(this)
  }

  changeMaxQuantity(event) {
    let showButton = this.state.showButton

    if (event.target.value != this.state.max) {
      showButton = true
    } else {
      showButton = false
    }

    this.setState({
      maxQuantity: event.target.value,
      showButton: showButton
    })
  }

  setActive(e) {
    if (!this.props.locked) {
      let that = this;
      let data = JSON.stringify({
        id: this.props.column.id
      })

      fetch(this.props.column.set_active, {
        method: 'PUT',
        headers: {},
        body: data
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors
          })
        } else {
          that.setState({
            active: data.active
          })
        }
      })
    }
  }

  setInputActive(e) {
    if (e) { e.stopPropagation(); }
  }

  setMaxQuantity(e) {
    if (e) { e.stopPropagation(); }

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.id,
      max_quantity: this.state.maxQuantity
    })
    formData.append('planogram_column', data)

    fetch(this.props.column.set_max_quantity, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          maxQuantity: data.max_quantity,
          showButton: false
        })
      }
    })
  }

  render () {
    let { maxQuantity } = this.state;
    let bgClass = ''

    if (this.state.active) {
      bgClass = "bg-showdrop"
    } else {
      bgClass = "bg-white"
    }

    return (
      <div className={`h100 m10 f1 pos-r ${bgClass}`} onClick={this.setActive}>
        <form onClick={this.setInputActive}>
          <input className="w100p" type="text" onChange={this.changeMaxQuantity} onClick={this.setInputActive} value={maxQuantity} disabled={this.props.locked ? "disabled" : ''} />

          <DisplaySaveButton
            onClick={this.setMaxQuantity}
            showButton={this.state.showButton}
          />
        </form>

        <i className="fas fa-check-circle color-white pos-a bottom0 fs-30 p10"></i>
      </div>
    )
  }
}

function DisplaySaveButton(props) {
  let { onClick, showButton } = props;

  if (showButton) {
    return (
      <button type="button" onClick={onClick}>Save</button>
    )
  } else {
    return null
  }
}
