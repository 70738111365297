import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: '',
      name: this.props.category.name,
      onDesktop: true,
      selectedCategory: this.props.category.parent_id,
    };

    this.changeCategory = this.changeCategory.bind(this)
    this.changeName = this.changeName.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeCategory(event) {
    let categoryId = Number(event.target.value);

    this.setState({
      selectedCategory: categoryId,
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      category_id: this.state.selectedCategory,
      name: this.state.name,
    })
    formData.append('category', data)

    fetch(this.props.category.url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { categories, category, showdrop_logo, url_new } = this.props;
    let { errors, name, onDesktop, selectedCategory } = this.state;
    let pageName = 'categories';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={`${this.props.category_type} Category: ${category.name}`}
              url_show={null}
              url_new={url_new}
            />

            <dl className="row">
              {
                selectedCategory ? (
                  <>
                    <dt className="col-sm-3 text-right">{this.props.parent_category_type} Categories</dt>
                    <dd className="col-sm-9">
                      <select className="form-select" value={selectedCategory} onChange={this.changeCategory}>
                        <option value="" disabled selected>Select a {this.props.parent_category_type} Category</option>

                        {categories.map(category => {
                          return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                          )
                        })}
                      </select>
                    </dd>
                  </>
                ) : (
                  null
                )
              }

              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Category</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
