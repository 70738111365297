import React from "react"

export default function MonthCalendar({ year, month, dayProp }) {
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  const firstDayOfMonth = new Date(year, month, 1)
  const monthAndYear = firstDayOfMonth.toLocaleString("en-us", { month: "long", year: "numeric" })
  const firstDayOfWeek = firstDayOfMonth.getDay()
  const headers = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"]
  const cellStyle = { display: "flex", alignItems: "center", justifyContent: "center", height: "35px", width: "35px" }
  const dayElems = []
  for (let i = 0; i < daysInMonth; i++) {
    const key = `${year}-${month}-${i}`
    const gridColumnStyle = i == 0 ? { gridColumnStart: firstDayOfWeek + 1 } : {}
    const { style: dayStyle, otherDayProps } = dayProp(i + 1) || {}
    const style = { ...cellStyle, ...gridColumnStyle, ...dayStyle }
    dayElems.push((<div key={key} style={style} {...otherDayProps}>{i + 1}</div>))
  }
  const gridStyle = { display: "grid", gridTemplateColumns: "repeat(7, 1fr)" }
  return (
    <div style={{ width: "245px", margin: "10px" }}>
      <div style={{ textAlign: "center", fontWeight: "bolder" }}>{monthAndYear}</div>
      <div style={gridStyle}>
        {headers.map((h) => (<div key={h} style={cellStyle}>{h}</div>))}
      </div>
      <div style={gridStyle}>{dayElems}</div>
    </div>
  )
}
