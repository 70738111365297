import React from "react"
import Product from "./Product";
import { Modal } from "react-bootstrap";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPlanogramModal: this.props.machine.next_planogram_ready,
    }

    this.activatePlanogram = this.activatePlanogram.bind(this)
  }

  activatePlanogram() {
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.machine.current_planogram.id,
      planogram_id: this.props.machine.next_planogram.id
    })
    formData.append('planogram', data)

    fetch(this.props.machine.url_activate, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      window.location.reload();
    })
  }

  render () {
    let { machine, restock_url } = this.props;
    let { showPlanogramModal } = this.state;

    let showProgressBar = (product) => {
      let percentage = Math.round((product.current_quantity / product.total_quantity) * 100)

      if (product.current_quantity == product.total_quantity) {
        return (
          <div style={{ position:"relative",border:"1px solid #20cb9a",borderRadius:"4px",fontWeight:"bold",textAlign:"center" }}>
            <div style={{ position:"absolute",left:"0",right:"0",fontSize:"20px",color:"white" }}>
              <i className="fas fa-check-circle" style={{ paddingRight:"4px" }}></i>

              Full
            </div>
            <div style={{ height:"30px",width:`100%`,backgroundColor:"#20cb9a" }}></div>
          </div>
        )
      } else if (percentage == 0) {
        return (
          <div style={{ position:"relative",border:"1px solid #ef5c63",borderRadius:"4px",fontWeight:"bold",textAlign:"center" }}>
            <div style={{ position:"absolute",left:"0",right:"0",fontSize:"20px",color:"white" }}>
              <i className="fas fa-exclamation-circle" style={{ paddingRight:"4px" }}></i>

              Empty
            </div>
            <div style={{ height:"30px",width:`100%`,backgroundColor:"#ef5c63" }}></div>
          </div>
        )
      } else {
        return (
          <div style={{ position:"relative",border:"1px solid #ef5c63",borderRadius:"4px",fontWeight:"bold",textAlign:"center" }}>
            <div style={{ position:"absolute",left:"0",right:"0",fontSize:"20px",color:"black" }}>{100 - percentage}% Empty</div>
            <div style={{ height:"30px",width:`${percentage}%`,backgroundColor:"#ef5c6350" }}></div>
          </div>
        )
      }
    }

    return (
      <div className="container-fluid full-height" style={{ width:"100vw" }}>
        <div className="row">
          <div className="col">
            <ul style={{ listStyleType:"none",padding:"0",margin:"0",marginTop:"20px" }}>
              {machine.products.map(product => {
                let productNeededCount = product.total_quantity - product.current_quantity

                return (
                  <li style={{ border:"2px solid #00000020",borderRadius:"4px",marginBottom:"20px" }}>
                    <div style={{ textDecoration:"none",color:"black" }}>
                      <div style={{ display:"flex" }}>
                        <div style={{ padding:"10px" }}>
                          <img src={product.image} style={{ width:"100px" }}></img>
                        </div>

                        <div style={{ borderLeft:"2px solid #00000020",padding:"10px",width:"100%",position:"relative" }}>
                          <div style={{ fontSize:"18px",fontWeight:"bold" }}>{product.brand.name}</div>
                          <div style={{ fontSize:"12px" }}>{product.name}</div>
                          <div style={{ fontSize:"12px",position:"absolute",bottom:"0",left:"0",width:"100%",padding:"5px",textAlign:"center",backgroundColor:"#f0f0f0" }}>
                            {
                              productNeededCount >= 100 ? (
                                <span style={{ fontWeight:"bold",fontSize:"15px",backgroundColor:"#20cb9a",width:"30px",height:"30px",borderRadius:"100px",color:"white",textAlign:"center",marginRight:"4px",display:"inline-block",padding:"4px 0" }}>{productNeededCount}</span>
                              ) : (
                                <span style={{ fontWeight:"bold",fontSize:"18px",backgroundColor:"#20cb9a",width:"30px",height:"30px",borderRadius:"100px",color:"white",textAlign:"center",marginRight:"4px",display:"inline-block",padding:"2px 0" }}>{productNeededCount}</span>
                              )
                            }
                            <span>Products Needed</span>
                          </div>
                        </div>
                      </div>

                      {showProgressBar(product)}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div style={{ position:"sticky",bottom:"0",left:"0",width:"100%",backgroundColor:"white",padding:"10px 5px",boxShadow:"rgb(0 0 0 / 20%) 0px -20px 15px -15px" }}>
          <div className="clickable" style={{ backgroundColor:"#1F51FF",color:"white",fontWeight:"bold",fontSize:"16px",padding:"20px 10px",borderRadius:"4px",textAlign:"center",margin:"10px" }} onClick={() => window.location.assign(restock_url)}>
            Start Restocking
          </div>
        </div>

        <PlanogramModal
          activate={this.activatePlanogram}
          img={machine.activate_image}
          show={showPlanogramModal}
        />
      </div>
    )
  }
}

function PlanogramModal(props) {
  let { activate, img, show } = props;

  if (show) {
    return (
      <Modal show={show} centered>
        <Modal.Body>
          <div style={{ textAlign:"center" }}>
            <img src={`${img}`} style={{ width:"50%" }}></img>
          </div>

          <div style={{ padding:"20px 0",textAlign:"center" }}>
            <div style={{ fontSize:"24px",fontWeight:"bold" }}>
              Time to Refresh Brands!
            </div>

            <div style={{ fontSize:"18px",fontStyle:"italic" }}>
              New brands... who dis?
            </div>
          </div>

          <button style={{ width:"100%",padding:"10px",borderRadius:"4px",border:"none",backgroundColor:"#20cb9a",fontSize:"18px",fontWeight:"bold",color:"white" }} onClick={activate}>I'm ready for something new!</button>
        </Modal.Body>
      </Modal>
    )
  } else {
    return null;
  }
}
