import React from "react"
import ahoy from "ahoy.js"

export default class Info extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    ahoy.track("ABOUT US CLICK", { campaign_id: this.props.campaign_id, customer_id: this.props.customer_id });
  }

  render () {
    return (
      <i className="fas fa-info-circle color-white fs-24" data-toggle="modal" data-target="#showdropInfo" onClick={this.onClick}></i>
    );
  }
}
