import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import Column from "./configuration/Column";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Configure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: '',
      onDesktop: true,
    };

    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  editRecord() {
    window.location.assign(this.props.url)
  }

  render () {
    let { rows, showdrop_logo } = this.props;
    let { errors, onDesktop } = this.state;
    let pageName = 'planograms';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col-lg-10 bg-white">
            <EditHeader
              name={"Configure Planogram"}
              url_show={null}
              url_new={null}
            />

            <dl className="row">
              <RenderRows
                rows={rows}
              />

              <dd className="col-sm-12">
                <button type="button" className="btn btn-success" onClick={this.editRecord}>Save Configuration</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}

function RenderRows(props) {
  let { rows } = props;

  if (rows.length > 0) {
    return (
      <>
        {rows.map(row => {
          return (
            <div className="w100p bg-dark-gray mb-5px d-flex">
              {row.columns.map(column => {
                return (
                  <Column
                    column={column}
                    locked={false}
                  />
                )
              })}
            </div>
          )
        })}
      </>
    )
  } else {
    return null;
  }
}
