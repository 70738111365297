import React from "react"

export default class Column extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnActive: this.props.column.active,
      columnQuantity: this.props.column.product.quantity,
      maxQuantity: this.props.column.max_quantity,
      selectedProduct: this.props.column.product.id,
      maxQuantityChanged: false
    };

    this.addQuantity = this.addQuantity.bind(this)
    this.changeMaxQuantity = this.changeMaxQuantity.bind(this)
    this.changeProduct = this.changeProduct.bind(this)
    this.removeQuantity = this.removeQuantity.bind(this)
    this.setMaxOnColumn = this.setMaxOnColumn.bind(this)
    this.setMaxQuantityOnColumn = this.setMaxQuantityOnColumn.bind(this)
    this.setMinOnColumn = this.setMinOnColumn.bind(this)
    this.toggleActive = this.toggleActive.bind(this)
  }

  addQuantity() {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.planogram_product_id,
      machine_id: this.props.column.product.machine_id
    })
    formData.append('planogram_product', data)

    fetch(this.props.column.add_quantity, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          columnQuantity: data.quantity,
        })
      }
    })
  }

  changeMaxQuantity(event) {
    this.setState({
      maxQuantity: event.target.value,
      maxQuantityChanged: true
    })
  }

  changeProduct(event) {
    let productId = Number(event.target.value);

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.planogram_product_id,
      product_id: productId,
      machine_id: this.props.column.product.machine_id
    })
    formData.append('planogram_product', data)

    fetch(this.props.column.set_product, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          selectedProduct: productId,
          columnQuantity: data.quantity,
        })
      }
    })
  }

  removeQuantity() {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.planogram_product_id,
      machine_id: this.props.column.product.machine_id
    })
    formData.append('planogram_product', data)

    fetch(this.props.column.remove_quantity, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          columnQuantity: data.quantity,
        })
      }
    })
  }

  setMaxQuantityOnColumn(event) {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.planogram_product_id,
      max_quantity: this.state.maxQuantity,
      machine_id: this.props.column.product.machine_id
    })
    formData.append('planogram_product', data)

    fetch(this.props.column.set_max_quantity_on_column, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          columnQuantity: 0,
          maxQuantity: data.max_quantity,
          maxQuantityChanged: false
        })
      }
    })
  }

  setMaxOnColumn() {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.planogram_product_id,
      machine_id: this.props.column.product.machine_id
    })
    formData.append('planogram_product', data)

    fetch(this.props.column.set_max_on_column, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          columnQuantity: data.quantity,
        })
      }
    })
  }

  setMinOnColumn() {
    this.props.resetValues();

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.planogram_product_id,
      machine_id: this.props.column.product.machine_id
    })
    formData.append('planogram_product', data)

    fetch(this.props.column.set_min_on_column, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          columnQuantity: data.quantity,
        })
      }
    })
  }

  toggleActive() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.column.planogram_product_id
    })
    formData.append('planogram_product', data)
    let currentActive = this.state.columnActive;

    this.setState({
      columnActive: !currentActive
    })

    fetch(this.props.column.set_active, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          columnActive: data.active,
          selectedProduct: data.product_id,
          columnQuantity: data.quantity,
        })
      }
    })
  }

  render () {
    let { column, empty, index, products, maxedOut, machine, onDesktop } = this.props;
    let { columnActive, columnQuantity, maxQuantity, maxQuantityChanged, selectedProduct } = this.state;
    let bgClass = '';
    let columnValue = "";

    if (columnActive) {
      bgClass = "bg-white"
    } else {
      bgClass = "bg-light-gray"
    }

    if (maxedOut) {
      columnValue = maxQuantity
    } else if (empty) {
      columnValue = 0
    } else {
      columnValue = columnQuantity
    }

    let quantityIsZero = columnQuantity == 0
    let quantityIsMax = columnQuantity == maxQuantity

    const renderInventoryEdit = () => {
      return (
        <>
          {
            machine.planogram.inventory_editable ? (
              <>
                <div className="f1 w50fs change-quantity m5 fs-16">
                  <div className="fs-8 center-text">Inventory</div>
                  <input className="w50 center-text bold" style={{ color:`${columnValue == 0 ? "red" : "white"}` }} type="text" value={columnValue} disabled />
                </div>

                <div className="f1 fs-14 w40fs">
                  <button className={`${quantityIsZero ? "bg-semi-light-gray" : "bg-red"} color-white border-none`} style={{ padding:"4px 8px",marginTop:"18px",borderRadius:"4px" }} onClick={machine.planogram.inventory_editable ? this.setMinOnColumn : null} disabled={quantityIsZero ? "disabled" : null}>MIN</button>
                </div>

                <div className={`f1 fs-24 ${onDesktop ? "w70fs" : "center-text"}`}>
                  <RemoveQuantity
                    columnQuantity={columnValue}
                    removeQuantity={this.removeQuantity}
                    empty={empty}
                    machine={machine}
                  />

                  <AddQuantity
                    addQuantity={this.addQuantity}
                    columnQuantity={columnValue}
                    maxQuantity={column.max_quantity}
                    maxedOut={maxedOut}
                    machine={machine}
                  />
                </div>

                <div className="f1 fs-14 w40fs">
                  <button className={`${quantityIsMax ? "bg-semi-light-gray" : "bg-green"} color-white border-none`} style={{ padding:"4px 8px",marginTop:"18px",borderRadius:"4px" }} onClick={machine.planogram.inventory_editable ? this.setMaxOnColumn : null} disabled={quantityIsMax ? "disabled" : null}>MAX</button>
                </div>
              </>
            ) : (
              null
            )
          }

          <div className="f1 w50fs max-quantity m5 fs-16">
            <div className="fs-8 center-text">Max Quantity</div>
            <input className="w50 center-text bold" type="text" onChange={this.changeMaxQuantity} value={maxQuantity} />
          </div>

          <div className="f1 fs-14 w40fs">
            <button className={`${maxQuantityChanged ? "bg-green" : "bg-semi-light-gray"} color-white border-none`} style={{ padding:"4px 8px",marginTop:"18px",borderRadius:"4px" }} onClick={this.setMaxQuantityOnColumn} disabled={maxQuantityChanged ? null : "disabled"}>SET</button>
          </div>
        </>
      )
    }

    const renderRow = () => {
      let productPresent = products.map(product => product.id ).includes(selectedProduct)

      return (
        <div style={{ opacity:`${columnActive ? "1.0" : "0.25"}`,display:`${onDesktop ? "flex" : "initial"}` }}>
          <div className="f1">
            <select className="form-select mt-10" value={selectedProduct} onChange={this.changeProduct} disabled={columnActive ? null : 'disabled'} style={{ color:`${productPresent ? 'black' : 'red'}`,fontWeight:`${productPresent ? 'normal' : 'bold'}` }}>
              <option value="" disabled selected>{productPresent ? "Select a Product" : "!! Product Error (Select a Product or Deactivate) !!"}</option>

              {products.map(product => {
                return (
                  <option key={product.id} value={product.id}>{product.name}</option>
                )
              })}
            </select>
          </div>

          {
            onDesktop ? (
              renderInventoryEdit()
            ) : (
              null
            )
          }
        </div>
      )
    }

    const renderCheckbox = () => {
      if (columnActive) {
        return (
          <input className="form-check-input" type="checkbox" value="" onChange={this.toggleActive} checked></input>
        )
      } else {
        return (
          <input className="form-check-input" type="checkbox" value="" onChange={this.toggleActive}></input>
        )
      }
    }

    return (
      <>
        <div className={`d-flex m10 p10 color-black mh-72 ${bgClass}`} style={{ marginBottom:`${onDesktop ? "10" : '0'}` }}>
          <div className="f1 w30fs m5 fs-24 center-text mt-8">
            <div className="form-check">
              {renderCheckbox()}
            </div>
          </div>

          <div className="f1 w20fs center-text fs-20 bold mt-12">{index}</div>

          {renderRow()}
        </div>

        {
          onDesktop ? (
            null
          ) : (
            <div className={`d-flex m10 color-black ${bgClass}`} style={{ marginTop:"0",padding:"10px 5px",paddingTop:"0",minHeight:"60px" }}>
              {renderInventoryEdit()}
            </div>
          )
        }
      </>
    )
  }
}

function AddQuantity(props) {
  let { addQuantity, columnQuantity, maxQuantity, maxedOut, machine } = props;
  let onClick = "";
  let color = "";

  if (maxedOut || columnQuantity >= maxQuantity) {
    onClick = null
    color = "color-semi-light-gray"
  } else {
    onClick = addQuantity
    color = "color-green"
  }

  return (
    <i className={`fas fa-plus-circle m5 ${color} mt-20`} onClick={machine.planogram.inventory_editable ? onClick : null}></i>
  )
}

function RemoveQuantity(props) {
  let { empty, removeQuantity, columnQuantity, machine } = props;
  let onClick = "";
  let color = "";

  if (empty || columnQuantity <= 0) {
    onClick = null
    color = "color-semi-light-gray"
  } else {
    onClick = removeQuantity
    color = "color-red"
  }

  return (
    <i className={`fas fa-minus-circle m5 ${color} mt-20`} onClick={machine.planogram.inventory_editable ? onClick : null}></i>
  )
}
