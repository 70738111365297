import React from "react"
import Nav from "../shared/Nav";
import Form from "react-bootstrap/Form";
import EditHeader from "../shared/headers/EditHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      discountName: this.props.campaign.discount_name,
      emailBgColor: this.props.campaign.email_bg_color,
      emailButtonBgColor: this.props.campaign.email_button_bg_color,
      emailButtonTxtColor: this.props.campaign.email_button_txt_color,
      emailFrequency: this.props.campaign.email_frequency,
      emailTxtColor: this.props.campaign.email_txt_color,
      errors: '',
      image: this.props.campaign.image,
      imageUploaded: false,
      marketingTextAppend: this.props.campaign.marketing_text_append,
      onDesktop: true,
      rebateButtonText: this.props.campaign.rebate_button_text,
      rebateColor: this.props.campaign.rebate_color,
      rebateImage: this.props.campaign.rebate_image,
      rebateImageUploaded: false,
      rebateResponse: this.props.campaign.rebate_response,
      rebateSubtext: this.props.campaign.rebate_subtext,
      rebateTitle: this.props.campaign.rebate_title,
      rebateTxtColor: this.props.campaign.rebate_txt_color,
      selectedApplyDiscount: this.props.campaign.apply_discount,
      selectedBrand: this.props.campaign.brand.id,
      selectedEnableRebate: this.props.campaign.enable_rebate,
      selectedEndDate: this.props.campaign.end_date,
      selectedMachine: this.props.campaign.machine.id,
      selectedProductIds: this.props.campaign.products.map(product => product.id),
      selectedStartDate: this.props.campaign.start_date,
      staticCode: this.props.campaign.static_code,
    };

    this.changeApplyDiscount = this.changeApplyDiscount.bind(this)
    this.changeBrand = this.changeBrand.bind(this)
    this.changeDiscountName = this.changeDiscountName.bind(this)
    this.changeEmailBgColor = this.changeEmailBgColor.bind(this)
    this.changeEmailButtonBgColor = this.changeEmailButtonBgColor.bind(this)
    this.changeEmailButtonTxtColor = this.changeEmailButtonTxtColor.bind(this)
    this.changeEmailFrequency = this.changeEmailFrequency.bind(this)
    this.changeEmailTxtColor = this.changeEmailTxtColor.bind(this)
    this.changeEnableRebate = this.changeEnableRebate.bind(this)
    this.changeEndDate = this.changeEndDate.bind(this)
    this.changeImage = this.changeImage.bind(this)
    this.changeMachine = this.changeMachine.bind(this)
    this.changeMarketingTextAppend = this.changeMarketingTextAppend.bind(this)
    this.changeProducts = this.changeProducts.bind(this)
    this.changeRebateButtonText = this.changeRebateButtonText.bind(this)
    this.changeRebateColor = this.changeRebateColor.bind(this)
    this.changeRebateImage = this.changeRebateImage.bind(this)
    this.changeRebateResponse = this.changeRebateResponse.bind(this)
    this.changeRebateSubtext = this.changeRebateSubtext.bind(this)
    this.changeRebateTitle = this.changeRebateTitle.bind(this)
    this.changeRebateTxtColor = this.changeRebateTxtColor.bind(this)
    this.changeStartDate = this.changeStartDate.bind(this)
    this.changeStaticCode = this.changeStaticCode.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeBrand(event) {
    let brandId = Number(event.target.value);

    this.setState({
      selectedBrand: brandId,
    })
  }

  changeRebateColor(event) {
    this.setState({
      rebateColor: event.target.value
    })
  }

  changeRebateTxtColor(event) {
    this.setState({
      rebateTxtColor: event.target.value
    })
  }

  changeMarketingTextAppend(event) {
    this.setState({
      marketingTextAppend: event.target.value
    })
  }

  changeEmailBgColor(event) {
    this.setState({
      emailBgColor: event.target.value
    })
  }

  changeEmailTxtColor(event) {
    this.setState({
      emailTxtColor: event.target.value
    })
  }

  changeEmailButtonTxtColor(event) {
    this.setState({
      emailButtonTxtColor: event.target.value
    })
  }

  changeEmailButtonBgColor(event) {
    this.setState({
      emailButtonBgColor: event.target.value
    })
  }

  changeApplyDiscount() {
    let applyDiscount = !this.state.selectedApplyDiscount;

    this.setState({
      selectedApplyDiscount: applyDiscount
    })
  }

  changeEnableRebate() {
    let enableRebate = !this.state.selectedEnableRebate;

    this.setState({
      selectedEnableRebate: enableRebate
    })
  }

  changeProducts(event) {
    let items = [...this.state.selectedProductIds];
    let selectedProductId = event.target.value;
    let itemAlreadyExistsInCollection = items.indexOf(selectedProductId) > -1;

    if (itemAlreadyExistsInCollection) {
      items = items.filter(item => item !== selectedProductId);
    } else {
      items = [...this.state.selectedProductIds, selectedProductId]
    }

    this.setState({
      selectedProductIds: items
    });
  }

  changeRebateResponse(event) {
    this.setState({
      rebateResponse: event.target.value
    })
  }

  changeRebateImage(event) {
    this.setState({
      rebateImage: event.target.files[0],
      rebateImageUploaded: true
    })
  }

  changeImage(event) {
    this.setState({
      image: event.target.files[0],
      imageUploaded: true
    })
  }

  changeMachine(event) {
    let machineId = Number(event.target.value);

    this.setState({
      selectedMachine: machineId
    })
  }

  changeEndDate(event) {
    this.setState({
      selectedEndDate: event.target.value
    })
  }

  changeStartDate(event) {
    this.setState({
      selectedStartDate: event.target.value
    })
  }

  changeDiscountName(event) {
    this.setState({
      discountName: event.target.value
    })
  }

  changeRebateSubtext(event) {
    this.setState({
      rebateSubtext: event.target.value
    })
  }

  changeRebateButtonText(event) {
    this.setState({
      rebateButtonText: event.target.value
    })
  }

  changeRebateTitle(event) {
    this.setState({
      rebateTitle: event.target.value
    })
  }

  changeEmailFrequency(event) {
    this.setState({
      emailFrequency: event.target.value
    })
  }

  changeStaticCode(event) {
    this.setState({
      staticCode: event.target.value
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      apply_discount: this.state.selectedApplyDiscount,
      brand_id: this.state.selectedBrand,
      discount_name: this.state.discountName,
      email_bg_color: this.state.emailBgColor,
      email_button_bg_color: this.state.emailButtonBgColor,
      email_button_txt_color: this.state.emailButtonTxtColor,
      email_frequency: this.state.emailFrequency,
      email_txt_color: this.state.emailTxtColor,
      enable_rebate: this.state.selectedEnableRebate,
      ended_at: this.state.selectedEndDate,
      machine_id: this.state.selectedMachine,
      marketing_text_append: this.state.marketingTextAppend,
      product_ids: JSON.stringify(this.state.selectedProductIds),
      rebate_button_text: this.state.rebateButtonText,
      rebate_color: this.state.rebateColor,
      rebate_response: this.state.rebateResponse,
      rebate_subtext: this.state.rebateSubtext,
      rebate_title: this.state.rebateTitle,
      rebate_txt_color: this.state.rebateTxtColor,
      started_at: this.state.selectedStartDate,
      static_code: this.state.staticCode,
    })
    formData.append('campaign', data)

    if (this.state.imageUploaded) {
      formData.append('image', this.state.image)
    }
    if (this.state.rebateImageUploaded) {
      formData.append('rebate_image', this.state.rebateImage)
    }

    fetch(this.props.campaign.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { brands, campaign, machines, products, showdrop_logo, url_new } = this.props;
    let {
      discountName,
      emailBgColor,
      emailButtonBgColor,
      emailButtonTxtColor,
      emailFrequency,
      emailTxtColor,
      errors,
      marketingTextAppend,
      onDesktop,
      rebateButtonText,
      rebateColor,
      rebateResponse,
      rebateSubtext,
      rebateTitle,
      rebateTxtColor,
      selectedApplyDiscount,
      selectedBrand,
      selectedEnableRebate,
      selectedEndDate,
      selectedMachine,
      selectedProductIds,
      selectedStartDate,
      staticCode,
    } = this.state;
    let pageName = "campaigns"

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white br-10 shadow">
            <EditHeader
              name={`${campaign.brand.name}`}
              url_show={campaign.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">Brand</dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedBrand} onChange={this.changeBrand}>
                  {brands.map(brand => {
                    return (
                      <option key={brand.id} value={brand.id}>{brand.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">Vending Machine</dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedMachine} onChange={this.changeMachine}>
                  <option value="" disabled selected>Select a Vending Machine</option>

                  {machines.map(machine => {
                    return (
                      <option key={machine.id} value={machine.id}>{machine.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">Products</dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedProductIds} onChange={this.changeProducts} multiple>
                  {products.map(product => {
                    return (
                      <option key={product.id} value={product.id}>{product.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">Start Date</dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <Form.Control type="date" value={selectedStartDate} name='start_date' onChange={this.changeStartDate} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">End Date</dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <Form.Control type="date" value={selectedEndDate} name='end_date' onChange={this.changeEndDate} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Discount Name
                <div className="fs-10 thin">(Exact Discount Name from Shopify)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Exact Discount Name from Shopify" onChange={this.changeDiscountName} value={discountName} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Static Code
                <div className="fs-10 thin">(Campaign with 1 code for everyone)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Static Code" onChange={this.changeStaticCode} value={staticCode} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Auto Apply Discount?
                <div className="fs-10 thin">(Shopping link will have discount auto applied)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeApplyDiscount} checked={selectedApplyDiscount} />
                  </div>
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Email Frequency
                <div className="fs-10 thin">(Send email to brands every x hours)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Email Frequency (In Hours)" onChange={this.changeEmailFrequency} value={emailFrequency} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Marketing Text Append
                <div className="fs-10 thin">(Appended to the text sent when a customer gets a PIN)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Marketing Text Append" onChange={this.changeMarketingTextAppend} value={marketingTextAppend} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Enable Rebate?
                <div className="fs-10 thin">(Allows customers to go to the rebate page)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeEnableRebate} checked={selectedEnableRebate} />
                  </div>
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Rebate Title
                <div className="fs-10 thin">{"(Ex. 'Get A Free<return>One On Us!')"}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Rebate Title" onChange={this.changeRebateTitle} value={rebateTitle} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Rebate Subtext
                <div className="fs-10 thin">{"(Ex. 'It's almost like<return>getting 1 for free!')"}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Rebate Subtext" onChange={this.changeRebateSubtext} value={rebateSubtext} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Rebate Welcome Text
                <div className="fs-10 thin">{"(The 1st text we send out to a customer after they submit their phone number.)"}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea type="text" className="form-control" placeholder="Rebate Welcome Text" onChange={this.changeRebateResponse} value={rebateResponse} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Rebate Button Text
                <div className="fs-10 thin">{"(The text that appears on the rebate button in an email)"}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Rebate Button Text" onChange={this.changeRebateButtonText} value={rebateButtonText} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Rebate Background Color (HEX)
                <div className="fs-10 thin">(ex. #000000 - Background of Rebates Page)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Rebate Color" onChange={this.changeRebateColor} value={rebateColor} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Rebate Text Color (HEX)
                <div className="fs-10 thin">(ex. #000000 - Text of Rebates Page)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Rebate Text Color" onChange={this.changeRebateTxtColor} value={rebateTxtColor} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Rebate Image
                <div className="fs-10 thin">(400x400)</div>
              </dt>
              <dd className="col-sm-9">
                <label>{campaign.rebate_image_name}</label>
                <input className="form-control" type="file" onChange={this.changeRebateImage} />
              </dd>

              <dt className="col-sm-3 text-right">
                Marketing Email: Background Color (HEX)
                <div className="fs-10 thin">(ex. #000000 - background of rebate section)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Email Rebate Background Color" onChange={this.changeEmailBgColor} value={emailBgColor} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Marketing Email: Text Color (HEX)
                <div className="fs-10 thin">(ex. #000000 - primary text)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Email Text Color" onChange={this.changeEmailTxtColor} value={emailTxtColor} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Marketing Email: Button Text Color (HEX)
                <div className="fs-10 thin">(ex. #000000 - text on button)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Email Button Text Color" onChange={this.changeEmailButtonTxtColor} value={emailButtonTxtColor} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Marketing Email: Button Background Color (HEX)
                <div className="fs-10 thin">(ex. #000000 - background of button)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Email Button Background Color" onChange={this.changeEmailButtonBgColor} value={emailButtonBgColor} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Marketing Image
                <div className="fs-10 thin">(400x600)</div>
              </dt>
              <dd className="col-sm-9">
                <label>{campaign.image_name}</label>
                <input className="form-control" type="file" onChange={this.changeImage} />
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Campaign</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
