import React from "react"
import IndexButtons from "../shared/buttons/IndexButtons";

export default class ProductRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changed: false,
      loading: false,
      trackSales: this.props.product.track_sales
    };

    this.changeTrackSales = this.changeTrackSales.bind(this)
    this.saveProduct = this.saveProduct.bind(this)
  }

  changeTrackSales() {
    let trackSales = !this.state.trackSales;

    this.setState({
      changed: true,
      trackSales: trackSales
    })
  }

  saveProduct(product) {
    this.setState({
      loading: true,
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: product.id,
      track_sales: this.state.trackSales,
    })
    formData.append('marianos_product', data)

    fetch(product.url_update, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loading: false,
          changed: false,
        })
      }
    })
  }

  render () {
    let { changed, loading, trackSales } = this.state;
    let { filterByTrackSales, product } = this.props;

    if (filterByTrackSales && this.props.product.track_sales == false) {
      return null;
    } else {
      return (
        <tr>
          <td style={{ width:"50px" }} scope="row">
            {
              loading ? (
                <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className="btn btn-sm btn-secondary mlr-5" onClick={null} disabled="disabled">
                  <i className="fas fa-circle-notch fa-spin"></i>
                </button>
              ) : (
                <button style={{ fontSize:"16px",padding:"2px 6px" }} type="button" className={`btn btn-sm ${changed ? "btn-success" : "btn-secondary"} mlr-5`} onClick={() => this.saveProduct(product)} disabled={changed ? "" : "disabled"}>
                  <i className="fas fa-save"></i>
                </button>
              )
            }
          </td>
          <td>
            <div className="input-group">
              <div className="form-check">
                <input type="checkbox" className="form-check-input" onChange={this.changeTrackSales} checked={trackSales} />
              </div>
            </div>
          </td>
          <td scope="row">{product.id}</td>
          <td>{product.upc}</td>
          <td>{product.brand_name}</td>
          <td>{product.product_name}</td>
        </tr>
      )
    }
  }
}
