import React from "react"
import Multiselect from 'multiselect-react-dropdown';

export default class SurveyQuestionRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMachines: this.props.surveyQuestion.machines,
      active: this.props.surveyQuestion.active,
      changed: false,
      loading: false,
    }

    this.changeActive = this.changeActive.bind(this)
    this.onMachineSelect = this.onMachineSelect.bind(this)
    this.saveSurveyQuestion = this.saveSurveyQuestion.bind(this)
  }

  changeActive() {
    let active = this.state.active;

    this.setState({
      active: !active,
      changed: true,
    })
  }

  onMachineSelect(selectedList, selectedItem) {
    let items = [...this.state.selectedMachines];
    let itemAlreadyExistsInCollection = items.indexOf(selectedItem) > -1;

    if (itemAlreadyExistsInCollection) {
      items = items.filter(item => item !== selectedItem);
    } else {
      items = [...this.state.selectedMachines, selectedItem]
    }

    this.setState({
      changed: true,
      selectedMachines: items
    });
  }

  saveSurveyQuestion() {
    this.setState({
      loading: true
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.surveyQuestion.id,
      active: this.state.active,
      active_machine_ids: this.state.selectedMachines.map(machine => machine.id),
    })
    formData.append('survey_question', data)

    fetch(this.props.surveyQuestion.url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload()
      }
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render () {
    let { machines, surveyQuestion } = this.props;
    let { active, changed, loading, selectedMachines } = this.state;

    return (
      <>
        <tr>
          <td style={{ width:"80px" }}>
            <div style={{ display:"flex" }}>
              <button type="button" className="btn btn-sm btn-warning" style={{ fontSize:"12px",padding:"3px 6px",marginRight:"5px" }} onClick={(e) => {e.stopPropagation(); this.goToUrl(surveyQuestion.edit_url) }}>
                <i className="fas fa-pencil-alt"></i>
              </button>

              {
                loading ? (
                  <button style={{ fontSize:"16px",padding:"2px 6px",marginRight:"5px" }} type="button" className="btn btn-sm btn-secondary" onClick={null} disabled="disabled">
                    <i className="fas fa-circle-notch fa-spin"></i>
                  </button>
                ) : (
                  <button style={{ fontSize:"16px",padding:"2px 6px",marginRight:"5px" }} type="button" className={`btn btn-sm ${changed ? "btn-success" : "btn-secondary"}`} onClick={() => this.saveSurveyQuestion()} disabled={changed ? "" : "disabled"}>
                    <i className="fas fa-save"></i>
                  </button>
                )
              }
            </div>
          </td>
          <td style={{ width:"80px" }}>
            <div className="input-group mb-3">
              <div className="form-check">
                <input type="checkbox" className="form-check-input" onChange={this.changeActive} checked={active} />
              </div>
            </div>
          </td>
          <td style={{ width:"50px" }}>{surveyQuestion.id}</td>
          <td>{surveyQuestion.message}</td>
          <td>{surveyQuestion.description}</td>
          <td>{surveyQuestion.presentation}</td>
          <td>{surveyQuestion.options || '-'}</td>
          <td>{surveyQuestion.shown_count || '-'}</td>
          <td>{surveyQuestion.response_count || '-'}</td>
        </tr>

        <tr>
          <td colspan="7" style={{ padding:"0",paddingBottom:"30px" }}>
            <Multiselect
              options={machines} // Options to display in the dropdown
              selectedValues={selectedMachines}
              onSelect={this.onMachineSelect} // Function will trigger on select event
              onRemove={this.onMachineSelect} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </td>
        </tr>
      </>
    );
  }
}
