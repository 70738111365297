import React from "react"
import Nav from "../shared/Nav";
import NewHeader from "../shared/headers/NewHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";
import Select from "react-select";

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: '',
      message: '',
      onDesktop: true,
      rating: '',
      selectedBrand: '',
      selectedCustomer: '',
      selectedProduct: '',
    };

    this.changeBrand = this.changeBrand.bind(this)
    this.changeCustomer = this.changeCustomer.bind(this)
    this.changeMessage = this.changeMessage.bind(this)
    this.changeProduct = this.changeProduct.bind(this)
    this.changeRating = this.changeRating.bind(this)
    this.newRecord = this.newRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeBrand(e) {
    this.setState({
      selectedBrand: e.target.value,
      selectedProduct: '',
      selectedCustomer: '',
    })
  }

  changeCustomer(e) {
    this.setState({
      selectedCustomer: e.value
    })
  }

  changeMessage(e) {
    this.setState({
      message: e.target.value
    })
  }

  changeProduct(e) {
    this.setState({
      selectedProduct: e.target.value
    })
  }

  changeRating(e) {
    this.setState({
      rating: e.target.value
    })
  }

  newRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      customer_id: this.state.selectedCustomer,
      message: this.state.message,
      product_id: this.state.selectedProduct,
      rating: this.state.rating,
    })
    formData.append('product_review', data)

    fetch(this.props.url_create, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { brands, product_reviews_url, showdrop_logo } = this.props;
    let {
      errors,
      message,
      onDesktop,
      rating,
      selectedBrand,
      selectedCustomer,
      selectedProduct,
    } = this.state;
    let pageName = 'product_reviews'
    let products = selectedBrand ? brands.filter(brand => brand.id == parseInt(selectedBrand))[0].products : []
    let customers = selectedBrand ? brands.filter(brand => brand.id == parseInt(selectedBrand))[0].customers : []
    let filteredCustomers = selectedProduct ? customers.filter(customer => customer.product_id == parseInt(selectedProduct)) : customers

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <NewHeader
              name={'New Product Review'}
              url={product_reviews_url}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">Brand</dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedBrand} onChange={this.changeBrand}>
                  <option value="" disabled selected>Select a Brand</option>

                  {brands.map(brand => {
                    return (
                      <option key={brand.id} value={brand.id}>{brand.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">Product</dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedProduct} onChange={this.changeProduct} disabled={selectedBrand ? '' : 'disabled'}>
                  <option value="" disabled selected>Select a Product</option>

                  {products.map(product => {
                    return (
                      <option key={product.id} value={product.id}>{product.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">Customer</dt>
              <dd className="col-sm-9">
                <CustomerSelect
                  customers={filteredCustomers}
                  onChange={this.changeCustomer}
                  selectedId={selectedCustomer}
                />
              </dd>

              <dt className="col-sm-3 text-right">
                Rating
                <div className="fs-10 thin">(A number between 0 and 5. (Ex. both "5" and "5.0" are accepted))</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Rating" onChange={this.changeRating} value={rating} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">Message</dt>
              <dd className="col-sm-9">
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Message" onChange={this.changeMessage} value={message} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-success" onClick={this.newRecord}>Create Product Review</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}

function CustomerSelect(props) {
  let { customers, onChange, selectedId } = props;

  const allOption = { value: "all", label: "Select a Customer..." }
  const customerOptions = customers.map(customer => {
    const label = [`#${customer.id}`, customer.name, customer.email, customer.phone].filter(Boolean).join(' ') || customer.id
    return { value: customer.id, label }
  })
  const selectOptions = [allOption, ...customerOptions]
  const selectedOption = selectedId ? selectOptions.find(o => o.value == selectedId) : allOption

  return (
    <Select
      inputId="customer-select"
      value={selectedOption}
      onChange={onChange}
      options={selectOptions}
    />
  )
}
