import React from "react"
import Nav from "../shared/Nav";
import NewHeader from "../shared/headers/NewHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.props.default_date,
      loading: false,
      onDesktop: true,
      selectedMachineIds: []
    };

    this.assignPlanogram = this.assignPlanogram.bind(this)
    this.changeMachines = this.changeMachines.bind(this)
    this.setDate = this.setDate.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  assignPlanogram() {
    this.setState({
      loading: true
    })

    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      machine_ids: this.state.selectedMachineIds,
      date: this.state.date
    })
    formData.append('machine_planogram', data)

    fetch(this.props.url_create, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  changeMachines(event) {
    let items = [...this.state.selectedMachineIds];
    let selectedMachineId = event.target.value;
    let itemAlreadyExistsInCollection = items.indexOf(selectedMachineId) > -1;

    if (itemAlreadyExistsInCollection) {
      items = items.filter(item => item !== selectedMachineId);
    } else {
      items = [...this.state.selectedMachineIds, selectedMachineId]
    }

    this.setState({
      selectedMachineIds: items
    });
  }

  setDate(e) {
    this.setState({
      date: e.currentTarget.value
    })
  }

  render () {
    let { showdrop_logo, machines } = this.props;
    let { date, errors, loading, onDesktop, selectedMachineIds } = this.state;
    let pageName = 'planograms';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <NewHeader
              name={'Assign Planogram to Machine'}
              url={null}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">Launch Date</dt>
              <dd className="col-sm-9">
                <input type="date" value={date} min={this.props.default_date} max={this.props.max_date} onChange={this.setDate} />
              </dd>

              <dt className="col-sm-3 text-right">Machines</dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedMachineIds} onChange={this.changeMachines} multiple>
                  {machines.map(machine => {
                    return (
                      <option key={machine.id} value={machine.id}>{machine.nickname}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className={`btn ${loading ? "btn-secondary" : "btn-success"}`} onClick={loading ? null : this.assignPlanogram}>
                  {
                    loading ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      <>Assign</>
                    )
                  }
                </button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
