import React from "react"
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Provider } from '@shopify/app-bridge-react';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    self = this;

    this.state = {
      activeItem: '',
      shopName: props.shop ? props.shop.name : '',
      shopDescription: props.shop ? props.shop.description : '',
      productIds: props.shop ? props.shop.product_ids : '',
      showSuccessBanner: false
    }

    this.setActive = this.setActive.bind(this);
    this.setProductIds = this.setProductIds.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleShopNameChange = this.handleShopNameChange.bind(this);
    this.handleShopDescriptionChange = this.handleShopDescriptionChange.bind(this);
    this.onProductCheck = this.onProductCheck.bind(this);
    this.submitContactForm = this.submitContactForm.bind(this);
  }

  setActive(event) {
    this.setState({
      activeItem: event.target.name,
      showSuccessBanner: false
    });
  }

  handleShopNameChange(event) {
    this.setState({
      shopName: event.target.value
    });
  }

  handleShopDescriptionChange(event) {
    this.setState({
      shopDescription: event.target.value
    });
  }

  onSubmit(event) {
    let data = JSON.stringify({
      name: event.target.brandname.value,
      description: event.target.description.value,
      shop: this.props.domain
    })

    fetch('/shops/update', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(
      this.setState({
        activeItem: 'update'
      })
    )
  }

  submitContactForm(event) {
    let data = JSON.stringify({
      name: event.target.brandname.value,
      email: event.target.email.value,
    })

    fetch('/shopify/contact', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(
      this.setState({
        activeItem: 'sent',
        showSuccessBanner: false
      })
    )
  }

  setProductIds(event) {
    let data = JSON.stringify({
      productIds: this.state.productIds,
      shop: this.props.domain
    })

    fetch('/shops/update', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(
      this.setState({
        showSuccessBanner: true
      })
    )
  }

  onProductCheck(event) {
    let newList = this.state.productIds;
    let productId = event.target.name;
    let itemNotPresentInList = newList.indexOf(productId) === -1;
    let itemPresentInList = newList.indexOf(productId) > -1;

    if (event.target.checked && itemNotPresentInList) {
      newList.push(productId)
    } else if (event.target.checked == false && itemPresentInList) {
      newList = newList.filter(function(id) { return id !== productId })
    }

    this.setState({
      productIds: newList
    });
  }

  render () {
    return (
      <CheckForProps
        props={this.props}
        state={this.state}
        setActive={this.setActive}
        setProductIds={this.setProductIds}
        onSubmit={this.onSubmit}
        submitContactForm={this.submitContactForm}
        onProductCheck={this.onProductCheck}
        handleShopNameChange={this.handleShopNameChange}
        handleShopDescriptionChange={this.handleShopDescriptionChange}
      />
    );
  }
}

function CheckForProps(props) {
  if (props.props) {
    let { campaigns, domain, domain_name, logo, products, shop, config } = props.props;
    let { activeItem, productIds, shopDescription, shopName, showSuccessBanner } = props.state;

    return (
      <Provider config={config}>
        <div className="container-fluid px-0">
          <NavBar
            activeItem={activeItem}
            domain={domain}
            domainName={domain_name}
            setActive={props.setActive}
          />

          <DetermineDisplay
            activeItem={activeItem}
            campaigns={campaigns}
            logo={logo}
            products={products}
            shop={shop}
            domain={domain}
            shopDescription={shopDescription}
            shopName={shopName}
            productIds={productIds}
            originalProductIds={shop ? shop.product_ids : []}
            showSuccessBanner={showSuccessBanner}
            setActive={props.setActive}
            submitContactForm={props.submitContactForm}
            setProductIds={props.setProductIds}
            onSubmit={props.onSubmit}
            onProductCheck={props.onProductCheck}
            handleShopNameChange={props.handleShopNameChange}
            handleShopDescriptionChange={props.handleShopDescriptionChange}
          />
        </div>
      </Provider>
    )
  } else {
    return (
      <RenderLoading />
    )
  }
}

function NavBar(props) {
  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark" ref={(node) => {
      if (node) {
        node.style.setProperty("background-color", "#20cb9a", "important");
      }
    }}>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className={"nav-item bold bb-primary " + (props.activeItem == 'contact' ? 'active bb-white' : '')} onClick={props.setActive} name={'contact'}>
            <a className="nav-link" onClick={props.setActive} name={'contact'}>Get Started</a>
          </li>

          <li className={"nav-item bold bb-primary " + (props.activeItem == 'products' ? 'active bb-white' : '')} onClick={props.setActive} name={'products'}>
            <a className="nav-link" onClick={props.setActive} name={'products'}>Products</a>
          </li>

          <li className={"nav-item bold bb-primary " + (props.activeItem == 'campaigns' ? 'active bb-white' : '')} onClick={props.setActive} name={'campaigns'}>
            <a className="nav-link" onClick={props.setActive} name={'campaigns'}>Campaigns</a>
          </li>
        </ul>
      </div>

      <DropdownButton id="dropdown-basic-button" variant="secondary" title={props.domainName}>
        <Dropdown.Item href="#" onClick={props.setActive} name={'settings'}>
          Settings
        </Dropdown.Item>
      </DropdownButton>
    </nav>
  );
}

function DetermineDisplay(props) {
  if (props.activeItem == 'products') {
    return (
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-10 col-lg-10">
          <ProductDisplay
            products={props.products}
            productIds={props.productIds}
            setActive={props.setActive}
            setProductIds={props.setProductIds}
            originalProductIds={props.originalProductIds}
            showSuccessBanner={props.showSuccessBanner}
            onProductCheck={props.onProductCheck}
          />
        </div>
      </div>
    )
  } else if (props.activeItem == 'campaigns') {
    return (
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-10 col-lg-10">
          <CampaignDisplay
            campaigns={props.campaigns}
            setActive={props.setActive}
          />
        </div>
      </div>
    )
  } else if (props.activeItem == 'contact') {
    return (
      <ContactDisplay
        domain={props.domain}
        shopName={props.shopName}
        handleShopNameChange={props.handleShopNameChange}
        submitContactForm={props.submitContactForm}
      />
    )
  } else if (props.activeItem == 'settings') {
    return (
      <SettingsDisplay
        shop={props.shop}
        shopName={props.shopName}
        handleShopNameChange={props.handleShopNameChange}
        shopDescription={props.shopDescription}
        handleShopDescriptionChange={props.handleShopDescriptionChange}
        onSubmit={props.onSubmit}
      />
    )
  } else if (props.activeItem == 'sent') {
    return (
      <SentDisplay
        logo={props.logo}
      />
    )
  } else if (props.activeItem == 'update') {
    return (
      <UpdateDisplay />
    )
  } else {
    return (
      <Confimation
        logo={props.logo}
      />
    )
  }
}

function ProductDisplay(props) {
  if (props.products.length > 1) {
    return (
      <div>
        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-6 col-lg-4 pt-30">
            <h2 className="color-primary center-text fs-30 bold">Product Listing</h2>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-8 col-lg-6 p10">
            <p className="center-text fs-20 thin mgb-5 lh-15">
              Select the products you'd like to feature in your Showdrop campaigns.
            </p>
          </div>
        </div>

        <div className="row align-items-center justify-content-center p10">
          <div className="col-8 col-md-4 col-lg-3">
            <button className="btn btn-lg btn-block color-white bold" onClick={props.setProductIds} disabled={props.productIds === props.originalProductIds} ref={(node) => {
              if (node) {
                node.style.setProperty("background-color", "#20cb9a", "important");
                node.style.setProperty("color", "#fff", "important");
              }
            }}>Save Changes</button>
          </div>
        </div>

        <ShowSuccessBanner
          showSuccessBanner={props.showSuccessBanner}
        />

        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Allow?</th>
              <th scope="col">#</th>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody>
            {props.products.map(product => {
              return (
                <tr key={product.id}>
                  <th scope="row">
                    <input
                      type="checkbox"
                      checked={props.productIds.indexOf(product.id.toString()) > -1}
                      onChange={props.onProductCheck}
                      name={product.id}
                    />
                  </th>
                  <td>{product.id}</td>
                  <td>{product.name}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  } else {
    return (
      <div>
        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-6 col-lg-4 pt-30">
            <h2 className="color-primary center-text fs-30 bold">No products yet</h2>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-8 col-lg-6 p10">
            <p className="center-text fs-20 thin mgb-5 lh-15">
              Once on-boarded, your products will be listed here.
            </p>
          </div>
        </div>

        <GetStartedButton
          setActive={props.setActive}
        />
      </div>
    )
  }
}

function CampaignDisplay(props) {
  if (props.campaigns.length > 0) {
    return (
      <div>
        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-6 col-lg-4 pt-30">
            <h2 className="color-primary center-text fs-30 bold">Campaign Listing</h2>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-10 col-lg-8 p10">
            <p className="center-text fs-20 thin mgb-5 lh-15">
              Your Showdrop campaigns partnering with local businesses.
            </p>
          </div>
        </div>

        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Partnership</th>
              <th scope="col">Active?</th>
              <th scope="col">Ends On</th>
            </tr>
          </thead>
          <tbody>
            {props.campaigns.map(campaign => {
              return (
                <tr key={campaign.id}>
                  <th scope="row">{campaign.id}</th>
                  <td>{campaign.partnership}</td>
                  <td>{campaign.active}</td>
                  <td>{campaign.ending}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  } else {
    return (
      <div>
        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-6 col-lg-4 pt-30">
            <h2 className="color-primary center-text fs-30 bold">No campaigns yet</h2>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-8 col-lg-6 p10">
            <p className="center-text fs-20 thin mgb-5 lh-15">
              You don't have any active campaigns yet.
            </p>
          </div>
        </div>

        <GetStartedButton
          setActive={props.setActive}
        />
      </div>
    )
  }
}

function ContactDisplay(props) {
  return (
    <div>
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <h2 className="color-primary center-text fs-30 bold">Let's get started</h2>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <form onSubmit={props.submitContactForm} name={'sent'}>
            <div className="form-group">
              <label for="brandname">Brand Name</label>
              <input type="text" className="form-control" id="brandname" aria-describedby="brandnameHelp" placeholder="Enter brand name" required="required" value={props.shopName} onChange={props.handleShopNameChange} />
            </div>

            <div className="form-group">
              <label for="domain">Domain</label>
              <input type="text" className="form-control" id="domain" aria-describedby="domainHelp" placeholder={props.domain} readonly='readonly' />
            </div>

            <div className="form-group">
              <label for="email">Email address</label>
              <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" required="required" />
            </div>

            <div className="form-group">
              <small className="form-text text-muted">A member of the Showdrop team will be in contact shortly after submitting your information.</small>
            </div>

            <button className="btn btn-lg btn-block color-white bold" type="submit" ref={(node) => {
              if (node) {
                node.style.setProperty("background-color", "#20cb9a", "important");
                node.style.setProperty("color", "#fff", "important");
              }
            }}>Send</button>
          </form>
        </div>
      </div>
    </div>
  )
}

function SettingsDisplay(props) {
  return (
    <div>
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <h2 className="color-primary center-text fs-30 bold">Settings</h2>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <form onSubmit={props.onSubmit}>
            <div className="form-group">
              <label for="brandname">Brand Name</label>
              <input type="text" className="form-control" id="brandname" aria-describedby="brandnameHelp" placeholder="Enter brand name" value={props.shopName} onChange={props.handleShopNameChange} />
            </div>

            <div className="form-group">
              <label for="description">Description</label>
              <textarea type="text" className="form-control" id="description" aria-describedby="descriptionHelp" placeholder="Enter your tagline" value={props.shopDescription} onChange={props.handleShopDescriptionChange} />
            </div>

            <button className="btn btn-lg btn-block color-white bold" type="submit" ref={(node) => {
              if (node) {
                node.style.setProperty("background-color", "#20cb9a", "important");
                node.style.setProperty("color", "#fff", "important");
              }
            }}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

function SentDisplay(props) {
  return (
    <div>
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <h2 className="color-primary center-text fs-30 bold">Sent!</h2>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 p10">
          <p className="center-text fs-20 thin mgb-5 lh-15">
            A member of the Showdrop team will contact you within the next
            <span className="pdlr-5 color-primary bold">24 hours</span>
            to complete your setup.
          </p>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 p10">
          <p className="italic fs-12">
            We can't wait to get you setup with one of our local businesses to showcase your
            brand to thousands of real life users!
          </p>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-5 col-md-3 col-lg-2 center-text">
          <img className="img-fluid rounded mt-4" src={props.logo}></img>
        </div>
      </div>
    </div>
  )
}

function UpdateDisplay(props) {
  return (
    <div>
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <h2 className="color-primary center-text fs-30 bold">Updated!</h2>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 p10">
          <p className="center-text fs-20 thin mgb-5 lh-15">
            Your changes have been saved.
          </p>
        </div>
      </div>
    </div>
  )
}

function RenderLoading() {
  return (
    <div>
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <h2 className="color-primary center-text fs-30 bold">Loading...</h2>
        </div>
      </div>
    </div>
  )
}

function Confimation(props) {
  return (
    <div>
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <h2 className="color-primary center-text fs-30 bold">Congratulations!</h2>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 p10">
          <p className="center-text fs-20 thin mgb-5 lh-15">
            You have successfully integrated your Shopify store with Showdrop.
          </p>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 p10">
          <p className="italic fs-12">
            Once your onboarding with the Showdrop team is complete, you will be able to keep track of all
            of your campaigns and the physical showspaces they are in. You will also see all of your products
            that are currently on display!
          </p>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 p10">
          <p className="italic fs-12">
            If you have any questions or concerns, please contact the Showdrop team at info@showdrop.com
          </p>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-5 col-md-3 col-lg-2 center-text">
          <img className="img-fluid rounded mt-4" src={props.logo}></img>
        </div>
      </div>
    </div>
  )
}

function GetStartedButton(props) {
  return (
    <div className="row align-items-center justify-content-center p10">
      <div className="col-8 col-md-4 col-lg-3">
        <button className="btn btn-lg btn-block color-white bold" onClick={props.setActive} name={'contact'} ref={(node) => {
          if (node) {
            node.style.setProperty("background-color", "#20cb9a", "important");
            node.style.setProperty("color", "#fff", "important");
          }
        }}>Get Started</button>
      </div>
    </div>
  )
}


function ShowSuccessBanner(props) {
  if (props.showSuccessBanner) {
    return (
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4">
          <p className="italic color-primary fs-12 center-text">
            Your product choices have been saved!
          </p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4">
          <p className="italic color-white fs-12 center-text">
            -
          </p>
        </div>
      </div>
    )
  }
}
