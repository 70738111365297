import React from "react"

export default class Errors extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    let { errors } = this.props;

    if (errors) {
      return (
        <div className="center-text red pb-20 fs-14">{errors}</div>
      )
    } else {
      return null;
    }
  }
}
