import React from "react"
import Nav from "../shared/Nav";
import NewHeader from "../shared/headers/NewHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: '',
      minorCategories: [],
      name: '',
      onDesktop: true,
      selectedMajorCategory: '',
      selectedMinorCategory: '',
    };

    this.changeMajorCategory = this.changeMajorCategory.bind(this)
    this.changeMinorCategory = this.changeMinorCategory.bind(this)
    this.changeName = this.changeName.bind(this)
    this.newRecord = this.newRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeMajorCategory(event, category_type) {
    let categoryId = Number(event.target.value);

    let majorCategory = category_type == "Sub" ? this.props.major_categories.find(category => category.id == categoryId) : null

    this.setState({
      selectedMajorCategory: categoryId,
      minorCategories: majorCategory ? majorCategory.minor_categories : [],
    })
  }

  changeMinorCategory(event, category_type) {
    let categoryId = Number(event.target.value);

    this.setState({
      selectedMinorCategory: categoryId,
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  newRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      minor_category_id: this.state.selectedMinorCategory,
      major_category_id: this.state.selectedMajorCategory,
      name: this.state.name,
    })
    formData.append('category', data)

    fetch(this.props.url_create, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { major_categories, category_type, showdrop_logo } = this.props;
    let { errors, minorCategories, name, onDesktop, selectedMajorCategory, selectedMinorCategory } = this.state;
    let pageName = 'categories';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <NewHeader
              name={`New ${category_type} Category`}
              url={null}
            />

            <dl className="row">
              {
                major_categories ? (
                  <>
                    <dt className="col-sm-3 text-right">Major Categories</dt>
                    <dd className="col-sm-9">
                      <select className="form-select" value={selectedMajorCategory} onChange={(e) => this.changeMajorCategory(e, category_type)}>
                        <option value="" disabled selected>Select a Major Category</option>

                        {major_categories.map(category => {
                          return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                          )
                        })}
                      </select>
                    </dd>
                  </>
                ) : (
                  null
                )
              }

              {
                category_type == "Sub" ? (
                  <>
                    <dt className="col-sm-3 text-right">Minor Categories</dt>
                    <dd className="col-sm-9">
                      <select className="form-select" value={selectedMinorCategory} onChange={(e) => this.changeMinorCategory(e, category_type)} disabled={selectedMajorCategory ? '' : 'disabled'}>
                        <option value="" disabled selected>Select a Minor Category</option>

                        {minorCategories.map(category => {
                          return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                          )
                        })}
                      </select>
                    </dd>
                  </>
                ) : (
                  null
                )
              }

              <dt className="col-sm-3 text-right">
                Name
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-success" onClick={this.newRecord}>Create {category_type} Category</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
