import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true,
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render () {
    let { showdrop_logo, url_new, waves } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'waves';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Waves'}
              url={url_new}
            />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Brand</th>
                  <th scope="col">Campaign Packages</th>
                </tr>
              </thead>
              <tbody>
                {waves.map(wave => {
                  return (
                    <tr className="clickable" onClick={(e) => {e.stopPropagation(); this.goToUrl(wave.url)}}>
                      <th scope="row">{wave.id}</th>
                      <td>{wave.name}</td>
                      <td>{wave.brand.name}</td>
                      <td>
                        {
                          wave.campaign_packages.length > 0 ? (
                            <ul style={{ margin:"0",padding:"0",listStyleType:"none" }}>
                              {wave.campaign_packages.map(cp => {
                                return (
                                  <li>{cp.name}</li>
                                )
                              })}
                            </ul>
                          ) : (
                            "-"
                          )
                        }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
