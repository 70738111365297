import React from "react"
import Nav from "../shared/Nav";
import AdminHeader from "../shared/headers/AdminHeader";
import IndexHeader from "../shared/headers/IndexHeader";

export default class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true
    };
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { mass_texts, showdrop_logo, url_new } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'text_messages';

    let displayFilters = (values) => {
      if (values.length > 0) {
        return (
          <ul style={{ paddingLeft:"12px",margin:"0" }}>
            {
              values.map(value => {
                return (
                  <li>{value}</li>
                )
              })
            }
          </ul>
        )
      } else {
        return "-"
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <IndexHeader
              name={'Mass Texts'}
              url={url_new}
            />

            <table className="table table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Message</th>
                  <th>Message Type</th>
                  <th>Scheduled At</th>
                  <th>Customer Count</th>
                  <th>Selected Ages</th>
                  <th>Selected Brands</th>
                  <th>Selected Campaigns</th>
                  <th>Selected Genders</th>
                  <th>Selected Machines</th>
                  <th>Selected Zips</th>
                  <th>Sidekiq Job ID</th>
                </tr>
              </thead>
              <tbody>
                {mass_texts.map(mt => {
                  return (
                    <tr>
                      <td>{mt.id}</td>
                      <td>{mt.message}</td>
                      <td>{mt.message_type}</td>
                      <td>{mt.scheduled_at}</td>
                      <td>{mt.customer_count}</td>
                      <td>{displayFilters(mt.selected_ages)}</td>
                      <td>{displayFilters(mt.selected_brands)}</td>
                      <td>{displayFilters(mt.selected_campaigns)}</td>
                      <td>{displayFilters(mt.selected_genders)}</td>
                      <td>{displayFilters(mt.selected_machines)}</td>
                      <td>{displayFilters(mt.selected_zips)}</td>
                      <td>{mt.job_id}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
