import React from "react";
import Chart from "chart.js/auto";
import { Bar, ChartDataLabels } from "react-chartjs-2";

export default class CampaignRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showContent: false
    };

    this.toggleContent = this.toggleContent.bind(this)
  }

  toggleContent() {
    let showContent = !this.state.showContent

    this.setState({
      showContent: showContent
    })
  }

  render() {
    let { campaign, clickThroughCount, rebateRedemptionsCount, rebateSignUpCount } = this.props;
    let { showContent } = this.state;

    let engagementBarGraph = () => {
      if (JSON.stringify(campaign.engagement_data) === '{}') {
        return (
          <></>
        )
      } else {
        const data = {
          labels: campaign.engagement_data.labels,
          datasets: campaign.engagement_data.datasets,
        };
        const options = {
          barPercentage: 0.9,
          categoryPercentage: 0.75,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: false,
              text: "Sampling Overview",
            }
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              grid: {
                display: true,
              },
              stacked: true,
            },
            y: {
              type: 'logarithmic',
              grid: {
                display: true,
              },
              ticks: {
                display: true,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        };

        return (
          <Bar data={data} options={options} />
        );
      }
    };
    let demographicBarGraph = (graph_data) => {
      if (JSON.stringify(graph_data) === '{}') {
        return (
          <></>
        )
      } else {
        const data = {
          labels: graph_data.labels,
          datasets: graph_data.datasets,
        };
        const options = {
          barPercentage: 1,
          categoryPercentage: 1,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
              max: 100
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              position: "bottom",
            },
            tooltip: {
              mode: 'index',
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || '';
                  if (context.parsed.x !== null) {
                    label += ' ' +context.parsed.x + '%';
                  }
                  return label;
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
        };

        return (
          <Bar data={data} options={options} height={"160px"} />
        );
      }
    };
    let showRebateCountForCampaign = (campaign) => {
      if (rebateSignUpCount > 0) {
        return (
          <>
            <td className="bold">{numberWithCommas(campaign.rebate_sign_ups)}</td>
            {
              rebateRedemptionsCount > 0 ? (
                <td className="bold">{numberWithCommas(campaign.rebate_redemptions)}</td>
              ) : (
                null
              )
            }
          </>
        )
      } else {
        return null;
      }
    }
    let showClickThroughCountForCampaign = (campaign) => {
      if (clickThroughCount > 0) {
        return (
          <td className="bold">{numberWithCommas(campaign.click_throughs)}</td>
        )
      } else {
        return null;
      }
    }
    const checkRecentlyClaimedCount = (count) => {
      if (count && count > 0) {
        return (
          <span style={{ color:"green",fontSize:"10px",paddingLeft:"2px" }}>
            (+{numberWithCommas(count)})
          </span>
        )
      }
    }

    return (
      <>
        <tr onClick={this.toggleContent} key={`campaign-${campaign.id}`} className={`hoverable ${campaign.machine.hidden ? "opacity25" : ""}`}>
          <td style={{ minWidth:"22px" }}>
            {
              showContent ? (
                <span><i className="fas fa-caret-down"></i></span>
              ) : (
                <span><i className="fas fa-caret-right"></i></span>
              )
            }
          </td>
          <td>{campaign.date}</td>
          <td>{campaign.machine.name}</td>
          <td>{campaign.product.name}</td>
          <td></td>
          <td className="bold">{numberWithCommas(campaign.impressions)}</td>
          <td className="bold">{numberWithCommas(campaign.dwells)}</td>
          <td className="bold">{numberWithCommas(campaign.qr_scans)}</td>
          <td className="bold">
            {numberWithCommas(campaign.samples_claimed)}
            {checkRecentlyClaimedCount(campaign.samples_claimed_24hours)}
          </td>
          {
            clickThroughCount > 0 || rebateSignUpCount > 0 ? (
              <td></td>
            ) : (
              null
            )
          }
          {showClickThroughCountForCampaign(campaign)}
          {showRebateCountForCampaign(campaign)}
        </tr>

        {
          showContent ? (
            <tr>
              <td style={{ padding:"30px 0" }} colSpan={`${rebateSignUpCount > 0 ? "13" : "11"}`}>
                <div className="bg-white">
                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",padding:"10px" }}>
                      <div style={{ padding:"0 0 10px",fontSize:"16px",fontWeight:"bold" }}>Summary of Customer Engagements</div>

                      <div className="h300">
                        {engagementBarGraph()}
                      </div>
                    </div>

                    <div style={{ flex:"1",padding:"10px" }}>
                      <div style={{ padding:"0 0 10px",fontSize:"16px",fontWeight:"bold" }}>Demographic Breakdown of "Samples Claimed"</div>

                      <div style={{ height:"160px" }}>
                        {
                          campaign.gender_data_exists ? (
                            demographicBarGraph(campaign.gender_data)
                          ) : (
                            <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                              <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Gender Data Unavailable</div>
                            </div>
                          )
                        }
                      </div>

                      <div style={{ height:"160px" }}>
                        {
                          campaign.age_data_exists ? (
                            demographicBarGraph(campaign.age_data)
                          ) : (
                            <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                              <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Age Data Unavailable</div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            null
          )
        }
      </>
    );
  }
}
function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
